import React, { useRef, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

export default function AccordionPreview({
  summary,
  children,
  previewHeight = '128px',
  startCollapsed = true,
}) {
  const [collapsed, setCollapsed] = useState(startCollapsed);
  const containerRef = useRef(null);

  return (
    <Stack
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
      ref={containerRef}
      spacing={2}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        sx={{ cursor: 'pointer', zIndex: 3 }}
      >
        {summary}
        <ExpandMoreIcon
          sx={{ transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)' }}
        />
      </Stack>

      <Slide in={collapsed} container={containerRef.current} direction='up'>
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            height: '100%',
            background:
              'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
            zIndex: 2,
          }}
        ></Box>
      </Slide>

      <Collapse in={!collapsed} collapsedSize={previewHeight}>
        {children}
      </Collapse>
    </Stack>
  );
}
