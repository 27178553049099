import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { onlineService } from '@ft-frontend/core';

import ErrorMessage from 'components/ErrorMessage';

import StartingInfoContext from 'contexts/starting_info/StartingInfoContext';

import { useOnlineAPI } from 'hooks/useOnlineAPI';

import { setDefaultValues } from 'reducers/delivery/deliverySlice';
import {
  setSnackbarDisplayTime,
  setStoreId,
  setWlId,
} from 'reducers/ui/uiSlice';

import { defaultLocale, dynamicActivate } from 'utils/i18nLoaderHelper';

function StartingInfoProvider({ children }) {
  const { data, isLoading, isError } = useOnlineAPI(
    onlineService.endpoints.getStartingInfo,
    {
      needsWhitelabelUUID: false,
    },
  );

  const [appStatus, setAppStatus] = useState('loading');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) {
      const loadLocale = async (locale, status) => {
        await dynamicActivate(locale);

        setAppStatus(status);
      };

      if (!isError) {
        document.title = data.name || 'TD Synnex';
        loadLocale(data.configs?.defaultLanguage || defaultLocale, 'loaded');
      } else {
        loadLocale(defaultLocale, 'error');
      }

      dispatch(
        setDefaultValues({
          deliveryOption:
            data?.configs?.onlineDeliveryMethods?.length === 1
              ? data?.configs?.onlineDeliveryMethods[0]
              : 'DELIVERY',
          country: data?.country,
        }),
      );

      if (data?.configs?.warningDisplayTime) {
        dispatch(
          setSnackbarDisplayTime(data.configs.warningDisplayTime * 1000),
        );
      }

      if (data?.wlId) {
        dispatch(setWlId(data?.wlId));
      }

      if (data?.storeId) {
        dispatch(setStoreId(data?.storeId));
      }
    }
  }, [data, isError, isLoading, dispatch]);

  const getContent = () => {
    if (appStatus === 'error') {
      return <ErrorMessage />;
    } else if (appStatus === 'loading') {
      return <React.Fragment />;
    } else {
      return children;
    }
  };

  let materialTheme = {};

  try {
    materialTheme = JSON.parse(data?.configs?.materialTheme || '{}');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return (
    <StartingInfoContext.Provider value={data}>
      <ThemeProvider
        theme={(defaultTheme) =>
          createTheme(deepmerge(defaultTheme, materialTheme))
        }
      >
        {getContent()}
      </ThemeProvider>
    </StartingInfoContext.Provider>
  );
}

export default StartingInfoProvider;
