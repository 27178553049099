import { apiServiceClient } from '../frameworkClient';

const storeClient = apiServiceClient('framework/StoreService');

const endpoints = {
  createStore: 'createStore',
  getStoreByID: 'getStoreByID',
  getUserStores: 'getUserStores',
  listStores: 'listStores',
  updateStore: 'updateStore',
};

export const createStore = async ({ store }, options) => {
  return storeClient(endpoints.createStore, { store }, options);
};

export const getStoreByID = async ({ storeID }, options) => {
  return storeClient(endpoints.getStoreByID, { params: { storeID } }, options);
};

export const getUserStores = async ({ wlID, userID }, options) => {
  return storeClient(
    endpoints.getUserStores,
    { wlID, userID, userRoleID: 6 },
    options,
  );
};

export const listStores = async ({ wlID }, options) => {
  return storeClient(endpoints.listStores, { wlID }, options);
};

export const updateStore = async ({ store }, options) => {
  return storeClient(endpoints.updateStore, { store }, options);
};

export default {
  endpoints,
  createStore,
  getStoreByID,
  getUserStores,
  listStores,
  updateStore,
};
