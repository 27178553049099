import { apiServiceClient } from '../frameworkClient';

const productClient = apiServiceClient('framework/ProductService');

const endpoints = {
  getProduct: 'getProduct',
  listDevices: 'listDevices',
};

export const getProduct = async ({ deviceID, countryCode }, options) => {
  return productClient(
    endpoints.getProduct,
    { deviceID, countryCode },
    options,
  );
};

export const listDevices = async ({ deviceStatus, countryCode }, options) => {
  return productClient(
    endpoints.listDevices,
    { deviceStatus, countryCode },
    options,
  );
};

export default {
  endpoints,
  getProduct,
  listDevices,
};
