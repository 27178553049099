import * as React from 'react';

import StartingInfoContext from 'contexts/starting_info/StartingInfoContext';

export default function useStartingInfo() {
  const startingInfo = React.useContext(StartingInfoContext);

  React.useDebugValue(
    process.env.NODE_ENV !== 'production' ? startingInfo : null,
  );

  return startingInfo || {};
}
