import { Trans } from '@lingui/macro';

export const SIM_STATUS_LOCKED = 'Locked';
export const SIM_STATUS_UNLOCKED = 'Unlocked';
export const NONE = 'none';

export const OUTCOME_RETURN = 'RETURN_DEVICE';
export const OUTCOME_UPGRADE = 'UPGRADE';
export const OUTCOME_VOUCHER = 'VOUCHER';

export const networkStatus = [SIM_STATUS_LOCKED, SIM_STATUS_UNLOCKED];

export const simStatusLabels = () => ({
  [SIM_STATUS_LOCKED]: <Trans id='recycle.network.locked'>Locked</Trans>,
  [SIM_STATUS_UNLOCKED]: <Trans id='recycle.network.unlocked'>Unlocked</Trans>,
  [NONE]: <Trans id='recycle.network.none'>None</Trans>,
});
