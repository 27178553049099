import React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import * as Sentry from '@sentry/react';

import { ScrollToTop, useAutoScroll } from '@ft-frontend/core';

import ErrorMessage from 'components/ErrorMessage';
import PosaleFooter from 'components/PosaleFooter';
import PosaleHeader from 'components/PosaleHeader';

import PosaleStartingInfoProvider from 'contexts/starting_info/PosaleStartingInfoProvider';

const Posale = ({ persistor }) => {
  useAutoScroll(60);

  return (
    <PosaleStartingInfoProvider>
      <ScrollToTop>
        <PosaleHeader persistor={persistor} />

        <Stack
          alignItems='center'
          sx={(theme) => ({
            marginTop: '123px',
            padding: theme.spacing(2),
          })}
          spacing={1}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <Sentry.ErrorBoundary
              fallback={({ resetError }) => (
                <ErrorMessage resetError={resetError} />
              )}
            >
              <Outlet />
            </Sentry.ErrorBoundary>
          </Box>
        </Stack>

        <PosaleFooter />
      </ScrollToTop>
    </PosaleStartingInfoProvider>
  );
};

export default Posale;
