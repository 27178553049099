import { getCountryCallingCode } from 'libphonenumber-js/max';
import groupBy from 'lodash/groupBy';

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CheckboxElement,
  MultiSelectElement,
  RadioButtonGroup,
  SelectElement,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useOnMount from '../hooks/useOnMount';
import { validators } from '../utils';

const GeneralForm = ({
  allowManualOptions,
  country,
  data = {},
  defaultValues = {},
  formMessages = {},
  formName = '',
  getCountryName = () => {},
  onChange = () => {},
  setContext = () => {},
  shouldValidateOnMount = false,
  showFieldsetTitle = true,
  spacing = 2,
  title,
  formCols = 3,
}) => {
  const { gridTemplateAreas, ...dataFields } = data;

  const [selectManualOptions, setSelectManualOptions] = useState({});

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { control } = methods;
  const messages = {
    errorRequired: 'Mandatory Field',
    getLabelName: (name) => name,
    ...formMessages,
  };

  useOnMount(() => {
    if (shouldValidateOnMount) {
      methods.trigger();
    }
  });

  /**
   * Calling the 'isValid' before hand is necessary for the form to
   * subscribe to the variables, making them react in real time
   *
   * ❌ formState.isValid is accessed conditionally,
   *  so the Proxy does not subscribe to changes of that state
   * return <button disabled={!formState.isDirty || !formState.isValid} />;
   *
   * ✅ read all formState values to subscribe to changes
   * const { isDirty, isValid } = formState;
   * return <button disabled={!isDirty || !isValid} />;
   */
  const isValid = methods?.formState?.isValid;
  const errors = methods?.formState?.errors;
  const dirtyFields = methods?.formState?.dirtyFields;
  const touchedFields = methods?.formState?.touchedFields;

  useEffect(() => {
    setContext({
      isValid,
      errors,
      dirtyFields,
      touchedFields,
      getValues: methods?.getValues,
    });
  }, [isValid, errors, dirtyFields, touchedFields, setContext]);

  const setGridTemplateAreas = (theme) => {
    const { base, ...breakpoints } = gridTemplateAreas;
    return {
      gridTemplateAreas: base,
      ...Object.entries(breakpoints).reduce(
        (acc, [breakpoint, string]) => ({
          ...acc,
          [theme.breakpoints.down(breakpoint)]: {
            gridTemplateAreas: string,
          },
        }),
        {},
      ),
    };
  };

  const addManualOption = (path) => () => {
    const value = document.getElementById(`new-item-${path}]`)?.value;

    setSelectManualOptions({
      ...(selectManualOptions || {}),
      [path]: [...(selectManualOptions[path] || []), value],
    });
  };

  const getFieldStyle = (fieldType) => {
    if (fieldType === 'form')
      return {
        gridColumnStart: 1,
        gridColumnEnd: formCols + 1,
      };
    else if (fieldType === 'textarea')
      return {
        gridColumnStart: 1,
        gridColumnEnd: `min(${formCols},3)`,
      };
    else return { width: '100%' };
  };

  const renderField = (field, fieldName, inputPath = '', _fieldset) => {
    const {
      editable = true,
      defaultValue,
      helperText,
      inputProps,
      maxLength,
      options = [],
      pattern,
      placeholder,
      required,
      size = 'small',
      type,
      name,
    } = field;
    const commonProps = {
      'data-testid': `form-${formName}-input-${fieldName}`,
      className: `form-input form-${formName}-input-${fieldName}`,
      control,
      disabled: !editable,
      helperText:
        helperText &&
        messages.getLabelName(helperText, {
          0: getCountryCallingCode(country),
        }),
      inputProps: { maxLength: maxLength + 1, ...inputProps },
      label: name ? messages.getLabelName(name) : fieldName,
      name: `${inputPath}${fieldName}`,
      onChange: onChange(fieldName),
      pattern: pattern ? new RegExp(pattern) : undefined,
      placeholder,
      required,
      size,
    };

    const validation = {
      ...(required && { required: messages.errorRequired }),
      validate: {
        ...(maxLength && {
          lengthValidator: validators.lengthValidator(maxLength),
        }),
        ...(pattern && {
          regExpValidator: validators.regExpValidator({
            pattern,
            errorMsg: messages.errorRegExp,
            errorChars: messages.errorChars,
          }),
        }),
      },
    };

    switch (type) {
      case 'checkbox':
        return <CheckboxElement {...commonProps} />;
      case 'radio':
        return (
          <RadioButtonGroup
            {...commonProps}
            options={options.map((option) => ({ id: option, label: option }))}
          />
        );
      case 'switch_checkbox':
        return <SwitchElement {...commonProps} />;
      case 'select':
        if (field.multiple) {
          return (
            <MultiSelectElement
              {...commonProps}
              options={options.map((option) => option)}
            />
          );
        }
        return (
          <SelectElement
            {...commonProps}
            options={options.map((option) => ({ id: option, label: option }))}
          />
        );
      case 'title':
        return (
          <SelectElement
            {...commonProps}
            options={messages.titleNames.filter((title) =>
              options.includes(title.id),
            )}
          />
        );
      case 'country':
        return (
          <SelectElement
            {...commonProps}
            SelectProps={{ IconComponent: () => null }}
            options={[
              {
                id: defaultValue,
                label: getCountryName(defaultValues.country),
              },
            ]}
          />
        );
      case 'color':
      case 'date':
      case 'datetime_local':
      case 'file':
      case 'month':
      case 'number':
      case 'password':
      case 'text':
      case 'time':
      case 'url':
      case 'week':
        if (field.multiple) {
          return (
            <Stack spacing={2}>
              <MultiSelectElement
                {...commonProps}
                options={
                  selectManualOptions?.[commonProps.name]?.map(
                    (option) => option,
                  ) || []
                }
                showChips
              />
              {allowManualOptions && (
                <Stack direction='row' spacing={2}>
                  <TextField
                    id={`new-item-${commonProps.name}]`}
                    size='small'
                    label='New Item'
                    variant='outlined'
                  />
                  <Button
                    size='small'
                    color='primary'
                    variant='contained'
                    onClick={addManualOption(commonProps.name)}
                  >
                    Add
                  </Button>
                </Stack>
              )}
            </Stack>
          );
        }
        return (
          <TextFieldElement
            {...commonProps}
            type={type.replace('_', '-')}
            multiline={field.multiple}
            validation={validation}
          />
        );
      case 'textarea':
        return (
          <TextFieldElement
            {...commonProps}
            multiline={true}
            maxRows={field.rows}
            validation={validation}
          />
        );
      case 'postal':
        return (
          <TextFieldElement
            {...commonProps}
            type={type}
            validation={{
              ...validation,
              validate: {
                ...validation.validate,
                ...(country && {
                  regExpValidator: (newPostalCode) =>
                    validators.postalValidator({
                      newPostalCode,
                      country,
                      errorMsg: messages.errorPostal,
                    }),
                }),
              },
            }}
          />
        );
      case 'tel':
        return (
          <TextFieldElement
            {...commonProps}
            type={type}
            validation={{
              ...validation,
              validate: pattern
                ? validators.regExpValidator({
                    pattern,
                    showInvalidChars: false,
                    errorMsg: messages.errorPhone,
                    errorChars: messages.errorChars,
                  })
                : (newNumber) =>
                    validators.phoneValidator({
                      newNumber,
                      country,
                      errorMsgCountry: messages.errorCountryPhone,
                      errorMsg: messages.errorPhone,
                    }),
            }}
          />
        );
      case 'email':
        return (
          <TextFieldElement
            {...commonProps}
            // type 'email' is trimming the spaces causing issues with the required validation.
            // leaving type 'text' for now until we find a better solution
            type={'text'}
            validation={{
              ...validation,
              validate: validators.regExpValidator({
                pattern: pattern ? pattern : validators.emailRegex,
                showInvalidChars: false,
                errorMsg: messages.errorEmail,
              }),
            }}
          />
        );
      case 'id_number':
        return (
          <TextFieldElement
            {...commonProps}
            type='text'
            validation={{
              ...validation,
              validate: {
                ...validation.validate,
                validate: (newIDNumber) =>
                  validators.idNumberValidator({
                    newIDNumber,
                    country,
                    errorMsg: messages.errorIDNumber,
                  }),
              },
            }}
          />
        );
      case 'iban':
        return (
          <TextFieldElement
            {...commonProps}
            type='text'
            validation={{
              ...validation,
              validate: {
                ...validation.validate,
                validate: (newIBAN) =>
                  validators.ibanValidator({
                    newIBAN,
                    errorMsg: messages.errorIBAN,
                  }),
              },
            }}
          />
        );
      case 'form':
        return (
          <Accordion
            style={{
              width: '100%',
              marginBottom: '16px',
              border: '1px solid rgba(255, 255, 255, 0.3)',
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography variant='h5'>{fieldName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={3}>
                {renderForm(field.fields, `${fieldName}.`)}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      default:
        return null;
    }
  };

  const renderForm = (fields, inputPath) => {
    if (!fields) return;

    const groupedFields = Object.entries(
      groupBy(Object.entries(fields), ([_key, value]) => value.fieldset),
    );

    return groupedFields.map(([fieldset, fieldsData]) => (
      <Box key={fieldset}>
        <Stack spacing={3}>
          {fieldset && showFieldsetTitle && fieldset !== 'undefined' && (
            <Typography variant='h5'>{fieldset}</Typography>
          )}
          <FormGroup
            sx={(theme) => {
              return {
                display: 'grid',
                gap: theme.spacing(spacing),
                ...(gridTemplateAreas
                  ? setGridTemplateAreas(theme)
                  : {
                      gridTemplateColumns: `repeat(${formCols}, 1fr)`,
                    }),
              };
            }}
          >
            {fieldsData.map(([fieldName, field]) => (
              <Box
                key={fieldName}
                sx={{
                  display: 'inline-grid',
                  minHeight: '56px',
                  ...(gridTemplateAreas
                    ? { gridArea: fieldName }
                    : getFieldStyle(field.type)),
                }}
              >
                {renderField(field, fieldName, inputPath, fieldset)}
              </Box>
            ))}
          </FormGroup>
        </Stack>
      </Box>
    ));
  };

  return (
    <FormProvider {...methods}>
      <Stack spacing={3}>
        {title && <Typography variant='h5'>{title}</Typography>}
        {renderForm(dataFields)}
      </Stack>
    </FormProvider>
  );
};

export default GeneralForm;
