import { createSlice } from '@reduxjs/toolkit';

const initialCartState = {
  deviceCode: '',
  parentDeviceCode: undefined,
  extras: [],
  specialOffers: [],
  insurances: [],
  paymentPlan: {},
  financingType: undefined,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    addExtra: (state, action) => {
      let extraIndex = state.extras.findIndex(
        (extra) => extra.code === action.payload.code,
      );

      if (extraIndex === -1) {
        state.extras.push(action.payload);
      }
    },
    removeExtra: (state, action) => {
      let extraIndex = state.extras.findIndex(
        (extra) => extra.code === action.payload.code,
      );

      if (extraIndex !== -1) {
        state.extras.splice(extraIndex, 1);
      }
    },
    removeInsurance: (state) => {
      state.insurances = [
        {
          code: 'NO_INSURANCE',
          name: 'No Insurance',
          quantity: 1,
        },
      ];
    },
    removeParentDeviceCode: (state) => {
      state.deviceCode = state.parentDeviceCode || state.deviceCode;
      state.parentDeviceCode = undefined;
    },
    resetCart: () => {
      return initialCartState;
    },
    setDeviceCode: (state, action) => {
      state.deviceCode = action.payload;
    },
    setFamily: (state, action) => {
      state.deviceCode = action.payload.deviceCode;
      state.specialOffers = [];
      state.insurances = [];
      state.paymentPlan = {
        id: action.payload.paymentPlanID,
      };
      state.financingType = action.payload.paymentPlanType?.toUpperCase();
    },
    setInsurance: (state, action) => {
      state.insurances = [action.payload];
    },
    setParentDeviceCode: (state, action) => {
      state.parentDeviceCode = action.payload.parentDeviceCode;
      state.deviceCode = action.payload.deviceCode;
    },
    setPaymentPlan: (state, action) => {
      state.paymentPlan = action.payload;
    },
  },
});

export const {
  addExtra,
  removeExtra,
  removeInsurance,
  removeParentDeviceCode,
  resetCart,
  setDeviceCode,
  setFamily,
  setInsurance,
  setParentDeviceCode,
  setPaymentPlan,
} = cartSlice.actions;

export default cartSlice.reducer;
