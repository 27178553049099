import { createSlice } from '@reduxjs/toolkit';

const initialFlowState = {
  swapUUID: false,
  acceptEvaluation: false,
  recycleDevice: undefined,
  recycleAnswers: {
    criteriaAnswers: {},
    deductionAnswers: {},
    additionAnswers: {},
  },
  recycleEvaluation: {},
  recyclePartner: {},
  paymentMethod: 'FINANCE',
  oldContract: {},
};

export const flowSlice = createSlice({
  name: 'flow',
  initialState: initialFlowState,
  reducers: {
    setSwapUUID: (state, action) => {
      state.swapUUID = action.payload;
    },
    setAcceptEvaluation: (state, action) => {
      state.acceptEvaluation = action.payload;
    },
    setRecycleDevice: (state, action) => {
      state.recycleDevice = action.payload;
    },
    setRecycleEvaluation: (state, action) => {
      state.recycleEvaluation = action.payload;
    },
    setRecycleAnswers: (state, action) => {
      state.recycleAnswers = action.payload;
    },
    setTradeInDeviceField: (state, action) => {
      state[action.payload.field] = action.payload.value;
    },
    setNetworkStatus: (state, action) => {
      state.networkStatus = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setRecyclePartner: (state, action) => {
      state.recyclePartner = action.payload;
    },
    setCourierURL: (state, action) => {
      state.courierURL = action.payload;
    },
    setOldContract: (state, action) => {
      state.oldContract = action.payload;
    },
    resetFlow: (state) => {
      return { ...initialFlowState, courierURL: state.courierURL };
    },
  },
});

export const {
  resetFlow,
  setAcceptEvaluation,
  setCourierURL,
  setNetworkStatus,
  setOldContract,
  setPaymentMethod,
  setRecycleAnswers,
  setRecycleDevice,
  setRecycleEvaluation,
  setRecyclePartner,
  setSwapUUID,
  setTradeInDeviceField,
} = flowSlice.actions;

export default flowSlice.reducer;
