import IBAN from 'iban';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import postalCodes from 'postal-codes-js';

const lengthValidator =
  (
    charLimit = 255,
    errorMsg = (charLimit) => `Input has has more than ${charLimit} character`,
  ) =>
  (newInput) => {
    if (newInput?.length > charLimit) {
      return errorMsg(charLimit);
    }

    return true;
  };

const parseInvalidCharsErrorMessage = (
  newInput,
  validationRegExp,
  errorChars = 'Invalid characters:',
) =>
  `${errorChars} ${newInput
    .split('')
    .map((char) => char.replace(validationRegExp, ''))
    .filter((char) => char)
    .join(',')}`;

const regExpValidator =
  ({
    pattern,
    showInvalidChars = true,
    errorMsg = 'Input format is invalid',
    errorChars,
  }) =>
  (newInput) => {
    if (pattern) {
      let validationRegExp = new RegExp(pattern);
      if (!validationRegExp.test(newInput)) {
        return showInvalidChars
          ? parseInvalidCharsErrorMessage(
              newInput,
              validationRegExp,
              errorChars,
            )
          : errorMsg;
      }
    }

    return true;
  };

const phoneValidator = ({
  newNumber,
  country,
  errorMsgCountry = 'Phone number should be in international format',
  errorMsg = 'Phone format is invalid',
}) => {
  if (!country) return true;

  try {
    let isValid = false;
    let parsedNumber = parsePhoneNumber(newNumber, country);

    if (parsedNumber.isValid()) {
      if (parsedNumber.country === 'PT') {
        isValid = true;
      }
      if (parsedNumber.country === country) {
        isValid = true;
      }
    }

    return isValid || errorMsgCountry;
  } catch (err) {
    return errorMsg;
  }
};

const postalValidator = ({
  newPostalCode,
  country,
  errorMsg = "This postal code isn't valid in your country",
}) => {
  const result = postalCodes.validate(country, newPostalCode);

  if (result && typeof result === 'boolean') {
    // postal is valid
    return true;
  }

  return errorMsg;
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email) => {
  return email?.match(emailRegex);
};

export const isCartUUID = (value = '') =>
  value?.match(/(\w{2}-){2}\d{4}-\d{7}/g);

export const isDigitOnlyString = (value = '') => value?.match(/^\d+$/);

export const isEmptyString = (value) =>
  value === '' || (value && !value.replace(/\s/g, '').length);

const IMEI_LENGTH = 15;

export const validIMEICheckDigit = ((arr) => {
  return (value) => {
    if (!isDigitOnlyString(value)) return false;

    let len = value.length;
    let bit = 1;
    let sum = 0;
    let val;

    while (len) {
      val = parseInt(value.charAt(--len), 10);
      // eslint-disable-next-line no-cond-assign
      sum += (bit ^= 1) ? arr[val] : val;
    }

    return sum && sum % 10 === 0;
  };
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

export const isValidIMEI = (value) =>
  value?.length === IMEI_LENGTH && validIMEICheckDigit(value);

export const idNumberValidator = ({
  newIDNumber,
  country,
  errorMsg = 'This ID Number is not valid',
}) => {
  let isValid = true;
  if (country == 'ES') {
    isValid = validateSpanishID(newIDNumber);
  }
  return isValid || errorMsg;
};

const validateSpanishID = (str) => {
  const DNI_REGEX = /^(([KLM]\d{7})|(\d{8}))([A-Z])$/;
  const CIF_REGEX = /^([ABCDEFGHJNPQRSUVW])(\d{7})([0-9A-J])$/;
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

  const spainIdType = (str) => {
    if (str?.match(DNI_REGEX)) {
      return 'dni';
    }
    if (str?.match(CIF_REGEX)) {
      return 'cif';
    }
    if (str?.match(NIE_REGEX)) {
      return 'nie';
    }
  };

  const validDNI = (dni) => {
    const dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    if (dni[0].match(/[KLM]/)) {
      dni = dni.substr(1);
    }
    const letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter === dni.charAt(dni.length - 1);
  };

  const validNIE = (nie) => {
    let nie_prefix = nie.charAt(0);

    switch (nie_prefix) {
      case 'X':
        nie_prefix = 0;
        break;
      case 'Y':
        nie_prefix = 1;
        break;
      case 'Z':
        nie_prefix = 2;
        break;
    }

    return validDNI(nie_prefix + nie.substr(1));
  };

  const validCIF = (cif) => {
    const match = cif.match(CIF_REGEX);
    const letter = match[1];
    const number = match[2];
    const control = match[3];

    let even_sum = 0;
    let odd_sum = 0;
    let last_digit = 0;
    let n;

    for (let i = 0; i < number.length; i++) {
      n = parseInt(number.charAt(i), 10);

      if (i % 2 === 0) {
        n *= 2;
        odd_sum += n < 10 ? n : n - 9;
      } else {
        even_sum += n;
      }
    }

    last_digit = parseInt((even_sum + odd_sum).toString().slice(-1), 10);
    const control_digit = last_digit !== 0 ? 10 - last_digit : last_digit;
    const control_letter = 'JABCDEFGHI'.charAt(control_digit);

    if (letter.match(/[ABEH]/)) {
      return control === control_digit.toString();
    } else if (letter.match(/[PQSW]/)) {
      return control === control_letter;
    } else {
      return control === control_digit.toString() || control === control_letter;
    }
  };

  // Ensure upcase and remove whitespace
  str = str?.toUpperCase().replace(/\s/g, '');

  const type = spainIdType(str);
  let valid = false;

  switch (type) {
    case 'dni':
      valid = validDNI(str);
      break;
    case 'nie':
      valid = validNIE(str);
      break;
    case 'cif':
      valid = validCIF(str);
      break;
  }

  return valid;
};

export const ibanValidator = ({
  newIBAN,
  errorMsg = 'This IBAN is not valid',
}) => IBAN.isValid(newIBAN) || errorMsg;

export const isIframe = () => window.location !== window.parent.location;

export default {
  emailRegex,
  ibanValidator,
  idNumberValidator,
  isCartUUID,
  isDigitOnlyString,
  isEmptyString,
  isIframe,
  isValidIMEI,
  lengthValidator,
  parseInvalidCharsErrorMessage,
  phoneValidator,
  postalValidator,
  regExpValidator,
  validateEmail,
  validIMEICheckDigit,
};
