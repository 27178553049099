import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans } from '@lingui/macro';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { setHideCookiesConsent } from 'reducers/cookies/cookiesSlice';

export default function ConsentBanner() {
  const dispatch = useDispatch();

  const hideCookieConsent = useSelector(
    ({ cookies }) => cookies.hideCookieConsent,
  );

  const allowCookies = () => {
    dispatch(setHideCookiesConsent());
    window.gtag('consent', 'update', {
      analytics_storage: 'granted',
    });
  };
  return (
    <Modal
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'end',
        padding: theme.spacing(2),
      })}
      open={!hideCookieConsent}
      id='cookies'
      aria-labelledby='consent-banner-title'
      aria-describedby='parent-modal-description'
    >
      <Paper
        sx={(theme) => ({
          padding: theme.spacing(2),
          width: '100%',
          outline: 0,
        })}
        elevation={0}
      >
        <Stack spacing={2}>
          <Typography id='consent-banner-title' variant='h5'>
            <Trans id='consent.banner.title'>We Value Your Privacy</Trans>
          </Typography>
          <Box>
            <Typography id='parent-modal-description'>
              <Trans id='consent.banner.body'>
                We use Google Analytics to understand how you interact with our
                site and improve your experience. Google Analytics collects
                anonymous data about your visit, such as pages viewed and time
                spent on our site.
              </Trans>
            </Typography>
            <Typography sx={{ fontWeight: '600' }}>
              <Trans id='consent.banner.confirmation'>
                Do you consent to the use of cookies during your visit?
              </Trans>
            </Typography>
          </Box>
          <Stack justifyContent='end' direction='row' spacing={1}>
            <Button
              color='secondary'
              size='large'
              variant='contained'
              onClick={allowCookies}
            >
              <Trans id='consent.banner.accept'>Accept</Trans>
            </Button>
            <Button
              color='secondary'
              size='large'
              variant='contained'
              onClick={() => dispatch(setHideCookiesConsent())}
            >
              <Trans id='consent.banner.decline'>Decline</Trans>
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}
