import React from 'react';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import OptionsCard from 'components/OptionsCard';

const mockedPaymentPlans = [{ id: 1 }, { id: 2 }];

export default function PaymentOptions({
  isLoading,
  paymentPlans,
  setSelectedPaymentPlan,
}) {
  const {
    i18n: { locale },
  } = useLingui();

  return (
    <Grid item container spacing={2} data-testid='payment-options'>
      <Grid item>
        <Typography variant='h5' component='h2'>
          <Trans id='online.payment.options.title'>Payment Options</Trans>
        </Typography>
      </Grid>
      <Grid item container spacing={1}>
        {(isLoading ? mockedPaymentPlans : paymentPlans)?.map((paymentPlan) => (
          <Grid key={paymentPlan.id} item xs={12} md={6}>
            <OptionsCard
              isLoading={isLoading}
              selected={paymentPlan.selected}
              enabled={paymentPlan.enabled}
              option={{
                code: paymentPlan.id,
                name: paymentPlan.name,
                subtitle:
                  paymentPlan.taglineMap?.[locale] || paymentPlan.tagline,
                mainPrice: paymentPlan.monthlyPrice,
              }}
              pricePrefix=''
              onClick={() => setSelectedPaymentPlan(paymentPlan)}
              currencyCode={paymentPlan.currencyCode}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
