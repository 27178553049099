import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function PosaleFooter() {
  return (
    <Grid
      sx={{
        backgroundColor: 'white',
        padding: '0px 16px',
        borderTop: '1px solid #e9e9e9',
        minHeight: '81px',
      }}
      component='footer'
      container
      justifyContent='center'
    >
      <Grid
        item
        container
        xs={12}
        md={10}
        lg={8}
        justifyContent='space-between'
        alignItems='center'
        spacing={3}
        sx={{ minHeight: '48px !important', maxWidth: '1366px !important' }}
      >
        <Grid item xs={4}></Grid>

        <Grid
          item
          container
          alignItems='center'
          justifyContent='flex-end'
          direction='column'
          xs={4}
          sx={{ height: '100%' }}
        >
          <Grid
            item
            container
            alignItems='flex-start'
            justifyContent='center'
            sx={{ height: '27px' }}
          >
            <img
              src='https://cdn.financetech365.com/Techdata/TDSYNNEX-transparent.svg'
              alt='TD SYNNEX'
              data-testid='footer-td-synnex-logo'
              width='101px'
              height='24px'
            />
          </Grid>

          <Grid item>
            <Typography
              variant='caption'
              sx={{
                fontSize: '11px',
                lineHeight: '24px',
                color: '#606060',
              }}
            >{`version #${process.env.REACT_APP_SENTRY_RELEASE}`}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}></Grid>
      </Grid>
    </Grid>
  );
}
