import axios from 'axios';

import apiVersions from './apiVersions';

const getSubOrFullDomain = () =>
  window.location.hostname.match(/.*financetech(demo|test|365).com/g)
    ? window.location.hostname.split('.')[0]
    : window.location.hostname;

const getAPIBaseURL = () => {
  const hostnameParts = window.location.hostname.split('.');
  const subdomain = hostnameParts.shift();
  const subdomainParts = subdomain.split('-');
  const apiSubdomain =
    subdomainParts.length > 1 ? [subdomainParts[0], 'api'].join('-') : 'api';

  return 'https://' + [apiSubdomain, ...hostnameParts].join('.') + '/';
};

const apiClient = (service, func, data = {}, fullConfig = {}) => {
  let { clientID, sessionID, ...body } = data;
  let {
    exceptionHandler = console.error,
    errorHandler = console.error,
    warningHandler = console.warn,
    ...config
  } = fullConfig;

  const subdomain = process.env.REACT_APP_SUBDOMAIN || getSubOrFullDomain();
  const baseURL =
    fullConfig?.baseURL || process.env.REACT_APP_ENDPOINT || getAPIBaseURL();

  const api = axios.create({
    method: 'post',
    headers: {
      Accept: 'application/json, text/csv, application/pdf',
      'Content-Type': 'application/json',
    },
    transformRequest: (data) => {
      const requestPayload = {
        version:
          apiVersions?.[data.header.service]?.[data.header.function] ||
          process.env.REACT_APP_API_VERSION,
        build: process.env.REACT_APP_SENTRY_RELEASE,
      };

      return JSON.stringify({
        ...data,
        header: {
          ...data.header,
          ...requestPayload,
        },
      });
    },
  });

  api.interceptors.response.use((response) => {
    const { header = {} } = response.data;

    const { apiStatus, errorNumber } = header;

    header?.warnings?.forEach((warning) =>
      warningHandler({ errorNumber, ...warning }),
    );
    header?.errors?.forEach((error) => errorHandler({ errorNumber, ...error }));

    if (response.data.data?.loginStatus) {
      response.data.data.status = response.data.data.loginStatus;
    }

    return response;
  });

  return (
    api
      .post(
        `${baseURL}${service}/${func}`,
        {
          header: {
            service: service.replace('framework/', ''),
            function: func,
            clientID: data?.clientID || process.env.REACT_APP_TOKEN,
            sessionID: data?.sessionID,
          },
          ...body,
          subdomain,
        },
        config,
      )
      .catch((error) => exceptionHandler(error)) || {}
  );
};

export default apiClient;

export const apiServiceClient = (service) => (func, data, config) =>
  apiClient(service, func, data, config);
