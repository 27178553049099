import React from 'react';
import { useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import { mainNav } from 'constants/navigation';

const DropdownMenu = ({ id, options, title }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path, isMigrated) => () => {
    handleClose();

    if (isMigrated) {
      navigate(path);
    } else {
      window.location.href = path;
    }
  };

  return (
    <div>
      <Button
        id={`nav-button-${id}`}
        aria-controls={open ? `nav-menu-${id}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        data-testid={`posale-header-${id}-dropdown`}
        onClick={handleClick}
        sx={{
          color: 'white !important',
          fontSize: '0.75rem',
          fontWeight: '400',
          padding: '0',
          minWidth: '0',
        }}
      >
        {title}
        <ExpandMoreIcon sx={{ fontSize: '18px' }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `nav-button-${id}`,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={handleNavigate(option.path, option.isMigrated)}
            data-testid={`posale-header-${id}-dropdown-${option.id}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const NavigationMenu = ({ documentation, menuConfigs, user }) => {
  return (
    <Stack direction='row' spacing={3}>
      {Object.values(mainNav(menuConfigs, documentation)).map((entry) => {
        if (entry.isActive) {
          if (user.role <= entry.minRole) {
            return entry.path ? (
              <Button
                key={entry.id}
                href={entry.path}
                data-testid={`posale-header-${entry.id}-link`}
                sx={{
                  color: 'white !important',
                  fontSize: '0.75rem',
                  fontWeight: '400',
                  padding: '0',
                  minWidth: '0',
                }}
              >
                {entry.label}
              </Button>
            ) : (
              <DropdownMenu
                key={entry.id}
                id={entry.id}
                title={entry.label}
                options={Object.values(entry.options).filter(
                  (entry) =>
                    entry.isActive === true && user.role <= entry.minRole,
                )}
              />
            );
          }
        }

        return undefined;
      })}
    </Stack>
  );
};

export default NavigationMenu;
