import SantanderHeadline from 'fonts/SantanderHeadline-Bold.woff2';
import SantanderMicroText from 'fonts/SantanderMicroText-Regular.woff2';

import ErrorOutline from '@mui/icons-material/ErrorOutline';
import TaskAlt from '@mui/icons-material/TaskAlt';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

// Define theme variables
export const defaultTheme = {
  palette: {
    primary: {
      light: '#337879',
      main: '#005758',
      dark: '#003C3D',
    },
    secondary: {
      light: '#39CBDD',
      main: '#08BED5',
      dark: '#058595',
    },
    error: {
      light: '#F9DDDC',
      main: '#D81414',
      dark: '#BD0000',
    },
    warning: {
      light: '#FDEE98',
      main: '#FFDD33',
      dark: '#E6BF00',
    },
    info: {
      light: '#E5EB89',
      main: '#CCD814',
      dark: '#ADB000',
    },
    success: {
      light: '#E5EB89',
      main: '#CCD814',
      dark: '#ADB000',
    },
    text: {
      primary: '#000000',
      secondary: '#63666A',
      disabled: '#D9D8D7',
    },
    gray: {
      light: '#F2F2F2',
      main: '#D9D8D7',
      dark: '#63666A',
    },
    available: {
      main: '#CCD814',
    },
    unavailable: {
      main: '#D81414',
    },
    cartWidget: {
      main: '#ffffff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1080,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '3.063rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.438rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.938rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.563rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1rem',
    },
    subtitle2: {
      fontSize: '0.813rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.813rem',
    },
    button: {
      fontSize: '0.813rem',
      textTransform: 'initial',
      lineHeight: 1.1,
    },
    caption: {
      fontSize: '0.625rem',
    },
    captionBold: {
      fontWeight: 600,
      fontSize: '0.625rem',
      lineHeight: 1.16,
    },
    overline: {
      fontSize: '0.625rem',
    },
    pricing: {
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.25,
    },
  },
  shape: {
    borderRadius: 8,
  },
};

// Overwrite components styles
export const defaultOverrides = {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&.MuiButton-contained': {
            color: 'white',
          },
          '&.MuiButton-text': {
            color: theme.palette.text.primary,
          },
          '&.MuiButton-outlined': {
            borderWidth: '2px',
            ...(ownerState.color === 'gray' && {
              color: theme.palette.gray.dark,
              borderColor: theme.palette.gray.dark,
              '&:hover': {
                borderColor: theme.palette.gray.dark,
              },
            }),
          },
        }),
        containedPrimary: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
        containedSecondary: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&.MuiButton-outlined': {
            borderWidth: '2px',
          },
        }),
        sizeSmall: {
          height: '24px',
          borderRadius: '12px !important',
        },
        sizeMedium: {
          height: '32px',
          borderRadius: '16px !important',
          fontWeight: 600,
        },
        sizeLarge: {
          height: '40px',
          borderRadius: '20px !important',
          fontWeight: 600,
        },
        sizeExtraLarge: ({ theme }) => ({
          height: '64px',
          borderRadius: '32px !important',
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: 600,
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'SantanderMicroText';
          src: url(${SantanderMicroText}) format("woff2");
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'SantanderHeadline';
          src: url(${SantanderHeadline}) format("woff2");
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }
      `,
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: () => ({
          backgroundColor: theme.palette.gray.light,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: '2px',
          borderColor: 'rgba(0, 0, 0, 0.5)',
        },
        root: ({ ownerState, theme }) => ({
          '&.Mui-disabled': {
            input: {
              WebkitTextFillColor: theme.palette.text.secondary,
              color: theme.palette.text.secondary,
            },
            backgroundColor: theme.palette.gray.light,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            ...(!ownerState.disabled && {
              borderColor: theme.palette.secondary.main,
            }),
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&& fieldset': {
            border: `2px solid rgba(0, 0, 0, 0.5)`,
          },
          '&:hover': {
            '&& fieldset': {
              borderColor: `${theme.palette.secondary.main}`,
            },
          },
          '&.Mui-focused fieldset': {
            borderColor: `${theme.palette.secondary.main} !important`,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.secondary,
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <ErrorOutline fontSize='medium' />,
          warning: <ErrorOutline fontSize='medium' />,
          success: <TaskAlt fontSize='medium' />,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiAlert-message': {
            ...theme.typography.body2,
          },
          color: theme.palette.text.primary,
        }),
        filledError: ({ theme }) => ({
          border: `2px solid ${theme.palette.error.main}`,
          backgroundColor: theme.palette.error.light,
        }),
        filledWarning: ({ theme }) => ({
          border: `2px solid ${theme.palette.warning.main}`,
          backgroundColor: theme.palette.warning.light,
        }),
        filledSuccess: ({ theme }) => ({
          border: `2px solid ${theme.palette.success.main}`,
          backgroundColor: theme.palette.success.light,
        }),
        message: ({ theme }) => ({
          ...theme.typography.body1,
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
        }),
      },
    },
    MuiAlertIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body2,
          fontWeight: 600,
        }),
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body2,
          fontWeight: 600,
          paddingTop: '4px',
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            fontWeight: 600,
          },
          '&.Mui-completed': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: ({ theme }) => ({
          fill: 'white',
          [theme.breakpoints.down('md')]: {
            ...theme.typography.captionBold,
          },
          [theme.breakpoints.up('md')]: {
            ...theme.typography.button,
          },
        }),
        root: ({ theme }) => ({
          height: 24,
          width: 24,
          color: theme.palette.gray.dark,
          '&.Mui-active': {
            color: theme.palette.text.primary,
          },
          '&.Mui-completed': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.main,
          textDecorationColor: theme.palette.secondary.main,
          cursor: 'pointer',
          '&[disabled]': {
            color: 'grey',
            cursor: 'not-allowed',
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '8px',
        },
      },
    },
  },
};

const theme = createTheme(deepmerge(defaultTheme, defaultOverrides));

export default responsiveFontSizes(theme, {
  variants: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'captionBold',
    'overline',
    'pricing',
  ],
});
