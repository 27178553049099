export const GERMANY_NAME = 'Deutschland';
export const GERMANY_ISO = 'DE';
export const UNITED_KINGDOM_NAME = 'United Kingdom';
export const UNITED_KINGDOM_ISO = 'UK';

export default {
  GERMANY_NAME,
  GERMANY_ISO,
  UNITED_KINGDOM_NAME,
  UNITED_KINGDOM_ISO,
};
