import { createSlice } from '@reduxjs/toolkit';

const initialDeliveryState = {
  billingAddress: {},
  comments: [],
  customer: {},
  deliveryAddress: {},
  deliveryOption: 'DELIVERY',
  sameDeliveryBilling: true,
  isDeliveryClean: true,
};

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState: initialDeliveryState,
  reducers: {
    copyBillingToDelivery: (state) => {
      state.deliveryAddress = state.billingAddress;
    },
    resetDelivery: (state) => {
      return {
        ...initialDeliveryState,
        billingAddress: {
          ...initialDeliveryState.billingAddress,
          country: state.billingAddress.country,
        },
        deliveryAddress: {
          ...initialDeliveryState.deliveryAddress,
          country: state.deliveryAddress.country,
        },
        deliveryOption: state.deliveryOption,
      };
    },
    setBillingAddressField: (state, action) => {
      state.billingAddress[action.payload.field] = action.payload.value;
      if (state.isDeliveryClean) state.isDeliveryClean = false;
    },
    setCustomerField: (state, action) => {
      state.customer[action.payload.field] = action.payload.value;
      if (state.isDeliveryClean) state.isDeliveryClean = false;
    },
    setDefaultValues: (state, action) => {
      state.deliveryOption = action.payload.deliveryOption;
      state.billingAddress.country = action.payload.country;
      state.deliveryAddress.country = action.payload.country;
    },
    setDeliveryAddressField: (state, action) => {
      state.deliveryAddress[action.payload.field] = action.payload.value;
      if (state.isDeliveryClean) state.isDeliveryClean = false;
    },
    setDeliveryOption: (state, action) => {
      state.deliveryOption = action.payload;
    },
    setSameDeliveryBilling: (state, action) => {
      state.sameDeliveryBilling = action.payload;
    },
  },
});

export const {
  copyBillingToDelivery,
  resetDelivery,
  setBillingAddressField,
  setCustomerField,
  setDefaultValues,
  setDeliveryAddressField,
  setDeliveryOption,
  setSameDeliveryBilling,
} = deliverySlice.actions;

export default deliverySlice.reducer;
