import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { converters } from '@ft-frontend/core';

import { dynamicActivate } from 'utils/i18nLoaderHelper';

const LanguageSelector = ({ visible, availableLocales, defaultLanguage }) => {
  const [language, setLanguage] = useState(defaultLanguage);

  if (!visible) {
    return;
  }

  const handleChange = (event) => {
    setLanguage(event.target.value);
    dynamicActivate(event.target.value);
  };

  return (
    <Select
      color='secondary'
      sx={{
        color: 'white',
        width: '137px',
        fontSize: '12px',
        '::before': {
          borderBottom: 'medium none white',
          content: 'none',
          transition: 'all 0s ease 0s',
        },
        '::after': {
          borderBottom: 'medium none white',
          transition: 'all 0s ease 0s',
        },
        '.MuiSelect-select': {
          paddingBottom: '0px',
        },
        '.MuiSelect-icon': {
          top: 'unset',
          color: 'white',
          height: '14px',
          width: '14px',
        },
      }}
      variant='standard'
      IconComponent={ExpandMoreIcon}
      value={language}
      onChange={handleChange}
    >
      {availableLocales.map((locale) => (
        <MenuItem value={locale} key={locale}>
          {converters.getLanguageName(locale) || locale}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelector;
