import { useEffect } from 'react';

export const useAutoScroll = (headerOffset = 0) => {
  useEffect(() => {
    const id = location.hash.replace('#', '');

    if (!id) {
      return;
    }

    const elementById = document.getElementById(id);

    if (elementById) {
      window.scrollTo({
        top: elementById.getBoundingClientRect().top - headerOffset,
        behavior: 'smooth',
      });
    }
  }, [location.hash]);
};

export default useAutoScroll;
