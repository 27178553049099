import { isToday } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { createSearchParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';

export const currencyFormatter = (value, currency, options = {}) =>
  (value || value === 0) &&
  currency &&
  i18n.number(value, {
    style: 'currency',
    currency: currency === '£' ? 'GBP' : currency,
    ...options,
  });

export const dateFormatter = (value, todayString, options = {}) => {
  if (todayString && isToday(new Date(value))) {
    return <Trans id='date.formatter.today'>Today</Trans>;
  } else {
    return i18n.date(value, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      ...options,
    });
  }
};

export const getCountryName = (country) =>
  country
    ? new Intl.DisplayNames([i18n.locale], { type: 'region' }).of(country)
    : '';

export const numberFormatter = (value, options = {}) =>
  i18n.number(value, options);

export const urlSearchFormatter = (url, searchParams) =>
  searchParams.size > 0 ||
  !isEmpty(searchParams) ||
  Array.from(searchParams.entries()).length > 0
    ? `${url}?${createSearchParams(searchParams).toString()}`
    : url;
