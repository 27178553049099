import { apiServiceClient } from '../frameworkClient';

const posaleServiceClient = apiServiceClient(
  process.env.REACT_APP_POSALE_URL || 'framework/PosaleService',
);

const endpoints = {
  changeSelectedStore: 'changeSelectedStore',
  getDeviceList: 'getDeviceList',
  getRuleTableData: 'getRuleTableData',
  getRuleTableMetaData: 'getRuleTableMetaData',
  getStartingInfo: 'getStartingInfo',
  logout: 'logout',
  saveRule: 'saveRule',
  deleteRule: 'deleteRule',
};

export const changeSelectedStore = async (
  { storeID, cartUUID, sessionID },
  options,
) =>
  posaleServiceClient(
    endpoints.changeSelectedStore,
    { storeID, cartUUID, sessionID },
    options,
  );

export const getStartingInfo = async (emptyPayload, options) =>
  posaleServiceClient(endpoints.getStartingInfo, emptyPayload, options);

export const logout = async ({ sessionID }, options) =>
  posaleServiceClient(endpoints.logout, { sessionID }, options);

export const saveRule = async (
  { sessionID, data, ruleTableIdentifier },
  options,
) => {
  return posaleServiceClient(
    endpoints.saveRule,
    {
      sessionID,
      data,
      ruleTableIdentifier,
    },
    options,
  );
};

export const deleteRule = async (
  { sessionID, ruleID, ruleTableIdentifier },
  options,
) => {
  return posaleServiceClient(
    endpoints.deleteRule,
    {
      sessionID,
      ruleTableIdentifier,
      ruleID,
    },
    options,
  );
};

export const getRuleTableData = async ({ sessionID, ruleTableIdentifier }) => {
  return posaleServiceClient(endpoints.getRuleTableData, {
    sessionID,
    ruleTableIdentifier,
  });
};

export const getDeviceList = async ({
  sessionID,
  manufacturer,
  category,
  family,
  searchType,
}) => {
  return posaleServiceClient(endpoints.getDeviceList, {
    sessionID,
    manufacturer,
    category,
    family,
    onlyEnabled: true,
    onlyEnabledForUpgrade: false,
    searchType,
  });
};

export const getRuleTableMetaData = async ({
  sessionID,
  ruleTableIdentifier,
}) => {
  return posaleServiceClient(endpoints.getRuleTableMetaData, {
    sessionID,
    ruleTableIdentifier,
  });
};

export default {
  endpoints,
  getDeviceList,
  getRuleTableData,
  getRuleTableMetaData,
  changeSelectedStore,
  getStartingInfo,
  logout,
  saveRule,
  deleteRule,
};
