export const isEnv = (env) =>
  process.env.REACT_APP_ENV_NAME?.toLowerCase()?.startsWith(env);

export const isDemoEnv = () => isEnv('demo');

export const isDevEnv = () => isEnv('dev');

export const isProdEnv = () => isEnv('prod');

export const isStageEnv = () => isEnv('stage');

export const isRCEnv = () => isEnv('rc');

const defaultExport = {
  isEnv,
  isDemoEnv,
  isDevEnv,
  isProdEnv,
  isStageEnv,
  isRCEnv,
};

export default defaultExport;
