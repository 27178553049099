import React from 'react';
import { useSelector } from 'react-redux';

import Unauthorized from 'components/Unauthorized';

import { loginPageUrl } from 'constants/routes';
import { ALL_ROLES } from 'constants/user';

const RoleWrapper = ({ allowedRoles = ALL_ROLES, children }) => {
  const user = useSelector((state) => state?.redux?.user);

  if (!user?.session_token) {
    window.location.href = loginPageUrl;
  }

  return allowedRoles.includes(user?.role) ? children : <Unauthorized />;
};

export default RoleWrapper;
