import { useEffect } from 'react';

import { servicesUrl } from 'constants/routes';

const NavigateSqueaky = () => {
  useEffect(() => {
    if (window.location.pathname !== servicesUrl) {
      window.location.href = servicesUrl;
    }
  });
};

export default NavigateSqueaky;
