import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const ScrollToTop = ({ children }) => {
  let location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('noscroll') !== 'true') window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

export default ScrollToTop;
