import React from 'react';

import { Trans } from '@lingui/macro';

import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Image } from '@ft-frontend/core';

import AvailabilityIndicator from 'components/AvailabilityIndicator';

import useStartingInfo from 'contexts/starting_info/useStartingInfo';

import { currencyFormatter } from 'utils/formatters';

export default function Card({ item = {}, removeItem, blocked }) {
  const { currency } = useStartingInfo();

  return (
    <Stack spacing={1} data-testid={`cart-widget-item-${item.code}`}>
      <Grid container>
        <Grid item xs='auto' data-testid='cart-widget-item-img'>
          <Image
            width='56'
            filePath={item.imgURL || item.media?.[0]?.url || ''}
            alt={item.description || item.name}
          />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs={9}>
            <Stack
              direction='column'
              sx={(theme) => ({ paddingLeft: theme.spacing(2) })}
            >
              <Typography
                variant='body1'
                sx={{ fontWeight: 600 }}
                data-testid='cart-widget-item-name'
              >
                {item.description || item.name}
              </Typography>
              <Typography
                variant='body2'
                sx={{ color: 'text.secondary' }}
                data-testid='cart-widget-item-code'
              >
                {item.code}
              </Typography>
            </Stack>
          </Grid>

          {!blocked && removeItem && (
            <Grid item xs>
              <IconButton
                aria-label='close'
                onClick={removeItem}
                sx={{
                  float: 'right',
                  padding: '0',
                  color: 'gray.dark',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          {item.stockLevel && (
            <AvailabilityIndicator
              justifyContent='flex-start'
              stockStatus={item.stockLevel}
            />
          )}
        </Grid>

        <Grid item xs={8} container rowSpacing={1}>
          <Grid item xs={4} sx={{ textAlign: 'center' }}>
            <Typography variant='body2' sx={{ fontWeight: 600 }}>
              <Trans id='online.card.widget.cash.price'>Cash price</Trans>
            </Typography>
          </Grid>
          <Grid item xs={4} sm sx={{ textAlign: 'center' }}>
            <Typography variant='body2' sx={{ fontWeight: 600 }}>
              <Trans id='online.card.widget.quantity'>Qty</Trans>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack
              spacing={0}
              sx={(theme) => ({
                paddingRight: theme.spacing(1),
              })}
            >
              <Typography
                variant='body2'
                sx={{ fontWeight: 600 }}
                align='right'
              >
                <Trans id='online.card.widget.total.price'>Total price</Trans>
              </Typography>
              <Typography variant='caption' align='right'>
                <Trans id='online.card.widget.per.month'>per month </Trans>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Paper
              sx={{
                backgroundColor: 'gray.light',
                borderRadius: 0,
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTopLeftRadius: '2px',
                borderBottomLeftRadius: '2px',
              }}
              elevation={0}
            >
              <Typography
                variant='body2'
                sx={{ fontWeight: 600 }}
                align='center'
                data-testid='cart-widget-item-cash-price'
              >
                {currencyFormatter(item.cashPrice, currency)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              sx={{
                backgroundColor: 'gray.light',
                borderRadius: 0,
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              elevation={0}
            >
              <Typography
                variant='body2'
                sx={{ fontWeight: 600 }}
                data-testid='cart-widget-item-qty'
              >
                {item.quantity || 1}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              sx={(theme) => ({
                backgroundColor: 'gray.light',
                borderRadius: 0,
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
                paddingRight: theme.spacing(1),
              })}
              elevation={0}
            >
              <Typography
                variant='body2'
                sx={{ fontWeight: 600 }}
                align='right'
                data-testid='cart-widget-item-monthly-price'
              >
                {currencyFormatter(item.monthlyPrice, currency)}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
