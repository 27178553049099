import React from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import { Image } from '@ft-frontend/core';

import CartWidget from 'components/Online/CartWidget/CartWidget';

import useStartingInfo from 'contexts/starting_info/useStartingInfo';

export default function OnlineHeader({ showCartWidget = true }) {
  const whitelabel = useStartingInfo();

  const navigate = useNavigate();

  return (
    <AppBar sx={{ height: '120px' }} position='fixed' elevation={0}>
      <Toolbar
        disableGutters
        sx={{
          minHeight: '64px !important',
          maxHeight: '64px !important',
          padding: '8px 24px',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1366px',
          }}
        >
          <Image
            filePath={whitelabel.onlinePrimaryLogo}
            alt={`${whitelabel.name} logo`}
            height='32'
            onClick={() => showCartWidget && navigate('/online')}
            style={showCartWidget && { cursor: 'pointer' }}
            data-testid='header-app-logo'
          />

          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </Toolbar>
      <Toolbar
        disableGutters
        sx={{
          minHeight: '56px !important',
          maxHeight: '56px !important',
          padding: '8px 24px',
          justifyContent: 'center',
          backgroundColor: 'primary.dark',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1366px',
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          {showCartWidget && <CartWidget />}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
