import MUISkeleton from '@mui/material/Skeleton';

const Skeleton = ({ isLoading = false, children, ...props }) => {
  const isText =
    props.variant === null ||
    props.variant === undefined ||
    props.variant === 'text';

  return !isLoading ? (
    children
  ) : (
    <MUISkeleton
      sx={{
        ...(isText && {
          transform: 'scale(1, 0.9)',
        }),
      }}
      {...props}
    >
      {!isText && children}
    </MUISkeleton>
  );
};

export default Skeleton;
