import React from 'react';

import { Trans } from '@lingui/macro';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Skeleton from 'components/Skeleton';

import useStartingInfo from 'contexts/starting_info/useStartingInfo';

import { currencyFormatter, numberFormatter } from 'utils/formatters';

const RepresentativeExample = ({
  isLoading,
  order,
  hasMultipleOrders = false,
}) => {
  let customDisclaimers = {
    BNP_UK: <BNPRepresentativeExample isLoading={isLoading} order={order} />,
    ONEYFR: <OneyRepresentativeExample isLoading={isLoading} order={order} />,
  };

  return (
    customDisclaimers[order?.financing?.funder] || (
      <DefaultRepresentativeExample
        isLoading={isLoading}
        order={order}
        hasMultipleOrders={hasMultipleOrders}
      />
    )
  );
};

const BNPRepresentativeExample = ({ isLoading, order }) => {
  return (
    <>
      <Stack direction='row' spacing={1}>
        <Typography
          variant='body2'
          data-testid='representative-example-cashprice'
        >
          <Skeleton loading={isLoading}>
            <Trans id='payment.disclaimer.representative.bnp.cashprice'>
              Cash Price:
            </Trans>{' '}
            {currencyFormatter(
              order?.financing?.deviceContractFinance?.totalSaleAmount,
              order?.financing?.currencyCode,
            )}
            {'. '}
          </Skeleton>
        </Typography>

        <Typography
          variant='body2'
          data-testid='representative-example-amountcredit'
        >
          <Skeleton loading={isLoading}>
            <Trans id='payment.disclaimer.representative.bnp.amountcredit'>
              Amount of Credit:
            </Trans>{' '}
            {currencyFormatter(
              order?.financing?.deviceContractFinance?.principal,
              order?.financing?.currencyCode,
            )}
            {'. '}
          </Skeleton>
        </Typography>

        {order?.financing?.openingFeeAmount > 0 && (
          <Typography
            variant='body2'
            data-testid='representative-example-deposit'
          >
            <Skeleton loading={isLoading}>
              <Trans id='payment.disclaimer.representative.bnp.deposit'>
                Deposit:
              </Trans>{' '}
              {currencyFormatter(
                order?.financing?.openingFeeAmount,
                order?.financing?.currencyCode,
              )}
              {'. '}
            </Skeleton>
          </Typography>
        )}
      </Stack>

      <Typography variant='body2' data-testid='representative-example'>
        <Skeleton loading={isLoading}>
          <Trans id='payment.disclaimer.representative.bnp'>
            Settle the loan by paying {order?.financing?.fixedPeriodEndMonth}{' '}
            monthly instalments of{' '}
            {currencyFormatter(
              order?.financing?.fixedPeriodMonthlyPayment,
              order?.financing?.currencyCode,
            )}
            . Total amount payable is{' '}
            {currencyFormatter(
              order?.financing?.deviceContractFinance?.totalPayments,
              order?.financing?.currencyCode,
            )}
            . Rate of interest{' '}
            {numberFormatter(
              order?.financing?.deviceContractFinance?.interestRate,
            )}
            %.{' '}
            {numberFormatter(
              order?.financing?.deviceContractFinance?.annualisedInterestRate,
            )}
            % APR representative.
          </Trans>
        </Skeleton>
      </Typography>
    </>
  );
};

const OneyRepresentativeExample = ({ isLoading, order }) => {
  return (
    <Stack spacing={1}>
      <Typography
        variant='body2'
        data-testid='representative-example-binding'
        sx={{ fontWeight: 600 }}
      >
        <Skeleton loading={isLoading}>
          <Trans id='payment.disclaimer.representative.oney.binding'>
            A CREDIT IS BINDING TO YOU AND MUST BE REFUNDED. CHECK YOUR
            REIMBURSEMENT CAPABILITY BEFORE COMMITTING.
          </Trans>
        </Skeleton>
      </Typography>

      <Typography
        variant='body2'
        data-testid='representative-example-amountcredit'
      >
        <Skeleton loading={isLoading}>
          <Trans id='payment.disclaimer.representative.oney.cashprice'>
            For your purchase of{' '}
            {currencyFormatter(
              order?.financing?.deviceContractFinance?.totalSaleAmount,
              order?.financing?.currencyCode,
            )}
            , you reimburse:
          </Trans>{' '}
          {order?.financing.flexPeriodEndMonth} X{' '}
          {currencyFormatter(
            order?.financing?.fixedPeriodMonthlyPayment,
            order?.financing?.currencyCode,
          )}
          {'. '}
        </Skeleton>
        <Skeleton loading={isLoading}>
          <Trans id='payment.disclaimer.representative.oney.amount.credit'>
            Funding amount:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.principal,
            order?.financing?.currencyCode,
          )}
          {'. '}
        </Skeleton>
        <Skeleton loading={isLoading}>
          <Trans id='payment.disclaimer.representative.oney.amount.due'>
            Total amount due:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.totalPayments,
            order?.financing?.currencyCode,
          )}
          {'. '}
        </Skeleton>
        <Skeleton loading={isLoading}>
          <Trans id='payment.disclaimer.representative.oney.credit.cost'>
            Cost of financing:
          </Trans>{' '}
          {currencyFormatter(
            order?.financing?.deviceContractFinance?.costOfCredit,
            order?.financing?.currencyCode,
          )}
          {'. '}
        </Skeleton>
        <Skeleton loading={isLoading}>
          <Trans id='payment.disclaimer.representative.oney.interests'>
            Credit allocated over {order?.financing.flexPeriodEndMonth} months
            at the FIXED APR of{' '}
            {
              order?.financing.annualisedInterestRateToDisplay
                .upgradeFinancePercent
            }
            % and at the fixed lending rate of{' '}
            {order?.financing.deviceContractFinance.interestRate}%.
          </Trans>
        </Skeleton>
      </Typography>
    </Stack>
  );
};

const DefaultRepresentativeExample = ({
  isLoading,
  order,
  hasMultipleOrders = false,
}) => {
  const upfrontText = (
    <>
      <Trans id='online.options.planInfo.upfront.text'>
        Based on your order of{' '}
        {currencyFormatter(
          order?.financing?.startingPrincipal &&
            order?.financing?.openingFeeAmount
            ? order?.financing?.startingPrincipal +
                order?.financing?.openingFeeAmount
            : undefined,
          order?.financing?.currencyCode,
        )}
        an initial deposit of{' '}
        {currencyFormatter(
          order?.financing?.openingFeeAmount,
          order?.financing?.currencyCode,
        )}{' '}
        will be required.
      </Trans>{' '}
    </>
  );

  const productText = (
    <>
      {hasMultipleOrders ? `${order?.product?.familyName} - ` : ''}
      {order?.financing?.openingFeePercentage > 0 && upfrontText}
      <Trans id='online.planInfo.payment.text'>
        Total credit amount{' '}
        {currencyFormatter(
          order?.financing?.startingPrincipal,
          order?.financing?.currencyCode,
        )}{' '}
        with {order?.financing?.fixedPeriodEndMonth} monthly payments of{' '}
        {currencyFormatter(
          order?.financing?.fixedPeriodMonthlyPayment,
          order?.financing?.currencyCode,
        )}{' '}
        at{' '}
        {numberFormatter(
          order?.financing?.deviceContractFinance?.financeType === 'UPGRADE'
            ? order?.financing?.annualisedInterestRateToDisplay
                ?.upgradeFinancePercent
            : order?.financing?.annualisedInterestRateToDisplay
                ?.standardFinancePercent,
        )}
        {'% '}
        {order?.financing?.annualisedInterestRateToDisplay?.displayType}.
      </Trans>
      {order?.financing?.deviceContractFinance?.financeType === 'UPGRADE' && (
        <>
          {' '}
          <Trans id='online.planInfo.upgrade.text'>
            Return the {order?.product?.familyName} after{' '}
            {order?.financing?.fixedPeriodEndMonth} payments to settle the
            outstanding loan (total amount payable{' '}
            {currencyFormatter(
              order?.financing?.totalPaymentsFixedPeriod,
              order?.financing?.currencyCode,
            )}
            ) or keep the device and make {order?.financing?.flexPeriodPayments}{' '}
            further monthly payments (total amount{' '}
            {currencyFormatter(
              order?.financing?.totalPayments,
              order?.financing?.currencyCode,
            )}
            ).
          </Trans>
        </>
      )}
    </>
  );
  const otherProductsText = (
    <Trans id='online.planInfo.payment.other.products.text'>
      Other Products - Total credit amount{' '}
      {currencyFormatter(
        order?.financing?.startingPrincipal,
        order?.financing?.currencyCode,
      )}{' '}
      with {order?.financing?.fixedPeriodEndMonth} monthly payments of{' '}
      {currencyFormatter(
        order?.financing?.fixedPeriodMonthlyPayment,
        order?.financing?.currencyCode,
      )}{' '}
      at{' '}
      {numberFormatter(
        order?.financing?.annualisedInterestRateToDisplay
          ?.standardFinancePercent,
      )}
      {'% '}
      {order?.financing?.annualisedInterestRateToDisplay?.displayType} (total
      amount payable{' '}
      {currencyFormatter(
        order?.financing?.totalPayments,
        order?.financing?.currencyCode,
      )}
      ).
    </Trans>
  );

  return (
    <Typography variant='body2' data-testid='representative-example'>
      <Skeleton loading={isLoading}>
        {order?.product && productText}
        {order?.extras && !order?.product && otherProductsText}
      </Skeleton>
    </Typography>
  );
};

const RepresentativeLegal = ({
  fcaNumber = '#',
  funder = 'Bank',
  isLoading,
  order,
  wlName = 'Retailer',
  wlRegisteredName = 'Retailer',
  wlAddress = 'Retailer Address',
}) => {
  let customLegals = {
    BNP_UK: (
      <BNPRepresentativeLegal
        isLoading={isLoading}
        wlRegisteredName={wlRegisteredName}
        fcaNumber={fcaNumber}
      />
    ),
    ONEYFR: (
      <OneyRepresentativeLegal
        isLoading={isLoading}
        wlRegisteredName={wlRegisteredName}
        fcaNumber={fcaNumber}
        order={order}
        wlAddress={wlAddress}
        wlName={wlName}
      />
    ),
  };

  return (
    customLegals[funder] || (
      <DefaultRepresentativeLegal
        isLoading={isLoading}
        wlName={wlName}
        funder={funder}
      />
    )
  );
};

const DefaultRepresentativeLegal = ({
  isLoading,
  wlName = 'Retailer',
  funder = 'Bank',
}) => {
  return (
    <Typography
      variant='body2'
      data-testid='representative-legal'
      sx={{ fontStyle: 'italic' }}
    >
      <Skeleton loading={isLoading}>
        <Trans id='options.disclaimers.body'>
          {wlName} acts as credit broker / intermediary and not as a lender.
          Credit products are provided exclusively by
          {funder}, which is authorised and regulated by the Financial Services
          Authority, with limited supervision by the Financial Conduct
          Authority.
        </Trans>
      </Skeleton>
    </Typography>
  );
};

const BNPRepresentativeLegal = ({
  isLoading,
  wlRegisteredName = 'Retailer',
  fcaNumber = '#',
}) => {
  return (
    <Typography
      variant='body2'
      data-testid='representative-legal'
      sx={{ fontStyle: 'italic' }}
    >
      <Skeleton loading={isLoading}>
        <Trans id='options.disclaimers.body.bnp'>
          {wlRegisteredName} is authorised by the Financial Conduct Authority as
          a credit broker and not as a lender. Financial Conduct Association
          registration number {fcaNumber}. Credit is exclusively provided by
          Creation Consumer Finance Limited. Registered Office: 4th - 6th floor
          Wellington Buildings, 2-4 Wellington Street, Belfast, BT1 6HT
          (Northern Ireland - company number NI032565). Authorised and regulated
          by the Financial Conduct Authority. Registration Number 311518. To
          access their privacy policy go to{' '}
          <a
            target='_blank'
            href='https://www.creation.co.uk/about-us/data-protection-notice'
            rel='noreferrer'
          >
            https://www.creation.co.uk/about-us/data-protection-notice
          </a>
        </Trans>
      </Skeleton>
    </Typography>
  );
};

const OneyRepresentativeLegal = ({
  fcaNumber = '#',
  isLoading,
  order,
  wlAddress,
  wlName,
  wlRegisteredName = 'Retailer',
}) => {
  return (
    <>
      <Typography
        variant='body2'
        data-testid='representative-legal'
        sx={{ fontStyle: 'italic' }}
      >
        <Skeleton loading={isLoading}>
          <Trans id='options.disclaimers.body.oney'>
            Credit assigned without insurance valid from €200 to €4,600 valid at{' '}
            {wlName}, over {order?.financing.flexPeriodEndMonth} months at the
            fixed APR of{' '}
            {
              order?.financing.annualisedInterestRateToDisplay
                .upgradeFinancePercent
            }
            % and at the fixed lending rate of{' '}
            {order?.financing.deviceContractFinance.interestRate}%, depending on
            the amount of the purchase. You have a withdrawal period of 14 days
            to waive your credit. Conditions as of 09/11/2024. Oney Bank SA with
            capital of €71,801,205 - Head office: 34 avenue de Flandre 59170
            CROIX - RCS Lille Métropole 546 380 197 - NºORIAS 07 023 261 -
            www.orias.fr - Correspondence: CS 60006 - 59895 Lille Cedex 9 -
            www.oney.fr.
          </Trans>{' '}
        </Skeleton>
      </Typography>

      <Typography
        variant='body2'
        data-testid='representative-legal'
        sx={{ fontStyle: 'italic', fontWeight: 600 }}
      >
        <Skeleton loading={isLoading}>
          <Trans id='options.disclaimers.body.oney.suffix'>
            {wlRegisteredName} acts as exclusive agent for banking operations
            and payment services - ORIAS number: {fcaNumber} - Head office:{' '}
            {wlAddress}
          </Trans>
        </Skeleton>
      </Typography>
    </>
  );
};

export default function PaymentDisclaimers({
  hasMultipleOrders = false,
  isLoading,
  order,
}) {
  const {
    name: wlName,
    wlAddress,
    wlRegisteredName,
    fcaNumber,
  } = useStartingInfo();

  return (
    <Stack spacing={1} data-testid='representative-block'>
      <Typography variant='body2' sx={{ fontWeight: 600 }}>
        <Trans id='payment.disclaimer.representative.title'>
          Representative Example:
        </Trans>
      </Typography>

      <RepresentativeExample
        isLoading={isLoading}
        order={order}
        wlName={wlName}
        hasMultipleOrders={hasMultipleOrders}
      />

      <RepresentativeLegal
        fcaNumber={fcaNumber}
        funder={order?.financing?.funder}
        isLoading={isLoading}
        order={order}
        wlAddress={wlAddress}
        wlName={wlName}
        wlRegisteredName={wlRegisteredName}
      />
    </Stack>
  );
}
