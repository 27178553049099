import { apiServiceClient } from '../frameworkClient';

const wlServiceClient = apiServiceClient('framework/WLService');

const endpoints = {
  getForm: 'getForm',
  getWhitelabels: 'getWhitelabels',
  getWhitelabelByID: 'getWhitelabelByID',
  createWhitelabel: 'createWhitelabel',
  updateWhitelabel: 'updateWhitelabel',
};

export const getForm = async (_payload, options) =>
  await wlServiceClient(endpoints.getForm, {}, options);

export const getWhitelabels = async (_payload, options) =>
  wlServiceClient(endpoints.getWhitelabels, {}, options);

export const getWhitelabelByID = async ({ wlID }, options) =>
  wlServiceClient(endpoints.getWhitelabelByID, { params: { wlID } }, options);

export const createWhitelabel = async ({ whitelabel }, options) =>
  await wlServiceClient(
    endpoints.createWhitelabel,
    {
      params: { whitelabel },
    },
    options,
  );

export const updateWhitelabel = async ({ whitelabel }, options) =>
  await wlServiceClient(
    endpoints.updateWhitelabel,
    {
      params: { whitelabel },
    },
    options,
  );

export default {
  endpoints,
  getForm,
  getWhitelabels,
  getWhitelabelByID,
  createWhitelabel,
  updateWhitelabel,
};
