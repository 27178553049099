import isEqual from 'lodash/isEqual';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import * as Sentry from '@sentry/react';

import { validators } from '@ft-frontend/core';
import { ScrollToTop, TrackingPixel, useAutoScroll } from '@ft-frontend/core';

import ConsentBanner from 'components/ConsentBanner';
import ErrorMessage from 'components/ErrorMessage';
import OnlineFooter from 'components/OnlineFooter';
import OnlineHeader from 'components/OnlineHeader';
import PageStepper from 'components/PageStepper';

import OnlineStartingInfoProvider from 'contexts/starting_info/OnlineStartingInfoProvider';

import { setSwapUUID } from 'reducers/flow/flowSlice';

const fullWidthPages = ['/contract/summary/'];
const stickyFooterPages = ['/online/extras'];

const locationHasFullWidth = (curPathname) => {
  return fullWidthPages.some((path) => curPathname.includes(path));
};

const locationHasStickyFooter = (curPathname) => {
  return stickyFooterPages.some((path) => curPathname.includes(path));
};

const Online = () => {
  const [searchParams] = useSearchParams();

  const orderGUID = searchParams.get('oid');
  const cartUUID = searchParams?.get('cart');
  const trackerName = searchParams?.get('name');
  const campaignName = searchParams?.get('cn');
  const templateName = searchParams?.get('tn');
  const hideHeaderFooter = searchParams?.get('redirect') === 'true';

  const wlId = useSelector((state) => state.ui.wlId);
  const storeId = useSelector((state) => state.ui.storeId);

  const dispatch = useDispatch();

  const [pageStep, setPageStep] = useState();
  const [showCartWidget, setShowCartWidget] = useState(true);

  const { swapUUID } = useSelector((state) => state.flow);
  const { pageName } = useSelector((state) => state.ui);

  useAutoScroll(60);
  const location = useLocation();

  const [hasFullWidth, setFullWidth] = useState(
    locationHasFullWidth(location.pathname),
  );

  const [hasStickyFooter, setStickyFooter] = useState(
    locationHasStickyFooter(location.pathname),
  );

  useEffect(() => {
    setFullWidth(locationHasFullWidth(location.pathname));
    setStickyFooter(locationHasStickyFooter(location.pathname));
    if (orderGUID && !isEqual(orderGUID, swapUUID))
      dispatch(setSwapUUID(orderGUID));
  }, [dispatch, location.pathname, orderGUID, swapUUID]);

  return (
    <OnlineStartingInfoProvider>
      <ScrollToTop>
        {!hideHeaderFooter && <OnlineHeader showCartWidget={showCartWidget} />}

        <Stack
          alignItems='center'
          sx={(theme) => ({
            marginTop: hideHeaderFooter ? '0' : '123px',
            padding: hasFullWidth ? '0' : theme.spacing(2),
            paddingBottom: hasStickyFooter ? '0' : theme.spacing(4),
          })}
          spacing={1}
        >
          {pageStep !== undefined && !hideHeaderFooter && (
            <Grid
              container
              sx={{
                maxWidth: hasFullWidth ? undefined : '1366px !important',
              }}
            >
              <PageStepper activeStep={pageStep} />
            </Grid>
          )}
          <Box
            sx={{
              width: '100%',
              maxWidth: hasFullWidth ? undefined : '1366px !important',
            }}
          >
            <Sentry.ErrorBoundary
              fallback={({ resetError }) => (
                <ErrorMessage resetError={resetError} />
              )}
            >
              <Outlet
                context={[
                  setPageStep,
                  setShowCartWidget,
                  validators.isIframe(),
                ]}
              />
            </Sentry.ErrorBoundary>
          </Box>
        </Stack>

        {!hideHeaderFooter && <OnlineFooter />}
        <ConsentBanner />
      </ScrollToTop>
      <TrackingPixel
        cartUUID={cartUUID}
        type='pagetrack'
        wlID={wlId}
        storeID={storeId}
        pageName={pageName}
        param1={trackerName}
        param2={
          templateName && campaignName && `${templateName}::${campaignName}`
        }
      />
    </OnlineStartingInfoProvider>
  );
};

export default Online;
