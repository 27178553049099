export const getSubOrFullDomain = () =>
  window.location.hostname.match(/.*financetech(demo|test|365).com/g)
    ? window.location.hostname.split('.')[0]
    : window.location.hostname;

export const getSubdomain = () =>
  process.env.REACT_APP_SUBDOMAIN || getSubOrFullDomain();

export const getWhitelabelShortName = () => {
  const subdomain = getSubdomain();
  const domainSplit = subdomain?.split('-');

  if (domainSplit?.length > 0) {
    return domainSplit[domainSplit.length - 1];
  }
};

export default {
  getSubOrFullDomain,
  getSubdomain,
  getWhitelabelShortName,
};
