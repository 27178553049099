import { apiServiceClient } from '../frameworkClient';

const requestServiceClient = apiServiceClient('framework/RequestsService');

const endpoints = {
  getRequests: 'getRequests',
};

export const getRequests = async (
  { ip = null, session = null, uuid = null, date = null } = {},
  options,
) => {
  return requestServiceClient(
    endpoints.getRequests,
    {
      params: { ip, session, uuid, date },
    },
    options,
  );
};

export default {
  endpoints,
  getRequests,
};
