import { useEffect, useRef, useState } from 'react';

export const usePolling = ({
  callback,
  interval,
  args = {},
  initialCall = false,
}) => {
  const savedCallback = useRef();
  const [intervalID, setIntervalID] = useState();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    initialCall && callback({ ...args });
  }, [initialCall]);

  useEffect(() => {
    if (interval !== null) {
      let id = setInterval(() => savedCallback.current({ ...args }), interval);
      setIntervalID(id);
      return () => clearInterval(id);
    }
  }, [interval]);

  const stopPolling = () => {
    clearInterval(intervalID);
  };

  return { stopPolling };
};

export default usePolling;
