import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Trans } from '@lingui/macro';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function ErrorMessage({ resetError }) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      spacing={1}
      direction='column'
      sx={{ flexGrow: 1, padding: '8px' }}
    >
      <Grid item xs={6}>
        <Stack spacing={3}>
          <Grid container justifyContent='center' spacing={0}>
            <Grid container justifyContent='center' item xs={6}>
              <img
                style={{
                  maxWidth: '104px',
                  maxHeight: '104px',
                  margin: '80px 0px',
                }}
                src='https://cdn.financetech365.com/svg/plug.svg'
                srcSet='https://cdn.financetech365.com/svg/plug.svg'
                alt='ERROR'
                width='100%'
                height='auto'
              />
            </Grid>
          </Grid>

          <Box>
            <Typography variant='h2'>
              <Trans id='something.wrong.initial.title'>Ooops</Trans>
            </Typography>
            <Typography variant='h2'>
              <Trans id='something.wrong.initial.subtitle'>
                Something went wrong
              </Trans>
            </Typography>
          </Box>

          <Typography>
            <Trans id='something.wrong.initial.paragraph.one'>
              Don't Panic!
            </Trans>
          </Typography>
          <Typography>
            <Trans id='something.wrong.initial.paragraph.two'>
              Our support team is here to help.
            </Trans>
          </Typography>

          <Link
            component='button'
            underline='always'
            onClick={() => {
              window.history.length > 1
                ? navigate(-1)
                : window.location.reload(true);
              window.setTimeout(() => resetError(), 100);
            }}
            sx={{ textAlign: 'start' }}
          >
            <Typography>
              <Trans id='something.wrong.back'>
                Please click here to go back
              </Trans>
            </Typography>
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
}
