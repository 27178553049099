import { createSlice } from '@reduxjs/toolkit';

const initialUIState = {
  snackbarDisplayTime: 5000,
  pageName: undefined,
  wlId: null,
  storeId: null,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUIState,
  reducers: {
    setSnackbarDisplayTime: (state, action) => {
      state.snackbarDisplayTime =
        action.payload || initialUIState.snackbarDisplayTime;
    },
    resetUI: () => {
      return initialUIState;
    },
    setPageName: (state, action) => {
      window.gtag('event', 'page_view', {
        page_title: action.payload,
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_referrer: document.referrer,
      });
      state.pageName = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },
    setWlId: (state, action) => {
      state.wlId = action.payload;
    },
  },
});

export const {
  setSnackbarDisplayTime,
  setPageName,
  setStoreId,
  setWlId,
  resetUI,
} = uiSlice.actions;

export default uiSlice.reducer;
