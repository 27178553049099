import React, { Fragment } from 'react';

const isCartUUID = (value = '') => value?.match(/(\w{2}-){2}\d{4}-\d{7}/g);

const isDigitOnlyString = (value = '') => value?.match(/^\d+$/);

const TrackingPixel = ({
  cartUUID,
  display = true,
  message,
  orderGUID,
  pageName,
  storeID,
  type,
  userID,
  wlID,
  param1,
  param2,
}) => {
  // sending orderGUID as cartID to show up in dashboard
  const parseOrderGUID = () => `${orderGUID ? `&cartId=${orderGUID}` : ''}`;
  return (
    <Fragment>
      {display && (
        <img
          style={{ visibility: 'hidden', display: 'none' }}
          src={
            `${process.env.REACT_APP_TRACK_URL}` +
            `?action=${type}` +
            `${userID ? `&userId=${userID}` : ''}` +
            `${storeID ? `&storeId=${storeID}` : ''}` +
            `${wlID ? `&wlId=${wlID}` : ''}` +
            `${
              isCartUUID(cartUUID) || isDigitOnlyString(cartUUID)
                ? `&cartId=${cartUUID}`
                : parseOrderGUID()
            }` +
            `${pageName ? `&page=${encodeURI(pageName)}` : ''}` +
            `${message ? `&error=${encodeURI(message)}` : ''}` +
            `${param1 ? `&param1=${encodeURI(param1)}` : ''}` +
            `${param2 ? `&param2=${encodeURI(param2)}` : ''}`
          }
          width='1'
          height='1'
          alt={type}
        />
      )}
    </Fragment>
  );
};

export default TrackingPixel;
