import { apiServiceClient } from '../frameworkClient';

const guarantorServiceClient = apiServiceClient('framework/GuarantorService');

const endpoints = {
  getGuarantors: 'getGuarantors',
};

export const getGuarantors = async (_payload, options) =>
  guarantorServiceClient(endpoints.getGuarantors, {}, options);

export default {
  endpoints,
  getGuarantors,
};
