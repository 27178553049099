import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { Image } from '@ft-frontend/core';
import { posaleService } from '@ft-frontend/core';

import LanguageSelector from 'components/LanguageSelector';
import NavigationMenu from 'components/NavigationMenu';
import UserMenu from 'components/UserMenu';

import useStartingInfo from 'contexts/starting_info/useStartingInfo';

import { usePosaleAPI } from 'hooks/usePosaleAPI';

import { changeSelectedStore } from 'reducers/redux/reduxSlice';

export default function PosaleHeader({ persistor }) {
  const whitelabel = useStartingInfo();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.redux.user);

  const { triggerRequest: callChangeSelectedStore } = usePosaleAPI(
    posaleService.endpoints.changeSelectedStore,
    {
      initialRequest: false,
    },
  );

  const onChangeStore = (event) => {
    const storeIDString = event.target.value;
    const storeID = parseInt(storeIDString);

    callChangeSelectedStore({ storeID });
    dispatch(changeSelectedStore(storeID));
  };

  return (
    <AppBar sx={{ padding: '0 16px' }} position='fixed' elevation={0}>
      <Grid container justifyContent='center'>
        <Grid sx={{ zIndex: '0', maxWidth: '1366px !important' }} item xs={12}>
          <Toolbar
            disableGutters
            sx={{ minHeight: '123px !important', padding: '0px 24px' }}
          >
            <Grid
              container
              direction='column'
              spacing={2}
              sx={{ minHeight: '123px' }}
            >
              <Grid
                item
                container
                sx={{ minHeight: '72px' }}
                xs={12}
                alignItems='center'
                direction='row'
              >
                <Stack direction='row' alignItems='center'>
                  <Image
                    filePath={whitelabel.posalePrimaryLogo}
                    alt={`${whitelabel.name} logo`}
                    height='24'
                  />
                  <Box>
                    <Divider
                      sx={{
                        height: '24px',
                        margin: '0 16px',
                        borderColor: 'white',
                      }}
                      orientation='vertical'
                      variant='middle'
                      flexItem
                    />
                  </Box>
                  <FormControl size='small'>
                    <Select
                      color='secondary'
                      sx={{
                        color: 'white',
                        width: '137px',
                        fontSize: '12px',
                        '::before': {
                          borderBottom: 'medium none white',
                          content: 'none',
                          transition: 'all 0s ease 0s',
                        },
                        '::after': {
                          borderBottom: 'medium none white',
                          transition: 'all 0s ease 0s',
                        },
                        '.MuiSelect-select': {
                          paddingBottom: '0px',
                        },
                        '.MuiSelect-icon': {
                          top: 'unset',
                          color: 'white',
                          height: '14px',
                          width: '14px',
                        },
                      }}
                      variant='standard'
                      value={user.selectedStore}
                      IconComponent={ExpandMoreIcon}
                      onChange={onChangeStore}
                    >
                      {Object.entries(user?.stores || {})?.map(([id, name]) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                <Box sx={{ flexGrow: 1 }} />

                <LanguageSelector
                  visible={whitelabel.configs?.canChangeLanguage}
                  availableLocales={whitelabel.configs?.availableLocales}
                  defaultLanguage={whitelabel.configs?.defaultLanguage}
                />

                {whitelabel.configs?.canChangeLanguage && (
                  <Divider
                    sx={{
                      height: '24px',
                      margin: '0 16px',
                      borderColor: 'white',
                      alignSelf: 'center',
                    }}
                    orientation='vertical'
                    variant='middle'
                    flexItem
                  />
                )}

                <UserMenu
                  name={user.name}
                  username={user.username}
                  hasSingleSignOn={whitelabel?.configs?.hasSingleSignOn}
                  persistor={persistor}
                />
              </Grid>
              <Grid
                item
                container
                sx={{ minHeight: '51px' }}
                xs={12}
                alignItems='center'
                direction='row'
              >
                <Box sx={{ flexGrow: 1 }} />
                <NavigationMenu
                  documentation={whitelabel?.configs?.documentation}
                  menuConfigs={whitelabel?.configs?.menuConfigs}
                  user={user}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
}
