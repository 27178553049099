import { sanitize } from 'dompurify';
import ReactHtmlParser from 'html-react-parser';

import { isValidElement } from 'react';

import { countries } from '../constants';
import { capitalize } from './formatters';

export const countryToISO = (country) => {
  const mapper = {
    [countries.GERMANY_NAME]: countries.GERMANY_ISO,
    [countries.UNITED_KINGDOM_NAME]: countries.UNITED_KINGDOM_ISO,
  };
  return mapper[country];
};

export const ISOToCountry = (country) => {
  const mapper = {
    [countries.GERMANY_ISO]: countries.GERMANY_NAME,
    [countries.UNITED_KINGDOM_ISO]: countries.UNITED_KINGDOM_NAME,
  };
  return mapper[country];
};

export const safeHTMLParser = (html = '', config = {}) =>
  isValidElement(html)
    ? html
    : ReactHtmlParser(
        sanitize(html, {
          KEEP_CONTENT: false,
          ...config,
        }),
      );
export const getCountryName = (country, locale) =>
  new Intl.DisplayNames([locale], { type: 'region' }).of(country);

export const b64toBlob = (url) => fetch(url).then((r) => r.blob());

export const getLanguageName = (locale) =>
  capitalize(new Intl.DisplayNames([locale], { type: 'language' }).of(locale));

export default {
  countryToISO,
  getCountryName,
  getLanguageName,
  safeHTMLParser,
  ISOToCountry,
  b64toBlob,
};
