import React from 'react';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';

import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { Image } from '@ft-frontend/core';

import useStartingInfo from 'contexts/starting_info/useStartingInfo';

export default function Header({ recycler = 'TDSYNNEX' }) {
  const { country } = useStartingInfo();

  const filePath = `https://cdn.financetech365.com/pdfs/${recycler.toLocaleLowerCase()}/${country?.toLocaleLowerCase()}/${i18n.locale?.toLocaleLowerCase()}`;

  return (
    <AppBar elevation={2} position='fixed'>
      <Toolbar
        disableGutters
        sx={{
          padding: '8px 24px',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderBottom: '1px',
          height: '80px',
        }}
      >
        <Stack
          justifyContent='space-between'
          direction='row'
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1366px',
            textAlign: 'end',
          }}
          spacing={2}
        >
          <Image
            filePath={`/recycler/${recycler}-logo.png`}
            alt={`${recycler.name} logo`}
            style={{ cursor: 'pointer' }}
            data-testid='header-app-logo'
            height={64}
          />

          <Stack direction='row' spacing={1}>
            <Link
              target='_blank'
              href={`${filePath}/TermsAndConditions.pdf`}
              rel='noreferrer'
              data-testid='header-app-termsconditions-link'
            >
              <Typography variant='body2'>
                <Trans id='online.trade.in.terms.and.conditions'>
                  Terms & Condition
                </Trans>
              </Typography>
            </Link>
            <Divider orientation='vertical' flexItem variant='inset' />

            <Link
              target='_blank'
              href={`${filePath}/DataPrivacy.pdf`}
              rel='noreferrer'
              data-testid='header-app-dataprivacy-link'
            >
              <Typography variant='body2'>
                <Trans id='online.trade.in.data.privacy'>Data Privacy</Trans>
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
