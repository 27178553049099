const errorCodes = {
  CANT_BE_NEGATIVE: {
    id: 'error.csv.invalid.value.negative',
    message: `The provided value needs to be positive.`,
  },
  CANT_HAVE_MORE_THAN_2_DECIMAL_PLACES: {
    id: 'error.csv.invalid.value.currency',
    message: `The provided value can't have more than 2 decimal places.`,
  },
  CATEGORY_IS_MANDATORY: {
    id: 'error.mandatory.category',
    message: `Invalid Category: %0.`,
  },
  CLIENT_ID_WITHOUT_WHITE_LABEL_MAPPED: {
    id: 'error.client.whithout.wl',
    message: `Client ID %0 without white label mapped. Please contact support@financetechnology.com.`,
  },
  CLIENT_NOT_ALLOWED_OR_CONFIGURED_TO_ACCESS_FTP_X: {
    id: 'error.client.not.allowed',
    message: `Client not allowed or configures to access FTP. Please contact support@financetechnology.com`,
  },
  CLIENT_NOT_FOUND_OR_CONFIGURED_IN_THIS_REQUEST_X: {
    id: 'error.client.not.found',
    message: `Client not found or configured in this request: %0. Please contact support@financetechnology.com`,
  },
  CONTRACT_ALREADY_ACTIVE: {
    id: 'warning.contract.already.active',
    message: `Created new cart, the cart %0 is already active.`,
  },
  CORE_CODE_API_FUNCTION_ADD_TO_SERVICE: {
    id: 'error.service.registration',
    message: `An error occurred registering service functions.`,
  },
  CORE_CODE_API_SERVICE_ADD_TO_API: {
    id: 'error.api.registration',
    message: `An error occurred registering service into the API.`,
  },
  CORE_CODE_CLIENT_ID_MANDATORY: {
    id: 'error.mandatory.client.id',
    message: `Could not validate access, clientID field is mandatory.`,
  },
  CORE_CODE_CLIENT_ID_UNAUTHORIZED_ACCESS_TO_FUNCTION: {
    id: 'error.unauthorized.access',
    message: `Your client ID does not have access to the function %0 from service %1.`,
  },
  CORE_CODE_CLIENT_SESSION_NOT_FOUND: {
    id: 'error.session.not.found',
    message: `Your session is no longer valid. Please login again.`,
  },
  CORE_CODE_CLIENT_SESSION_UNAUTHORIZED_ACCESS: {
    id: 'error.no.session',
    message: `Your session is no longer valid. Please log in again.`,
  },
  CORE_CODE_FUNCTION_NOT_FOUND: {
    id: 'error.function.not.found',
    message: `We could not find the function %0 from service %1, please make sure it exists.`,
  },
  CORE_CODE_INTERNAL_API_CORE_ERROR: {
    id: 'error.api.unknown',
    message: `Unknown error on internal API core code.`,
  },
  CORE_CODE_SERVICE_CALL_ERROR: {
    id: 'error.service.call',
    message: `An error occurred calling the function %0 from service %1`,
  },
  CORE_CODE_SERVICE_NOT_FOUND: {
    id: 'error.service.not.found',
    message: `We could not find the service %0, please make sure it exists.`,
  },
  CREDIT_ALREADY_APPLIED_TO_ORDER: {
    id: 'warning.credit.already.applied',
    message: `This cart as been submitted for credit approval. It cannot be changed. If you want to cancel this credit application please go to the Pending Contracts page and cancel it there.`,
  },
  CSV_IMPORT_CANT_HAVE_MORE_THAN_2_DECIMAL_PLACES: {
    id: 'error.csv.import.cant.have.more.than.2.decimal.places',
    message: `Value can't have more than 2 decimal places`,
  },
  CSV_IMPORT_CREATE_CART_FAILED: {
    id: 'error.csv.import.create.cart.failed',
    message: `Create cart failed`,
  },
  CSV_IMPORT_CUSTOMER_CREATE_OR_UPDATE_FAILED: {
    id: 'error.csv.import.customer.create.or.update.failed',
    message: `Customer creation or update failed`,
  },
  CSV_IMPORT_DEVICE_NOT_FOUND_FOR_X: {
    id: 'error.csv.import.device.not.found.for',
    message: `Device not found for %0. This row will be ignored.`,
  },
  CSV_IMPORT_DEVICE_NOT_FOUND: {
    id: 'error.csv.import.device.not.found',
    message: `Device not found. This row will be ignored.`,
  },
  CSV_IMPORT_ERROR_CREATING_CART_FINANCING: {
    id: 'error.csv.import.creating.cart.financing',
    message: `Creation of cart financing failed`,
  },
  CSV_IMPORT_ERROR_INSERTING_CONTRACT: {
    id: 'error.csv.import.inserting.contract',
    message: `Insert of contract failed`,
  },
  CSV_IMPORT_FAILED_SAVE_OR_UPDATE: {
    id: 'error.csv.failed.save.or.update',
    message: `Save or update failed`,
  },
  CSV_IMPORT_INVALID_DATE_FORMAT: {
    id: 'error.csv.invalid.date.format',
    message: `Invalid date format`,
  },
  CSV_IMPORT_INVALID_DEVICE_REFERENCE_X: {
    id: 'error.csv.invalid.device.reference',
    message: `Invalid device reference for %0`,
  },
  CSV_IMPORT_INVALID_VALUE: {
    id: 'error.csv.invalid.value',
    message: `Invalid value`,
  },
  CSV_IMPORT_MANDATORY_FIELD: {
    id: 'error.csv.mandatory.field',
    message: `Mandatory field`,
  },
  CSV_IMPORT_MUST_BE_A_NUMBER: {
    id: 'error.csv.field.must.be.a.number',
    message: `Field must be a number`,
  },
  CSV_IMPORT_RESIDUAL_NOT_FOUND_FOR_DEVICE_X: {
    id: 'error.csv.residual.not.found',
    message: `Residual not found for device %0`,
  },
  CSV_IMPORT_STORE_CREATE_OR_UPDATE_FAILED: {
    id: 'error.csv.store.create.or.update.failed',
    message: `Store creation or update failed`,
  },
  CSV_IMPORT_VENDOR_CREATE_OR_UPDATE_FAILED: {
    id: 'error.csv.vendor.create.or.update.failed',
    message: `Vendor creation or update failed`,
  },
  CSV_IMPORT_DATE_BEFORE_OR_EQUAL_X: {
    id: 'error.csv.date.before.or.equal',
    message: `Date should be before or same as %0`,
  },
  CSV_IMPORT_DATE_AFTER_OR_EQUAL_X: {
    id: 'error.csv.date.after.or.equal',
    message: `Date should be after or same as %0`,
  },
  CSV_IMPORT_MUST_BE_BETWEEN_0_AND_100: {
    id: 'error.csv.between.zero.and.hundred',
    message: `Value must be between 0 and 100`,
  },
  CSV_IMPORT_MUST_BE_HIGHER_OR_EQUAL_TO_0: {
    id: 'error.csv.higher.or.equal.zero',
    message: `Value must be higher or equal to 0`,
  },
  CSV_IMPORT_MUST_BE_LOWER_THAN_X: {
    id: 'error.csv.lower.than.x',
    message: `Value must be lower than %0`,
  },
  DB_GENERIC_ERROR: {
    id: 'error.db.generic.error',
    message: `Database error. Please contact support@financetechnology.com.`,
  },
  DEFAULT_ERROR: {
    id: 'error.default',
    message: `There was an error in the system. Please contact support@financetechnology.com.`,
  },
  DEVICE_REFERENCE_ALREADY_PRESENT: {
    id: 'error.device.reference.recycled',
    message: `A device with the provided device reference has already been recycled.`,
  },
  DISCOUNTS_EXCEEDED_ADD_EXTRAS: {
    id: 'error.discount.too.high',
    message: `The maximum discount amount has been reached for order %0. Please review your discounts or add some additional extras in the amount of %1 to proceed with the credit application.`,
  },
  DV_MISSING_AUTH_DATA: {
    id: 'error.missing.auth.data',
    message: `Missing authentication data. Please contact support@financetechnology.com.`,
  },
  DV_INVALID_IMEI: {
    id: 'error.invalid.imei',
    message: `Error performing search. Make sure you are inserting a correct IMEI/Serial Number`,
  },
  ERROR_INSERTING_CONTRACT: {
    id: 'error.csv.importing.contract',
    message: `Error importing contract`,
  },
  ERROR_PARSING_ORDER_JSON: {
    id: 'error.parsing.json',
    message: `Error parsing order %0.`,
  },
  ERROR_SENDIND_EMAIL_MESSAGE: {
    id: 'error.email.delivery',
    message: `Error sending email. Please contact support@financetechnology.com.`,
  },
  EXTERNAL_API_ERROR: {
    id: 'error.external.api',
    message: `Error from external API [%0]. Please contact support@financetechnology.com.`,
  },
  EXTERNAL_API_LOGIN_DATA_ERROR: {
    id: 'error.api.login',
    message: `Error with external API missing authentication data. Please contact support@financetechnology.com.`,
  },
  EX_MAX_EXTRAS_QUANTITY_LIMIT_REACHED: {
    id: 'error.extras.limit.reached',
    message: `Max quantity %0 reached for: %1`,
  },
  FC_CAPTURE_NOT_ALLOWED: {
    id: 'error.bank.capture.not.allowed',
    message: `Capture not allowed.`,
  },
  FC_CANCEL_NOT_ALLOWED: {
    id: 'error.bank.cancel.not.allowed',
    message: `Cancel not allowed (e.g. order has captures or is closed)`,
  },
  FC_CREATE_SESSION_MISSING_DATA: {
    id: 'error.bank.missing.session.data',
    message: `We were unable to create a session with the provided data. Some field constraint was violated.`,
  },
  FC_CREATE_SESSION_NOT_AUTHORIZED: {
    id: 'error.bank.session.not.authorized',
    message: `You were not authorized to execute this operation.`,
  },
  FC_INVALID_WITHDRAW_PERIOD: {
    id: 'error.invalid.withdraw.period',
    message: `Withdraw period has expired for cart %0 and order %1, max period is %2 days passed %3.`,
  },
  FC_EXPORT_BAD_REQUEST: {
    id: 'error.bank.export.bad.request',
    message: `There is an error and we cannot process your request.`,
  },
  FC_EXPORT_NOT_FOUND: {
    id: 'error.bank.export.not.found',
    message: `The file you're trying to export does not exist.`,
  },
  FC_INVALID_SEQUENCE: {
    id: 'warning.invalid.credit.sequence',
    message: `Invalid sequence number %0. Use the sequence number provided in our partner’s app after you finish the credit application process.`,
  },
  FC_ORDER_NOT_FOUND: {
    id: 'error.bank.order.not.found',
    message: `Order not found.`,
  },
  FC_PAYOUTS_BAD_REQUEST: {
    id: 'error.bank.payouts.bad.request',
    message: `There is an error and we cannot process your request.`,
  },
  FC_PLACE_ORDER_DATA_NOT_MATCH_SESSION: {
    id: 'error.bank.order.session.match',
    message: `The data in the request does not match the session for the authorization.`,
  },
  FC_PLACE_ORDER_MISSING_AUTHORIZATION: {
    id: 'error.bank.missing.order.authorization',
    message: `The authorization does not exist.`,
  },
  FC_PLACE_ORDER_MISSING_DATA: {
    id: 'error.bank.missing.order.data',
    message: `We were unable to create an order with the provided data. Some field constraint was violated.`,
  },
  FC_PLACE_ORDER_NOT_AUTHORIZED: {
    id: 'error.bank.order.not.authorized',
    message: `You were not authorized to execute this operation.`,
  },
  FC_REFUND_NOT_ALLOWED: {
    id: 'error.bank.refund.not.allowed',
    message: `Refund not allowed.`,
  },
  FILE_FORMAT_NOT_SUPPORTED: {
    id: 'error.invalid.file.format',
    message: `File format is not supported at the moment.`,
  },
  FINANCED_AMOUNT_BELOW_OR_EQUAL_TO_ZERO: {
    id: 'error.financed_amount.bellow.zero',
    message: `Invalid financed amount %0, for order %1. Please check your discounts, or add products to be financed.`,
  },
  IMEI_USED_IN_CONTRACT_X: {
    id: 'warning.used.imei',
    message: `IMEI/Serial Number already in use in Contract: %0`,
  },
  INTERNAL_ERROR: {
    id: 'error.internal',
    message: `Internal error. Please contact support@financetechnology.com.`,
  },
  INVALID_NUMBER: { id: 'error.invalid.number', message: `Invalid number` },
  INVALID_SESSION: {
    id: 'error.session.expired',
    message: `Your session has expired`,
  },
  INVALID_VALUE: {
    FIRST_LETTER_MUST_BE_CAPITALIZED: {
      id: 'error.csv.invalid.value.capitalized',
      message: `The provided value needs to be capitalized.`,
    },
    MUST_BE_A_NUMBER: {
      id: 'error.csv.invalid.value.number',
      message: `The provided value needs to be a number.`,
    },
    MUST_BE_0_OR_1: {
      id: 'error.csv.invalid.value.boolean',
      message: `The provided value needs to be 0 or 1.`,
    },
    UNKNOWN_ERROR: {
      id: 'error.csv.invalid.unknown.error',
      message: `An error occurred due to an invalid value on a column`,
    },
  },
  INVALID_X_X: { id: 'error.invalid.x', message: `Invalid %0: %1.` },
  IS_MANDATORY: {
    id: 'error.csv.mandatory.column',
    message: `This column is mandatory.`,
  },
  KLARNA_API_ERROR: {
    id: 'klarna.phone.error',
    message: `Klarna API Error - %0: %1 for %2`,
  },
  LEGACY_STRING_ERROR: '%0',
  ONLINE_MANUAL_PRICING_DISABLED: {
    id: 'error.api.online.manual.pricing.disabled',
    message: `Manual pricing disabled!.`,
  },
  MAPPING_NOT_FOUND_FOR_X_X: {
    id: 'error.mapping.not.found',
    message: `Mapping not found for %0: %1.`,
  },
  MAX_LENGTH: {
    id: 'error.csv.max.length',
    message: `This column exceeds its maximum length of %0 characters.`,
  },
  MISSING_USER_STORES: {
    id: 'error.user.missing.user.stores',
    message: `User doesn't have any stores associated to them`,
  },
  MISSING_DEFAULT_STORE: {
    id: 'error.user.missing.default.user.store',
    message: `User doesn't have a default store associated to them`,
  },
  MULTIPLE_DEFAULT_STORES: {
    id: 'error.user.multiple.default.user.stores',
    message: `User has multiple default stores associated to them`,
  },
  NO_CONTRACTS_IN_PERIOD: {
    id: 'warning.no.contracts',
    message: `There are no contracts in that date period`,
  },
  NO_RECORDS_FOUND: { id: 'error.no.records', message: `No records found` },
  NO_STORES: {
    id: 'whitelabel.no.stores',
    message: `Please contact your manager in order to add a store to the portal before selling.`,
  },
  ONLY_X_IMAGES_ALLOWED: {
    id: 'error.only.images.allowed',
    message: `Only %0 images allowed.`,
  },
  POS_CART_NO_ORDER_FOR_CART_ID: {
    id: 'error.cart.not.found.cart',
    message: `Order not found for cart ID %0. Please contact support@financetechnology.com.`,
  },
  POS_CART_NOT_FOUND_FOR_UUID: {
    id: 'error.cart.not.found',
    message: `Cart not found for ID %0. Please contact support@financetechnology.com.`,
  },
  POS_INVALID_NEW_REFERENCE: {
    id: 'error.invalid.new.reference',
    message: `Invalid %0 %1. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_CART_UUID: {
    id: 'error.missing.cart.uuid',
    message: `Invalid or missing cart UUID. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_DEVICE_CODE: {
    id: 'error.missing.device.code',
    message: `Invalid or missing device code. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_FINANCING_TYPE: {
    id: 'error.missing.financing.type',
    message: `Invalid or missing financing type. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_INSURANCE_ID: {
    id: 'error.missing.insurance.id',
    message: `Invalid or missing insurance ID. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_NEW_REFERENCE: {
    id: 'error.missing.new.reference',
    message: `Invalid or missing new reference. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_PAYMENTPLAN_ID: {
    id: 'error.missing.payment.plan',
    message: `Invalid or missing payment plan. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_SUBSCRIPTION_ID: {
    id: 'error.missing.subscription.id',
    message: `Invalid or missing subscription. Please contact support@financetechnology.com.`,
  },
  POS_MISSING_TYPE: {
    id: 'error.missing.type',
    message: `Invalid or missing new reference type. Please contact support@financetechnology.com.`,
  },
  POS_ORDER_NOT_FOUND_FOR_UUID: {
    id: 'error.order.not.found',
    message: `Order not found for UUID %0. Please contact support@financetechnology.com.`,
  },
  POS_PRODUCTS_NOT_FOUND_FOR_ORDER: {
    id: 'error.product.not.found',
    message: `Products not found for order with UUID %0. Please contact support@financetechnology.com.`,
  },
  POS_ORDER_ALREADY_ACTIVE: {
    id: 'error.contract.already.active',
    message: `This contract is already active.`,
  },
  PR_HIGHER_DISCOUNT_VALUE: {
    id: 'error.discount.value.too.high',
    message: `The discount value is higher than the total value`,
  },
  DISCOUNT_TOO_HIGH: {
    id: 'error.high.discount.value',
    message: `The discount value is too high`,
  },
  REQUEST_FAIL: {
    id: 'error.request.fail',
    message: `The request failed, please try again later.`,
  },
  UNKNOWN_ERROR: {
    id: 'error.unknown',
    message: `Something went wrong. Please contact support@financetechnology.com.`,
  },
  US_ALREADY_EXISTS: {
    id: 'error.email.already.exists',
    message: `The email is already registered in the system.`,
  },
  US_BLOCKED_ACCOUNT: {
    id: 'error.account.blocked',
    message: `This user is currently blocked. Please contact a system administrator if this is a mistake.`,
  },
  US_FAILED_LOGIN: {
    id: 'error.failed.login',
    message: `Failed login. Incorrect email or password.`,
  },
  US_FAILED_LOGIN_ATTEMPTS: {
    id: 'error.failed.login.attempts',
    message: `Failed login. %0 attempts remaining.`,
  },
  US_INACTIVE_ACCOUNT: {
    id: 'error.account.inactive',
    message: `The selected account is inactive.`,
  },
  US_INVALID_FORGOT_PWD: {
    id: 'error.reset.password',
    message: `Error resetting password.`,
  },
  US_INVALID_PASSWORD: {
    id: 'error.invalid.password',
    message: `Invalid or nonexistent password.`,
  },
  US_INVALID_RESET_PWD_TOKEN: {
    id: 'error.invalid.token',
    message: `Invalid reset password token.`,
  },
  US_INVALID_ROLE: {
    id: 'error.invalid.role',
    message: `Invalid or nonexistent role.`,
  },
  US_PASSWORD_STRENGTH: {
    id: 'error.password.too.weak',
    message: `Password is too weak. Needs to be more than 6 characters long and have at least a number, a special character and an uppercase character.`,
  },
  US_REPEATED_PASSWORD: {
    id: 'error.different.password',
    message: `New password must be different.`,
  },
  USER_ALREADY_EXISTS_FOR_EMAIL_X: {
    id: 'error.user.already.exists.for.email.x',
    message: `An user already exists for email %0.`,
  },
  USER_UPDATE_PASSWORD_REQUIRED: {
    id: 'error.required.password.update',
    message: `Please change your password. You are using a generated one from a recent password recovery.`,
  },
  X_NOT_FOUND_FOR_X_X: {
    id: 'error.x.not.found',
    message: `%0 not found for %1: %2.`,
  },
  ST_STORE_NOT_FOUND_FOR_ID: {
    id: 'error.store.not.found',
    message: `Store not found for selected store ID %0.`,
  },
  ST_STORE_DOES_NOT_EXIST: {
    id: 'error.store.not.exist',
    message: `The selected store does not exist.`,
  },
  STORE_ID_X_NOT_VALID_FOR_THIS_WHITELABEL: {
    id: 'error.store.x.not.available.for.whitelabel',
    message: `Selected store %0 is not available for this whitelabel domain.`,
  },
  FRAMEWORK_EXCEPTION: {
    id: 'error.framework.exception',
    message: `Framework Exception`,
  },
  FRAMEWORK_EXCEPTION_INVALID_SERVICE_X: {
    id: 'error.framework.exception.invalid.service',
    message: `Framework Exception invalid service %0`,
  },
  FRAMEWORK_EXCEPTION_CALLING_X_X: {
    id: 'error.framework.exception.calling.values',
    message: `Framework Exception calling %0, %1`,
  },
  FRAMEWORK_EXCEPTION_INVALID_CALL_X_X: {
    id: 'error.framework.exception.invalid.call',
    message: `Framework Exception invalid call`,
  },
  FRAMEWORK_CLASS_X_HAS_NO_DEFAULT_CONSTRUCTOR: {
    id: 'error.framework.exception.no.constructor',
    message: `Framework Exception no constructor`,
  },
  FRAMEWORK_EXCEPTION_NO_SUCH_METHOD_X_X: {
    id: 'error.framework.exception.no.method',
    message: `Framework Exception no method`,
  },
  FRAMEWORK_EXCEPTION_CREATING_API_RESPONSE_X: {
    id: 'error.framework.exception.creating.response',
    message: `Framework Exception creating response`,
  },
  WL_INVALID_DOMAIN_NULL: {
    id: 'error.invalid.wl.domain',
    message: `Invalid whitelabel domain`,
  },
  ONLINE_API_ERROR: {
    id: 'error.online.api',
    message: `An Error occurred connecting to the Finance Technology Online API`,
  },
  FUNDER_ERROR: {
    id: 'error.funder',
    message: `An Error occurred connecting to the Finance Partner`,
  },
  RECYCLER_ERROR: {
    id: 'error.recycler',
    message: `An error occurred with recycler data`,
  },
  RECYCLE_IMEI_USED_IN_CONTRACT_X: {
    id: 'warning.tradein.use.swap.instead',
    message: `This IMEI/SN is being used in contract %0. If you want to upgrade it, please use the upgrade option in the contract details.`,
  },
  EB_CANCEL_ACTIVE_CONTRACT: {
    id: 'warning.eb.cancel.active.contract',
    message: `You are trying to cancel a EB contract when the contract is already active. This has to be done by email, directly to Express Bank.`,
  },
  EB_CREDIT_ALREADY_APPLIED_TO_ORDER: {
    id: 'warning.eb.credit.already.started',
    message: `The credit has already been approved for this order.`,
  },
  ONLINE_SERVICE_TRADECART_MISSING_X_FOR_X_X: {
    id: 'error.trade.cart.missing',
    message: `Trade cart missing %0 for %1 %2.`,
  },
  WRONG_SELECTED_STORE_X_FOR_PICKUP_STORE_X: {
    id: 'error.wrong.pickup.store',
    message: `The current selected store %0 doesn't correspond to the appropriate pick up store. Please select store %1 to proceed.`,
  },
  RECYCLE_TRADECART_MISSING_CUSTOMER_APPROVAL: {
    id: 'error.recycle.tradecart.missing.customer.approval',
    message: `The current trade cart is missing the customer approval.`,
  },
  RECYCLE_TRADECART_MISSING_PHOTO: {
    id: 'error.recycle.tradecart.missing.photo.id',
    message: `The current trade cart is missing the customer id photo.`,
  },
  TEXT_NOT_DETECTED_ON_DOCUMENT: {
    id: 'warning.text.not.detected.photo.id',
    message: `The expected text %0 was not detected on the customer id document. Please proceed only if you're sure that the document contains that information.`,
  },
  ST_INVALID_SELECTED_STORE: {
    id: 'error.recycle.store.transfer',
    message: `This quote can't be transfered to another store.`,
  },
  ONLINE_TRADEIN_DISABLED: {
    id: 'error.recycle.feature.disabled',
    message: `You are trying to access a feature that is disabled on your system.`,
  },
  INVALID_IMEI: {
    id: 'error.recycle.invalid.imei',
    message: `The inserted IMEI is not a valid one.`,
  },
  CMR_ORIGINAL_ORDER_IS_NOT_PENDING: {
    id: 'error.recycle.quote.not.pending',
    message: `This quote has already been fulfilled.`,
  },
  CMR_ORIGINAL_ORDER_HAD_EXPIRED: {
    id: 'error.recycle.quote.expired',
    message: `This quote has already expired.`,
  },
  RECYCLE_QUOTE_EXPIRED: {
    id: 'error.recycle.quote.expired',
    message: `This quote has already expired.`,
  },
  UNAUTHORIZED_ACCESS: {
    id: 'error.api.unauthorized.access',
    message: `Unauthorized access!`,
  },
  POS_INVALID_ORDER_STATUS_X_TO_ADD_DEVICE_REFERENCE: {
    id: 'error.api.invalid.order.status.device.reference',
    message: `The current order status does not allow for a device reference to be added. Please wait. If the error persists please contact support@financetechnology.com.`,
  },
  NO_WEIGHT_FOR_KLARNA_V2_PRICE_CALC: {
    id: 'error.api.missing.weight.price.calculation',
    message: `Missing weigth in price calculation.`,
  },
  KLARNA_V2_PRICE_CALCULATION_ERROR: {
    id: 'error.api.calculating.prices',
    message: `Error calculating price.`,
  },
  ERROR_BUILDING_KLARNA_V2_DAILY_AMORTIZATION: {
    id: 'error.api.building.amortizations',
    message: `Error building daily amortizations.`,
  },
  NO_AMORTIZATION_PERIOD_FOR_MONTH_X: {
    id: 'error.api.missing.amortization.period',
    message: `Couldn't find an amortization period for month %0.`,
  },
  NO_AMORTIZATION_SCHEDULE: {
    id: 'error.api.missing.amortization.schedule',
    message: `Couldn't find an amortization schedule.`,
  },
  ERROR_PRICING_ORDER_X: {
    id: 'error.api.pricing.order',
    message: `Error occurred while pricing order %0.`,
  },
  CREDIT_CHECK_ERROR: {
    id: 'error.api.credit.check',
    message: `Credit Check Error.`,
  },
  CREDIT_CHECK_FUNDER_ERROR: {
    id: 'error.api.funder.credit.check',
    message: `Funder Credit Check Error.`,
  },
  TOO_MANY_REQUESTS: {
    id: 'error.api.too.many.requests',
    message: `Daily search limit exceeded!`,
  },
  ONLINE_SERVICE_CONTRACT_NOT_FOUND: {
    id: 'error.api.online.contract.not.found',
    message: `No contract was found matching the data provided.`,
  },
  PRODUCT_X_OUT_OF_STOCK: {
    id: 'error.api.online.X.no.stock',
    message: `We are sorry, but the %0 is out of stock at the moment. Please come back later.`,
  },
  MAX_FINANCE_AMOUNT_EXCEEDED_VALUE_X: {
    id: 'error.api.online.max.finance.amount',
    message: `Financed amount is above maximum value of %0.`,
  },
  MIN_FINANCE_AMOUNT_EXCEEDED_VALUE_X: {
    id: 'error.api.online.min.finance.amount',
    message: `Financed amount is below minimum value of %0.`,
  },
  CMR_DEVICE_NOT_FOUND_FOR_REFERENCE: {
    id: 'error.api.online.cmr.device.not.found',
    message: `The device reference you entered is not valid. Please enter a different valid serial number or IMEI or contact support@td-renew.com.`,
  },
};

export default errorCodes;
