import { apiServiceClient } from '../frameworkClient';

const onlineServiceClient = apiServiceClient(
  process.env.REACT_APP_ONLINE_URL || 'framework/OnlineService',
);

const endpoints = {
  confirmTradeIn: 'confirmTradeIn',
  createTradecart: 'createTradecart',
  evaluateDevice: 'evaluateDevice',
  getCartByGuid: 'getCartByGuid',
  getCartStatus: 'getCartStatus',
  getCollectionTimes: 'getCollectionTimes',
  getContractSummary: 'getContractSummary',
  getContractUrl: 'getContractUrl',
  getCriteria: 'getCriteria',
  getCustomerOptionsInfo: 'getCustomerOptionsInfo',
  getDeviceOptions: 'getDeviceOptions',
  getExtrasCategories: 'getExtrasCategories',
  getFinanceOptions: 'getFinanceOptions',
  getFunderForms: 'getFunderForms',
  getMarketValue: 'getMarketValue',
  getPricedExtras: 'getPricedExtras',
  getProductCategories: 'getProductCategories',
  getRecyclerForms: 'getRecyclerForms',
  getStartingInfo: 'getStartingInfo',
  getTradecartByUuid: 'getTradecartByUuid',
  getTradeInDeviceList: 'getTradeInDeviceList',
  getTradeInPrices: 'getTradeInPrices',
  getTranslations: 'getTranslations',
  placeOrder: 'placeOrder',
  priceCart: 'priceCart',
  startCheckout: 'startCheckout',
  startCreditProcess: 'startCreditProcess',
  submitCustomerOption: 'submitCustomerOption',
  thirdPartyClientLogs: 'thirdPartyClientLogs',
  validateDeviceReference: 'validateDeviceReference',
  withdraw: 'withdraw',
};

export const createTradecart = async (
  {
    additionAnswers = {},
    clientID,
    collectionDate,
    collectionTimeSlotCode,
    criteriaAnswers = {},
    customer,
    deductionAnswers = {},
    deliveryType,
    deviceCode,
    deviceID,
    deviceReference,
    grade,
    hasPackaging,
    networkStatus,
    orderGUID,
    paymentMethod,
    priceID,
    promotionID,
    recycleOutcome,
    searchText,
    trackingID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.createTradecart,
    {
      additionAnswers,
      clientID,
      collectionDate,
      collectionTimeSlotCode,
      criteriaAnswers,
      customer,
      deductionAnswers,
      deliveryType,
      deviceCode,
      deviceID,
      deviceReference,
      grade,
      hasPackaging,
      networkStatus,
      orderGUID,
      paymentMethod,
      priceID,
      promotionID,
      recycleOutcome,
      searchText,
      trackingID,
    },
    options,
  );

export const confirmTradeIn = async (
  { priceID, customer, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.confirmTradeIn,
    { priceID, customer, clientID },
    options,
  );

export const evaluateDevice = async (
  {
    networkStatus,
    deductionAnswers = {},
    additionAnswers = {},
    criteriaAnswers = {},
    deviceID,
    categoryCode,
    manufacturerCode,
    deviceCode,
    clientID,
    orderGUID,
    priceID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.evaluateDevice,
    {
      networkStatus,
      additionAnswers,
      criteriaAnswers,
      deductionAnswers,
      deviceID,
      deviceCode,
      categoryCode,
      manufacturerCode,
      clientID,
      orderGUID,
      priceID,
    },
    options,
  );

export const getCartByGuid = async ({ cartGUID, clientID }, options) =>
  onlineServiceClient(
    endpoints.getCartByGuid,
    {
      cartGUID,
      clientID,
    },
    options,
  );

export const getCartStatus = async ({ cartUUID, clientID }, options) =>
  onlineServiceClient(
    endpoints.getCartStatus,
    {
      cartUUID,
      clientID,
    },
    options,
  );

export const getCollectionTimes = (
  { clientID, countryCode, postalCode },
  options,
) =>
  onlineServiceClient(
    endpoints.getCollectionTimes,
    {
      clientID,
      countryCode,
      postalCode,
    },
    options,
  );

export const getContractSummary = async ({ clientID, orderGUID }) =>
  onlineServiceClient(endpoints.getContractSummary, {
    orderGUID,
    clientID,
  });

export const getContractUrl = async (
  { email, deviceReference, contractReference, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.getContractUrl,
    { email, deviceReference, contractReference, clientID },
    options,
  );

export const getCriteria = async (device, options) =>
  onlineServiceClient(endpoints.getCriteria, device, options);

export const getDeviceOptions = async (
  {
    deviceCode = '',
    parentDeviceCode,
    extras = [],
    financingType,
    insurances = [],
    specialOffers = [],
    paymentPlanID,
    clientID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.getDeviceOptions,
    {
      deviceCode,
      parentDeviceCode,
      extras,
      financingType,
      insurances,
      specialOffers,
      paymentPlanID,
      clientID,
    },
    options,
  );

export const getExtrasCategories = async ({ clientID }) =>
  onlineServiceClient(endpoints.getExtrasCategories, {
    clientID,
  });

export const getFinanceOptions = async (
  {
    paymentPlanType,
    paymentPlanMonths,
    devices = [],
    clientID = '',
    sessionID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.getFinanceOptions,
    {
      paymentPlanType,
      paymentPlanMonths,
      devices,
      clientID,
      sessionID,
    },
    options,
  );

export const getMarketValue = async (
  {
    marketValueRequestDate = new Date().toISOString().split('T')[0],
    taxType = 'VAT',
    items,
    clientID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.getMarketValue,
    { marketValueRequestDate, taxType, items, clientID },
    options,
  );

export const getPricedExtras = async (
  {
    showMostPopular = false,
    category = undefined,
    searchString = '',
    deviceCode = '',
    parentDeviceCode,
    extras = [],
    insurances = [],
    isDeviceRelated = true,
    specialOffers = [],
    page = 0,
    quantity = 4,
    financingType,
    paymentPlanID,
    clientID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.getPricedExtras,
    {
      category,
      searchString,
      deviceCode,
      parentDeviceCode,
      extras,
      insurances,
      isDeviceRelated,
      specialOffers,
      quantity,
      page,
      financingType,
      paymentPlanID,
      clientID,
      showMostPopular,
    },
    options,
  );

export const getProductCategories = async ({ clientID }) =>
  onlineServiceClient(endpoints.getProductCategories, { clientID });

export const getStartingInfo = async (emptyPayload, options) =>
  onlineServiceClient(endpoints.getStartingInfo, emptyPayload, options);

export const getTradeInDeviceList = async (
  { manufacturer, category, family, partNumber, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.getTradeInDeviceList,
    { manufacturer, category, family, partNumber, clientID },
    options,
  );

export const getTradeInPrices = async (
  { priceOption = 'BEST_PRICE', taxType = 'VAT', items, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.getTradeInPrices,
    { priceOption, taxType, items, clientID },
    options,
  );

export const getTranslations = async (emptyPayload, options) =>
  onlineServiceClient(endpoints.getTranslations, emptyPayload, options);

export const placeOrder = async ({ cartUUID, clientID }, options) =>
  onlineServiceClient(endpoints.placeOrder, { cartUUID, clientID }, options);

export const priceCart = async (
  {
    customer,
    deliveryType = 'DELIVERY',
    deviceCode = '',
    parentDeviceCode,
    extras = [],
    financingType,
    insurances = [],
    specialOffers = [],
    paymentPlanID,
    pickupStore = null,
    clientID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.priceCart,
    {
      customer,
      deliveryType,
      deviceCode,
      parentDeviceCode,
      extras,
      financingType,
      insurances,
      specialOffers,
      paymentPlanID,
      pickupStore,
      clientID,
    },
    options,
  );

export const startCheckout = async (
  {
    customer,
    deliveryType = 'DELIVERY',
    deviceCode = '',
    parentDeviceCode,
    extras = [],
    financingType,
    insurances = [],
    specialOffers = [],
    paymentPlanID,
    pickupStore,
    clientID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.startCheckout,
    {
      customer,
      deliveryType,
      parentDeviceCode,
      deviceCode,
      extras,
      financingType,
      insurances,
      specialOffers,
      paymentPlanID,
      pickupStore,
      clientID,
    },
    options,
  );

export const startCreditProcess = async (
  {
    customer,
    deliveryType = 'DELIVERY',
    deviceCode = '',
    parentDeviceCode,
    extras = [],
    financingType,
    insurances = [],
    specialOffers = [],
    paymentPlanID,
    pickupStore = null,
    clientID,
    cartGUID,
    hasMarketing,
    orderGUID,
    tradecartSID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.startCreditProcess,
    {
      customer,
      deliveryType,
      deviceCode,
      parentDeviceCode,
      extras,
      financingType,
      insurances,
      specialOffers,
      paymentPlanID,
      pickupStore,
      clientID,
      cartGUID,
      hasMarketing,
      orderGUID,
      tradecartSID,
    },
    options,
  );

export const getTradecartByUuid = async (
  { tradecartUUID, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.getTradecartByUuid,
    {
      tradecartUUID,
      clientID,
    },
    options,
  );

export const getCustomerOptionsInfo = async (
  { clientID, campaignID, templateName, tradecartUUID, orderGUID },
  options,
) =>
  onlineServiceClient(
    endpoints.getCustomerOptionsInfo,
    { clientID, campaignID, templateName, tradecartUUID, orderGUID },
    options,
  );

export const submitCustomerOption = async (
  { campaignID, tradecartUUID, orderGUID, optionID, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.submitCustomerOption,
    { campaignID, tradecartUUID, orderGUID, optionID, clientID },
    options,
  );

export const thirdPartyClientLogs = async (
  { requestName, request, response, cartUUID, sentAt, receivedAt, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.thirdPartyClientLogs,
    {
      cartUUID,
      request: JSON.stringify({
        data: request,
        sentAt: sentAt ? new Date(sentAt).toISOString() : null,
        clientVersion: process.env.REACT_APP_SENTRY_RELEASE,
      }),
      response: JSON.stringify({
        data: response,
        receivedAt: receivedAt ? new Date(receivedAt).toISOString() : null,
        clientVersion: process.env.REACT_APP_SENTRY_RELEASE,
      }),
      requestName,
      clientID,
    },
    options,
  );

export const validateDeviceReference = async (
  {
    orderGUID,
    fetchModelData = true,
    isIMEI = false,
    deviceReference,
    priceID,
    clientID,
  },
  options,
) =>
  onlineServiceClient(
    endpoints.validateDeviceReference,
    {
      clientID,
      orderGUID,
      fetchModelData,
      isIMEI,
      deviceReference,
      priceID,
    },
    options,
  );

export const withdraw = async ({ cartUUID, clientID }, options) =>
  onlineServiceClient(endpoints.withdraw, { cartUUID, clientID }, options);

export const getFunderForms = async (
  { financingType, paymentPlanID, clientID },
  options,
) =>
  onlineServiceClient(
    endpoints.getFunderForms,
    {
      financingType,
      paymentPlanID,
      clientID,
    },
    options,
  );

export const getRecyclerForms = async ({ recyclePartner, clientID }, options) =>
  onlineServiceClient(
    endpoints.getRecyclerForms,
    {
      recyclePartner,
      clientID,
    },
    options,
  );

const onlineService = {
  confirmTradeIn,
  createTradecart,
  endpoints,
  evaluateDevice,
  getCartByGuid,
  getCartStatus,
  getCollectionTimes,
  getContractSummary,
  getContractUrl,
  getCriteria,
  getCustomerOptionsInfo,
  getDeviceOptions,
  getExtrasCategories,
  getFinanceOptions,
  getFunderForms,
  getMarketValue,
  getPricedExtras,
  getProductCategories,
  getRecyclerForms,
  getStartingInfo,
  getTradecartByUuid,
  getTradeInDeviceList,
  getTradeInPrices,
  getTranslations,
  placeOrder,
  priceCart,
  startCheckout,
  startCreditProcess,
  submitCustomerOption,
  thirdPartyClientLogs,
  validateDeviceReference,
  withdraw,
};

export default onlineService;
