import { da, de, en, es, fi, fr, nb, nl, pt, sv } from 'make-plural/plurals';

import React from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

export const I18nLoader = ({ children }) => {
  const {
    messages,
  } = require(`@ft-frontend/core/src/translations/locales/en/messages`);

  i18n.load('en', messages);

  i18n.loadLocaleData({
    da: { plurals: da },
    de: { plurals: de },
    en: { plurals: en },
    es: { plurals: es },
    fi: { plurals: fi },
    fr: { plurals: fr },
    'fr-BE': { plurals: fr },
    nb: { plurals: nb },
    nl: { plurals: nl },
    'nl-BE': { plurals: nl },
    pt: { plurals: pt },
    sv: { plurals: sv },
  });

  i18n.activate('en');

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      {children}
    </I18nProvider>
  );
};

export default I18nLoader;
