import { i18n } from '@lingui/core';

export const defaultLocale = 'en';
export async function dynamicActivate(locale) {
  try {
    const { messages } = await import(
      `@ft-frontend/core/src/translations/locales/${locale}/messages`
    );

    i18n.load(locale, messages);
    i18n.activate(locale);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Invalid locale: ${locale} >`, err);
  }
}
