import { defineMessage } from '@lingui/macro';

export const evaluationGrade = {
  A: {
    message: defineMessage({
      id: 'evaluation.grade.a.functional.good',
      message: `is fully functional and in good condition`,
    }),
    status: 'success',
  },
  B: {
    message: defineMessage({
      id: 'evaluation.grade.b.functional',
      message: `is fully functional`,
    }),
    status: 'success',
  },
  BC: {
    message: defineMessage({
      id: 'evaluation.grade.bc.damage',
      message: `has minor Damage`,
    }),
    status: 'warning',
  },
  C: {
    message: defineMessage({
      id: 'evaluation.grade.c.poor',
      message: `is in poor condition`,
    }),
    status: 'warning',
  },
  W: {
    message: defineMessage({
      id: 'evaluation.grade.w.functional.good',
      message: `is fully functional and in good condition`,
    }),
    status: 'success',
  },
  D: {
    message: defineMessage({
      id: 'evaluation.grade.d.damaged',
      message: `is damaged or faulty`,
    }),
    status: 'warning',
  },
  NW: {
    message: defineMessage({
      id: 'evaluation.grade.nw.non.working',
      message: `is non-working condition`,
    }),
    status: 'warning',
  },
  NWZ: {
    message: defineMessage({
      id: 'evaluation.grade.nwz.non.working.zero',
      message: `is in non-working condition and has zero value`,
    }),
    status: 'error',
  },
  IN_AGREED_CONDITION: {
    message: defineMessage({
      id: 'evaluation.grade.in.agreed.condition',
      message: `is in agreed condition`,
    }),
    status: 'success',
  },
  NOT_IN_AGREED_CONDITION: {
    message: defineMessage({
      id: 'evaluation.grade.not.in.agreed.condition',
      message: `is not in agreed condition`,
    }),
    status: 'warning',
  },
  ZERO_VALUE: {
    message: defineMessage({
      id: 'evaluation.grade.zero.value',
      message: `is not in condition to be recyclable`,
    }),
    status: 'error',
  },
};
