import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useSearchParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import * as Sentry from '@sentry/react';

import { validators } from '@ft-frontend/core';
import { ScrollToTop, TrackingPixel, useAutoScroll } from '@ft-frontend/core';

import ErrorMessage from 'components/ErrorMessage';
import Footer from 'components/Online/Evaluation/Footer';
import Header from 'components/Online/Evaluation/Header';

import OnlineStartingInfoProvider from 'contexts/starting_info/OnlineStartingInfoProvider';

import useOnMount from 'hooks/useOnMount';

import { setTrackingID } from 'reducers/recycle/recycleSlice';

const OnlineTradein = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const trackingID = searchParams.get('trackingID');

  useOnMount(() => {
    if (trackingID) {
      dispatch(setTrackingID(trackingID));
    }
  });

  const wlId = useSelector((state) => state.ui.wlId);
  const storeId = useSelector((state) => state.ui.storeId);

  const recyclePartner = useSelector(({ flow }) => flow.recyclePartner);
  const { pageName } = useSelector((state) => state.ui);

  useAutoScroll(60);

  return (
    <OnlineStartingInfoProvider>
      <ScrollToTop>
        <Stack
          alignItems='center'
          sx={(theme) => ({
            padding: theme.spacing(2),
            marginTop: validators.isIframe() ? 0 : theme.spacing(11),
          })}
        >
          {!validators.isIframe() && <Header recycler={recyclePartner?.code} />}
          <Grid
            container
            justifyContent='center'
            sx={{
              width: '100%',
              maxWidth: '1366px',
              margin: 'auto',
            }}
          >
            <Sentry.ErrorBoundary
              fallback={({ resetError }) => (
                <ErrorMessage resetError={resetError} />
              )}
            >
              <Outlet />
            </Sentry.ErrorBoundary>
          </Grid>
        </Stack>
        {!validators.isIframe() && <Footer {...recyclePartner} />}
      </ScrollToTop>
      <TrackingPixel
        type='pagetrack'
        wlID={wlId}
        storeID={storeId}
        pageName={pageName}
        param1={trackingID}
      />
    </OnlineStartingInfoProvider>
  );
};

export default OnlineTradein;
