import { combineReducers } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import reportWebVitals from 'reportWebVitals';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import theme from 'theme';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { configureStore } from '@reduxjs/toolkit';

import App from 'components/App';
import I18nLoader from 'components/I18nLoader';

import cartReducer from 'reducers/cart/cartSlice';
import cookiesReducer from 'reducers/cookies/cookiesSlice';
import creditReducer from 'reducers/credit/creditSlice';
import deliveryReducer from 'reducers/delivery/deliverySlice';
import flowReducer from 'reducers/flow/flowSlice';
import orderReducer from 'reducers/order/orderSlice';
import recycleReducer from 'reducers/recycle/recycleSlice';
import reduxReducer from 'reducers/redux/reduxSlice';
import uiReducer from 'reducers/ui/uiSlice';

const cartPersistConfig = {
  key: 'cart',
  storage: sessionStorage,
};

const cookiesPersistConfig = {
  key: 'cookies',
  storage: storage,
};

const creditPersistConfig = {
  key: 'credit',
  storage: sessionStorage,
};

const deliveryPersistConfig = {
  key: 'delivery',
  storage: sessionStorage,
};

const flowPersistConfig = {
  key: 'flow',
  storage: sessionStorage,
};

const orderPersistConfig = {
  key: 'order',
  storage: sessionStorage,
};

const uiPersistConfig = {
  key: 'ui',
  storage: sessionStorage,
};

const reduxPersistConfig = {
  key: 'redux',
  keyPrefix: '',
  serialize: (state) => {
    if (state.user) {
      return JSON.stringify(state);
    } else {
      return state;
    }
  },
  deserialize: (state) => {
    if (typeof state === 'object' && !state.user) {
      return state;
    }

    try {
      const deserialized = JSON.parse(state);

      if (deserialized.user) {
        return deserialized;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error, state);
    }

    return state;
  },
  storage: storage,
};

const recyclePersistConfig = {
  key: 'recycle',
  storage: sessionStorage,
};

const persistedReducer = combineReducers({
  cart: persistReducer(cartPersistConfig, cartReducer),
  cookies: persistReducer(cookiesPersistConfig, cookiesReducer),
  credit: persistReducer(creditPersistConfig, creditReducer),
  delivery: persistReducer(deliveryPersistConfig, deliveryReducer),
  flow: persistReducer(flowPersistConfig, flowReducer),
  order: persistReducer(orderPersistConfig, orderReducer),
  redux: persistReducer(reduxPersistConfig, reduxReducer),
  ui: persistReducer(uiPersistConfig, uiReducer),
  recycle: persistReducer(recyclePersistConfig, recycleReducer),
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let persistor = persistStore(store);

function Render() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <I18nLoader>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <App persistor={persistor} />
              </LocalizationProvider>
            </PersistGate>
          </Provider>
        </I18nLoader>
      </ThemeProvider>
    </React.StrictMode>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<Render />);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
});

// eslint-disable-next-line no-console
reportWebVitals(console.log);
