export const currencyFormatter = (value, currency, language, options = {}) =>
  value !== null &&
  typeof value !== 'undefined' &&
  currency !== 'null' &&
  typeof currency !== 'undefined'
    ? new Intl.NumberFormat(language, {
        style: 'currency',
        currency,
        ...options,
      }).format(value)
    : '';

export const stringFormatter = (str = '', values, symbol = '%') => {
  if (values) {
    values.forEach((value, i) => {
      const regex = new RegExp(`${symbol}${i}`, 'g');
      str = str.replace(regex, value);
    });
  }

  const regex = new RegExp(`${symbol}\\d`, 'g');
  str = str.replace(regex, 'null');

  return str;
};

export const capitalize = (string) => {
  if (typeof string === 'string')
    return string.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  else return string;
};

export const dateFormatter = (value, language) => {
  if (typeof value !== 'undefined' && value !== '' && value !== null) {
    const date =
      isNaN(value) && /:+/.test(value)
        ? new Date(`${value}${'Z'}`)
        : new Date(value);

    const formattingOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return new Intl.DateTimeFormat(language, formattingOptions).format(date);
  }

  return '-';
};

export const formatDateForDatepicker = (date) => {
  if (date === undefined || date === null) {
    return '';
  }

  try {
    return new Date(date)?.toISOString()?.substring(0, 10);
  } catch (e) {
    // IGNORE
  }
};

export const numberFormatter = (language, value, options) =>
  Intl.NumberFormat(language, options).format(value);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  capitalize,
  currencyFormatter,
  stringFormatter,
  dateFormatter,
  numberFormatter,
  formatDateForDatepicker,
};
