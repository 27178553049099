import { SnackbarProvider } from 'notistack';

import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  createRoutesFromChildren,
  createSearchParams,
  matchRoutes,
  useLocation,
  useNavigationType,
  useParams,
} from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';

import * as Sentry from '@sentry/react';
import SentryRRWeb from '@sentry/rrweb';

import { envUtils } from '@ft-frontend/core';
import { TrackingPixel } from '@ft-frontend/core';

import ErrorMessage from 'components/ErrorMessage';
import NavigateSqueaky from 'components/NavigateSqueaky';
import Online from 'components/Online';
import OnlineTradeinWrapper from 'components/OnlineTradeinWrapper';
// import PageNotFound from 'PageNotFound';
import Posale from 'components/Posale';
import RoleWrapper from 'components/RoleWrapper';

import { ADMIN_WHITELIST } from 'constants/user';

import OnlineStartingInfoProvider from 'contexts/starting_info/OnlineStartingInfoProvider';

const PageNotFound = React.lazy(() => import('pages/PageNotFound'));

const Approved = React.lazy(() => import('pages/online/Approved'));
const Categories = React.lazy(() => import('pages/online/Categories'));
const ContractSearch = React.lazy(() => import('pages/online/ContractSearch'));
const ContractSummary = React.lazy(() =>
  import('pages/online/ContractSummary'),
);
const CreditCheck = React.lazy(() => import('pages/online/CreditCheck'));
const Customization = React.lazy(() => import('pages/online/Customization'));
const Delivery = React.lazy(() => import('pages/online/Delivery'));
const Evaluate = React.lazy(() => import('pages/online/Evaluate'));
const Extras = React.lazy(() => import('pages/online/Extras'));
const Families = React.lazy(() => import('pages/online/Families'));
const Options = React.lazy(() => import('pages/online/Options'));
const PartialApproved = React.lazy(() =>
  import('pages/online/PartialApproved'),
);
const Referred = React.lazy(() => import('pages/online/Referred'));
const Refused = React.lazy(() => import('pages/online/Refused'));
const Result = React.lazy(() => import('pages/online/Result'));
const Review = React.lazy(() => import('pages/online/Review'));
const Test = React.lazy(() => import('pages/online/Test'));
const Unexpected = React.lazy(() => import('pages/online/Unexpected'));
const Withdrawn = React.lazy(() => import('pages/online/Withdrawn'));
const Rules = React.lazy(() => import('pages/posale/admin/Rules'));
const FindDevice = React.lazy(() => import('pages/online/tradein/FindDevice'));
const EstimatedValue = React.lazy(() =>
  import('pages/online/tradein/EstimatedValue'),
);
const TradeInEvaluation = React.lazy(() =>
  import('pages/online/tradein/Evaluation'),
);
const TradeInCustomer = React.lazy(() =>
  import('pages/online/tradein/Customer'),
);
const TradeInSummary = React.lazy(() => import('pages/online/tradein/Summary'));
const ReturnEvaluation = React.lazy(() =>
  import('pages/online/return/Evaluation'),
);
const ReturnCustomer = React.lazy(() => import('pages/online/return/Customer'));
const ReturnSummary = React.lazy(() => import('pages/online/return/Summary'));
const CustomerOptions = React.lazy(() =>
  import('pages/online/CustomerOptions'),
);

Sentry.init({
  autoSessionTracking: true,
  dsn: 'https://7c0a1fab52624204807f3e4e1533d6d4@o348393.ingest.sentry.io/6442982',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new SentryRRWeb(),
  ],
  debug: process.env.REACT_APP_SENTRY_DEBUG === 'true',
  environment: process.env.REACT_APP_SENTRY_ENV || 'development',
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // We recommend adjusting this value in production,
  // or using tracesSampler for finer control
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = ({ persistor }) => {
  const snackbarDisplayTime = useSelector(
    (state) => state.ui.snackbarDisplayTime,
  );
  const pageName = useSelector((state) => state.ui.pageName);
  const wlId = useSelector((state) => state.ui.wlId);
  const storeId = useSelector((state) => state.ui.storeId);

  const notistackRef = React.createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  // https://stackoverflow.com/questions/70073688/react-router-6-navigate-to-using-params
  const NavigateParamsToQuery = ({ to, replace = true }) => {
    const params = useParams();

    return (
      <Navigate
        to={`${to}?${createSearchParams(params).toString()}`}
        replace={replace}
      />
    );
  };

  const RedirectWithParams = ({ to, ...props }) => {
    const insertParams = (to, params) => {
      const entries = Object.entries(params);

      entries.forEach(([key, value]) => {
        to = to.replace(`:${key}`, `${value}`);
      });

      return to;
    };

    const params = useParams();
    return <Navigate to={insertParams(to, params)} {...props} replace />;
  };

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => <ErrorMessage resetError={resetError} />}
    >
      <SnackbarProvider
        autoHideDuration={snackbarDisplayTime || 5000}
        content={(key, messageParam) => {
          const isStringMessage =
            typeof messageParam === 'string' || messageParam instanceof String;

          let variant = 'error';
          let title = '';
          let message = messageParam;

          if (!isStringMessage) {
            ({ variant, title, message } = messageParam);
          }

          return (
            <Alert
              id={key}
              severity={variant}
              variant='filled'
              action={
                <IconButton
                  color='inherit'
                  sx={{ p: 0.5 }}
                  onClick={onClickDismiss(key)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              {title && <AlertTitle>{title}</AlertTitle>}
              {message}
              <TrackingPixel
                type='errortrack'
                pageName={pageName}
                display={variant}
                wlID={wlId}
                storeID={storeId}
                message={message}
              />
            </Alert>
          );
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ref={notistackRef}
      >
        <BrowserRouter>
          <SentryRoutes>
            <Route index element={<Navigate to='online' replace={true} />} />

            <Route path='online/tradein' element={<OnlineTradeinWrapper />}>
              <Route
                path='search'
                element={<Navigate to='/online/tradein/finddevice' replace />}
              />
              <Route
                exact
                path='finddevice'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <FindDevice />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='estimation'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <EstimatedValue />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='delivery'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <TradeInCustomer />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='summary'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <TradeInSummary />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='evaluation'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <TradeInEvaluation />
                  </React.Suspense>
                }
              />
            </Route>

            <Route
              exact
              path='online/:campaignID/trade/:tradecartUUID'
              element={
                <React.Suspense fallback={<React.Fragment />}>
                  <OnlineStartingInfoProvider>
                    <CustomerOptions />
                  </OnlineStartingInfoProvider>
                </React.Suspense>
              }
            />

            <Route
              exact
              path='online/:campaignID/order/:orderGUID'
              element={
                <React.Suspense fallback={<React.Fragment />}>
                  <OnlineStartingInfoProvider>
                    <CustomerOptions />
                  </OnlineStartingInfoProvider>
                </React.Suspense>
              }
            />

            <Route
              exact
              path='online/:campaignID/order/:templateName/:orderGUID'
              element={
                <React.Suspense fallback={<React.Fragment />}>
                  <OnlineStartingInfoProvider>
                    <CustomerOptions />
                  </OnlineStartingInfoProvider>
                </React.Suspense>
              }
            />

            <Route path='online' element={<Online />}>
              {!envUtils.isProdEnv() && !envUtils.isDemoEnv() ? (
                <Route index element={<Navigate to='test' replace={true} />} />
              ) : (
                <Route
                  index
                  element={<Navigate to='categories' replace={true} />}
                />
              )}

              <Route
                exact
                path='contract/search'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <ContractSearch />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path=':uuid/summary'
                element={
                  <RedirectWithParams to={`/online/contract/summary/:uuid`} />
                }
              />
              <Route
                exact
                path='contract/summary/:uuid'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <ContractSummary />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='return/evaluation'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <ReturnEvaluation />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='return/delivery'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <ReturnCustomer />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='return/summary'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <ReturnSummary />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='categories'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Categories />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path='families/category/:category/:paymentPlanType/:paymentPlanMonths'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Families />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='families/category/:category/:paymentPlanType'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Families />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='options/:deviceCode'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Options />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='customize/:parentDeviceCode/:deviceCode'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Customization />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='extras'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Extras />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='evaluate'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Evaluate />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='delivery'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Delivery />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='review'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Review />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='express/review/:cartGUID'
                element={<NavigateParamsToQuery to='/online/review' />}
              />

              <Route
                exact
                path='creditcheck'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <CreditCheck />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path='result/:cart'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <Result />
                  </React.Suspense>
                }
              >
                <Route
                  exact
                  path='approved'
                  element={
                    <React.Suspense fallback={<React.Fragment />}>
                      <Approved />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path='preapproved'
                  element={
                    <React.Suspense fallback={<React.Fragment />}>
                      <PartialApproved />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path='referred'
                  element={
                    <React.Suspense fallback={<React.Fragment />}>
                      <Referred />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path='refused'
                  element={
                    <React.Suspense fallback={<React.Fragment />}>
                      <Refused />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path='withdrawn'
                  element={
                    <React.Suspense fallback={<React.Fragment />}>
                      <Withdrawn />
                    </React.Suspense>
                  }
                />

                <Route
                  exact
                  path='unexpected/:status'
                  element={
                    <React.Suspense fallback={<React.Fragment />}>
                      <Unexpected />
                    </React.Suspense>
                  }
                />
              </Route>

              {!envUtils.isProdEnv() && !envUtils.isDemoEnv() && (
                <Route
                  exact
                  path='test'
                  element={
                    <React.Suspense fallback={<React.Fragment />}>
                      <Test />
                    </React.Suspense>
                  }
                />
              )}

              <Route
                path='*'
                element={
                  <React.Suspense fallback={<React.Fragment />}>
                    <PageNotFound />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='posale' element={<Posale persistor={persistor} />}>
              <Route index element={<NavigateSqueaky />} />

              <Route
                exact
                path='admin/rules'
                element={
                  <RoleWrapper allowedRoles={ADMIN_WHITELIST}>
                    <React.Suspense fallback={<React.Fragment />}>
                      <Rules ruleTableIdentifier='RESELLER_MARGIN_RULES' />
                    </React.Suspense>
                  </RoleWrapper>
                }
              />

              <Route path='*' element={<NavigateSqueaky />} />
            </Route>

            <Route
              path='*'
              element={
                <React.Suspense fallback={<React.Fragment />}>
                  <PageNotFound />
                </React.Suspense>
              }
            />
          </SentryRoutes>
        </BrowserRouter>
      </SnackbarProvider>
    </Sentry.ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
