import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans } from '@lingui/macro';

import CloseIcon from '@mui/icons-material/Close';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Card from 'components/Online/CartWidget/Card';
import PaymentInformation from 'components/PaymentInformation';

import { removeExtra, removeInsurance } from 'reducers/cart/cartSlice';

export default function CartWidget() {
  const { order } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const [cartVisible, toggleCartVisibility] = useState(false);
  const openCart = () => toggleCartVisibility(true);
  const closeCart = () => toggleCartVisibility(false);

  const countCartItems = () =>
    (order?.product ? 1 : 0) +
    (order?.extras?.length || 0) +
    (order?.insurances?.length || 0);

  const checkCartBlockedPaths = () =>
    window.location.pathname.includes('creditcheck');

  const getMarginBasedOnItems = () => {
    let nrOfItems = countCartItems();
    let spacing;

    if (nrOfItems > 9) {
      spacing = 2;
    } else if (nrOfItems > 0) {
      spacing = 1.5;
    } else {
      spacing = 0.5;
    }
    return spacing;
  };

  return (
    <>
      <Stack
        direction='row-reverse'
        justifyContent='flex-start'
        alignItems='flex-end'
        spacing={getMarginBasedOnItems()}
        onClick={openCart}
        sx={(theme) => ({
          color: theme.palette.cartWidget.main,
          cursor: 'pointer',
        })}
      >
        <Typography variant='body2'>
          {countCartItems() > 0 ? (
            <Trans id='online.header.my.cart'>Item(s) in my cart</Trans>
          ) : (
            <Trans id='online.header.my.cart.empty'>My cart</Trans>
          )}
        </Typography>
        <Badge
          data-testid='cart-widget-badge-nr'
          badgeContent={countCartItems()}
          color='error'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <ShoppingBag />
        </Badge>
      </Stack>
      <Drawer
        anchor='right'
        open={cartVisible}
        onClose={closeCart}
        ModalProps={{ BackdropProps: { invisible: true } }}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderRadius: '0',
            [theme.breakpoints.down('md')]: {
              padding: `${theme.spacing(7)} ${theme.spacing(2)} ${theme.spacing(
                2,
              )}`,
              width: '100%',
            },
            [theme.breakpoints.up('md')]: {
              padding: `${theme.spacing(7)} ${theme.spacing(3)} ${theme.spacing(
                3,
              )}`,
              width: 'calc(100vw / 1.5);',
              borderTopLeftRadius: '12px',
              borderBottomLeftRadius: '12px',
            },
            [theme.breakpoints.up('lg')]: {
              padding: `${theme.spacing(7)} ${theme.spacing(3)} ${theme.spacing(
                3,
              )}`,
              width: 'min(calc(100vw / 3), 640px);',
            },
          }),
        }}
      >
        <IconButton
          aria-label='close'
          onClick={closeCart}
          sx={(theme) => ({
            position: 'absolute',
            color: 'gray.dark',
            padding: 0,
            top: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
              right: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
              right: theme.spacing(3),
            },
          })}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Paper
            variant='outlined'
            elevation={0}
            sx={(theme) => ({
              borderColor: 'gray.main',
              borderRadius: '12px',
              padding: countCartItems() > 0 ? 0 : theme.spacing(3),
            })}
          >
            {countCartItems() > 0 ? (
              <>
                <Stack
                  spacing={2}
                  sx={(theme) => ({ padding: theme.spacing(3) })}
                  data-testid='cart-widget-items'
                >
                  {order?.product && <Card item={order?.product} />}

                  {order?.extras?.length > 0 && (
                    <>
                      <Divider flexItem variant='fullWidth' />

                      {order?.extras.map((extra) => (
                        <Card
                          key={extra.code}
                          item={extra}
                          removeItem={() => {
                            dispatch(removeExtra(extra));
                          }}
                          blocked={checkCartBlockedPaths()}
                        />
                      ))}
                    </>
                  )}

                  {order?.insurances?.length > 0 && (
                    <>
                      <Divider flexItem variant='fullWidth' />

                      {order?.insurances.map((insurance) => (
                        <Card
                          key={insurance.code}
                          item={insurance}
                          removeItem={() => {
                            dispatch(removeInsurance());
                          }}
                          blocked={checkCartBlockedPaths()}
                        />
                      ))}
                    </>
                  )}
                </Stack>

                <PaymentInformation
                  order={order}
                  showOrderDetails={false}
                  showDisclaimers={false}
                  variant='outlined'
                  dataTestID='cart-widget-order-totals'
                />
              </>
            ) : (
              <>
                <Typography
                  variant='body2'
                  data-testid='cart-widget-empty-cart'
                >
                  <Trans id='online.cart.widget.empty'>
                    You have no items in your shopping cart
                  </Trans>
                </Typography>
                <Typography variant='body2'>
                  <Link component='button' variant='body2' onClick={closeCart}>
                    <Trans id='online.cart.widget.close.link'>Click here</Trans>
                  </Link>{' '}
                  <Trans id='online.cart.widget.close.text'>
                    to continue shopping
                  </Trans>
                </Typography>
              </>
            )}
          </Paper>
        </Box>
      </Drawer>
    </>
  );
}
