import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Image } from '@ft-frontend/core';

export default function OnlineFooter() {
  return (
    <Toolbar
      sx={{
        backgroundColor: 'white',
        padding: '16px 24px',
        borderTop: '1px solid #e9e9e9',
        minHeight: '81px !important',
        maxHeight: '81px !important',
        justifyContent: 'center',
      }}
      component='footer'
      disableGutters
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%', maxWidth: '1366px !important' }}
      >
        <Box></Box>

        <Stack alignItems='center'>
          <Image
            filePath='Techdata/TDSYNNEX-transparent.svg'
            alt='TD SYNNEX'
            data-testid='footer-td-synnex-logo'
            aspectRatio='101/24'
            width='101'
            height='24'
          />

          <Typography
            variant='caption'
            sx={{
              color: '#606060',
            }}
            data-testid='footer-td-synnex-copyright'
          >
            Copyright © {new Date().getFullYear()}. All rights reserved.
          </Typography>

          <Typography
            variant='caption'
            sx={{
              color: '#606060',
            }}
          >
            {`Ver. #${process.env.REACT_APP_SENTRY_RELEASE}`}
          </Typography>
        </Stack>

        <Box></Box>
      </Stack>
    </Toolbar>
  );
}
