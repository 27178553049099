import React from 'react';

import { Trans } from '@lingui/macro';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Image } from '@ft-frontend/core';
import { converters } from '@ft-frontend/core';

import AvailabilityIndicator from 'components/AvailabilityIndicator';
import Skeleton from 'components/Skeleton';

import { currencyFormatter } from 'utils/formatters';

export default function OptionsCard({
  colourOptionsType,
  currencyCode,
  emptyOption = false,
  enabled = true,
  hexColourCodes,
  hidePrice = false,
  imgURL,
  isLoading,
  onClick = () => {},
  option = {},
  priceLabel = <Trans id='option.card.price.label'>per month</Trans>,
  secondaryPriceLabel = <Trans id='online.card.cash.price'>cash price</Trans>,
  pricePrefix = '',
  selected = null,
  stockLevel,
}) {
  const getGradientString = (hexList = []) => {
    const spacing = 100 / hexList.length;

    const gradientValues = hexList.map(
      (hexCode, index) =>
        `${hexCode} ${spacing * index}% ${spacing * (index + 1)}%`,
    );

    return `linear-gradient(${gradientValues.join(', ')})`;
  };

  const colourSwatchType = {
    URL: (
      <Image
        data-testid={`card-option-${option.subtitle}-image`}
        filePath={imgURL}
        alt={option.name}
        aspectRatio='1/1'
        height='56'
        width='56'
        pictureStyle={{
          flexShrink: 0,
        }}
      />
    ),
    SWATCH: (
      <div
        data-testid={`card-option-${option.subtitle}-swatch`}
        style={{
          display: 'inline-block',
          width: '32px',
          height: '32px',
          borderRadius: '32px',
          boxShadow: 'inset 1px 1px 2px #a4a4a4',
          background: getGradientString(hexColourCodes),
          transform: 'rotate(-45deg)',
          flexShrink: 0,
        }}
      />
    ),
  };

  return (
    <Paper
      sx={{
        height: '100%',
        width: '100%',
        padding: '8px',
        borderWidth: '2px',
        borderColor: selected && 'secondary.main',
        cursor: enabled && !selected ? 'pointer' : 'default',
        opacity: enabled ? 1 : 0.25,
      }}
      elevation={0}
      variant='outlined'
      onClick={() => {
        enabled && !selected && !isLoading && onClick(option.code);
      }}
    >
      <Stack
        data-testid='option-card'
        data-test-status={
          selected ? 'SELECTED' : enabled ? 'ENABLED' : 'DISABLED'
        }
        spacing={1}
        justifyContent='space-between'
        sx={{ height: '100%' }}
      >
        <Stack direction='row' spacing={1}>
          <Skeleton isLoading={isLoading} variant='rounded'>
            {colourSwatchType[colourOptionsType]}
          </Skeleton>
          <Stack>
            <Typography
              variant='subtitle'
              sx={{ fontWeight: 600 }}
              data-testid='option-name'
            >
              <Skeleton isLoading={isLoading} width={120}>
                {converters.safeHTMLParser(option.name)}
              </Skeleton>
            </Typography>

            {!emptyOption && (
              <Typography
                variant='body2'
                sx={{ color: 'text.secondary' }}
                data-testid='option-code'
              >
                <Skeleton isLoading={isLoading}>{option.subtitle}</Skeleton>
              </Typography>
            )}
          </Stack>
        </Stack>

        {!hidePrice && !emptyOption && enabled && (
          <Stack spacing={0.5}>
            <Stack direction='row' alignItems='baseline' spacing={0.5}>
              <Typography
                variant='h5'
                component='span'
                sx={{ fontWeight: 600 }}
                data-testid='option-monthly-price'
              >
                <Skeleton
                  isLoading={isLoading}
                  width={64}
                  sx={(theme) => ({
                    fontSize: theme.typography.h5.fontSize,
                  })}
                >
                  {pricePrefix}{' '}
                  {currencyFormatter(option.mainPrice, currencyCode)}
                </Skeleton>
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  overflowWrap: 'anywhere',
                }}
              >
                {priceLabel}
              </Typography>
            </Stack>
            {option.strikethroughPrice && (
              <Typography
                variant='body2'
                sx={(theme) => ({
                  textDecoration: 'line-through',
                  color: theme.palette.text.secondary,
                })}
                data-testid='option-strikethrough-price'
              >
                <Skeleton isLoading={isLoading}>
                  {pricePrefix}{' '}
                  {currencyFormatter(option.strikethroughPrice, currencyCode)}
                </Skeleton>
              </Typography>
            )}
            {option.secondaryPrice && (
              <Typography
                variant='body2'
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
                data-testid='option-cash-price'
              >
                <Skeleton isLoading={isLoading}>
                  {pricePrefix}{' '}
                  {currencyFormatter(option.secondaryPrice, currencyCode)}{' '}
                  {secondaryPriceLabel}
                </Skeleton>
              </Typography>
            )}
          </Stack>
        )}

        {stockLevel && (
          <AvailabilityIndicator
            justifyContent='flex-start'
            stockStatus={stockLevel}
          />
        )}
      </Stack>
    </Paper>
  );
}
