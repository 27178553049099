import React from 'react';
import { useDispatch } from 'react-redux';

import { Trans } from '@lingui/macro';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { posaleService, utils } from '@ft-frontend/core';

import { changePasswordUrl, loginPageUrl } from 'constants/routes';

import { usePosaleAPI } from 'hooks/usePosaleAPI';

import { logout } from 'reducers/redux/reduxSlice';

const UserMenu = ({
  hasSingleSignOn,
  name,
  persistor,
  username = 'No username',
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openUserManual = (event) => {
    event.preventDefault();
    handleClose();

    window.open(
      `https://cdn.financetech365.com/ft/manual_${utils.getWhitelabelShortName()}.pdf`,
    );
  };

  const handleNavigate = (path) => () => {
    handleClose();
    window.location.href = path;
  };

  const {
    triggerRequest: _callLogout,
    exceptionHandler,
    apiMessageHandler,
  } = usePosaleAPI(posaleService.endpoints.logout, {
    initialRequest: false,
  });

  const handleLogout = async (event) => {
    event.preventDefault();

    const logoutResponse = await posaleService.logout(
      {},
      {
        exceptionHandler,
        errorHandler: apiMessageHandler('error'),
        warningHandler: apiMessageHandler('warning'),
      },
    );

    if (logoutResponse?.data?.header?.apiStatus === 'SUCCESS') {
      await dispatch(logout());

      // dispatch(cleanStoreAction());

      await persistor?.flush();

      logoutResponse?.data?.url &&
        window.open(logoutResponse.data.url, '_blank');
    }

    window.setTimeout(() => (window.location.href = loginPageUrl), 100);
  };

  return (
    <>
      <Button
        id='user-menu-button'
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        data-testid='posale-header-username'
        onClick={handleClick}
        sx={{
          color: 'white !important',
          fontSize: '0.75rem',
          fontWeight: '400',
          padding: '0',
          minWidth: '0',
        }}
      >
        <Stack direction='row' spacing={1} alignItems='flex-end'>
          <Typography variant='body2' sx={{ fontSize: '0.75rem' }}>
            {`${name || ''} / ${username}`}
          </Typography>
          <img
            width='24px'
            height='24px'
            src='https://cdn.financetech365.com/svg/user_menu.svg'
          />
        </Stack>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={openUserManual}
          data-testid='posale-header-usermanual'
        >
          <Trans id='settings.usermanual'>User manual</Trans>
        </MenuItem>
        {!hasSingleSignOn && (
          <MenuItem
            onClick={handleNavigate(changePasswordUrl)}
            data-testid='posale-header-change-password'
          >
            <Trans id='settings.change.pwd'>Change Password</Trans>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout} data-testid='posale-header-logout'>
          <Trans id='settings.logout'>Logout</Trans>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
