import { Trans } from '@lingui/macro';

import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export const Footer = ({ address, email, name, phone, taxNumber, vat }) => {
  return (
    <Toolbar
      disableGutters
      id='footer-tradein'
      component='footer'
      sx={{
        padding: '8px 24px',
        justifyContent: 'center',
        backgroundColor: 'white',
        height: '80px',
        borderTop: '1px solid #e9e9e9',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ maxWidth: '1366px', width: '100%' }}
        spacing={1}
      >
        <Stack>
          {name && (
            <Typography variant='body2' data-testid='footer-app-name'>
              {name}
            </Typography>
          )}
          {address && (
            <Typography variant='body2' data-testid='footer-app-address'>
              {address}
            </Typography>
          )}
        </Stack>
        <Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 0, md: 1 }}
            sx={{ textAlign: 'end' }}
          >
            {phone && (
              <Typography variant='body2' data-testid='footer-app-phone'>
                {phone}
              </Typography>
            )}
            {phone && email && (
              <Divider
                orientation='vertical'
                flexItem
                sx={{ display: { xs: 'none', md: 'flex' } }}
              />
            )}
            {email && (
              <Link href={`mailto:${email}`} data-testid='footer-app-email'>
                <Typography variant='body2'>{email}</Typography>
              </Link>
            )}
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 0, md: 1 }}
            justifyContent='end'
            sx={{ textAlign: 'end' }}
          >
            {taxNumber && (
              <Typography variant='body2' data-testid='footer-app-taxid'>
                <Trans id='footer.wl.taxid'>UTR</Trans>: {taxNumber}{' '}
              </Typography>
            )}
            {vat && (
              <Typography variant='body2' data-testid='footer-app-vat'>
                <Trans id='footer.wl.vat'>VAT</Trans>: {vat}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default Footer;
