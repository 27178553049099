import isEmpty from 'lodash/isEmpty';

import React from 'react';

import { Trans } from '@lingui/macro';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AccordionPreview from 'components/AccordionPreview';
import EvaluationSummary from 'components/Online/Evaluation/EvaluationSummary';
import OnlineSwapLegal from 'components/OnlineSwapLegal';
import PaymentDisclaimers from 'components/PaymentDisclaimers';
import PaymentOptions from 'components/PaymentOptions';
import Skeleton from 'components/Skeleton';

import { currencyFormatter } from 'utils/formatters';

const ExtraLine = ({ extra, currencyCode }) => (
  <Stack
    key={extra.code}
    direction='row'
    justifyContent='space-between'
    data-testid='order-details-extra'
  >
    <Typography variant='body2' data-testid='extra-name'>
      {extra.name}
    </Typography>
    {
      <Stack textAlign='end' data-testid='extra-monthly-price'>
        {extra.monthlyPrice && (
          <>
            <Typography variant='body2' sx={{ fontWeight: 600 }} noWrap>
              {currencyFormatter(extra.monthlyPrice, currencyCode)}
            </Typography>
            <Typography variant='body2' noWrap>
              <Trans id='payment.extra.monthly'>per month</Trans>
            </Typography>
          </>
        )}

        {extra.cashPrice && (
          <>
            <Typography variant='body2' noWrap>
              {currencyFormatter(extra.cashPrice, currencyCode)}
            </Typography>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-end', md: 'baseline' }}
              spacing={{ xs: 0, md: 0.5 }}
            >
              <Typography variant='body2' noWrap>
                <Trans id='payment.upfront.cashprice'>cash price</Trans>
              </Typography>

              <Typography variant='caption'>
                <Trans id='payment.include.vat'>{' (incl. VAT)'}</Trans>
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    }
  </Stack>
);

const PaymentInformation = ({
  isLoading,
  order,
  showPaymentPlans,
  paymentPlans,
  setSelectedPaymentPlan = () => {},
  showOrderDetails = true,
  showDisclaimers = true,
  showEvaluation = false,
  variant,
  dataTestID,
  paperStyle = {},
  hasMultipleOrders,
  cartUUID,
  recycleDevice,
  recycleEvaluation,
}) => {
  const extras = order?.extras?.reduce(
    ([accessories, ctos], extra) =>
      extra.isCTO
        ? [accessories, [...ctos, extra]]
        : [[...accessories, extra], ctos],
    [[], []],
  );

  const [accessories, ctos] = extras || [];
  return (
    <Paper
      sx={{
        backgroundColor: 'gray.light',
        borderRadius: '12px',
        ...paperStyle,
      }}
      elevation={0}
      variant={variant}
    >
      <Stack
        spacing={2}
        useFlexGap
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            padding: `${theme.spacing(2)}`,
          },
          [theme.breakpoints.up('lg')]: {
            padding: `${theme.spacing(3)}`,
          },
        })}
      >
        {showPaymentPlans && (paymentPlans?.length > 0 || isLoading) && (
          <>
            <PaymentOptions
              isLoading={isLoading}
              paymentPlans={paymentPlans}
              financing={order?.financing}
              setSelectedPaymentPlan={setSelectedPaymentPlan}
            />
            <Divider flexItem variant='fullWidth' />
          </>
        )}

        {showOrderDetails && (
          <>
            <Grid container spacing={2} data-testid='order-details'>
              <Grid item xs={12} md={6}>
                <Typography variant='h5' component='h2'>
                  <Trans id='payment.order.details'>My order details</Trans>
                </Typography>
              </Grid>

              {(order?.orderUUID || cartUUID) && (
                <Grid item xs={12} md={6}>
                  <Typography
                    variant='body1'
                    sx={(theme) => ({
                      fontWeight: 600,
                      lineHeight: '1.9',
                      [theme.breakpoints.down('md')]: {
                        textAlign: 'left',
                      },
                      [theme.breakpoints.up('md')]: {
                        textAlign: 'right',
                      },
                    })}
                    data-testid='order-UUID'
                  >
                    <Skeleton isLoading={isLoading}>
                      {hasMultipleOrders ? order?.orderUUID : cartUUID}
                    </Skeleton>
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Stack spacing={2} useFlexGap>
              {order?.products?.map((product, idx) => (
                <Stack
                  key={`${product.code}-${idx}`}
                  direction='row'
                  justifyContent='space-between'
                  data-testid='order-details-product'
                >
                  <Stack>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 600 }}
                      data-testid='product-name'
                    >
                      {product.description}
                    </Typography>
                    {order?.financing?.deviceContractFinance?.financeType ===
                      'UPGRADE' && (
                      <Typography variant='body2' data-testid='product-tagline'>
                        <Trans id='payment.order.details.available'>{`Available for upgrade after ${order?.financing?.fixedPeriodEndMonth} months`}</Trans>
                      </Typography>
                    )}
                  </Stack>
                  <Stack textAlign='end' data-testid='product-monthly-price'>
                    <Typography variant='body2' sx={{ fontWeight: 600 }} noWrap>
                      {currencyFormatter(
                        product.monthlyPrice,
                        order?.financing?.currencyCode,
                      )}
                    </Typography>
                    <Typography variant='body2' noWrap>
                      <Trans id='payment.extra.monthly'>per month</Trans>
                    </Typography>

                    <Typography variant='body2' noWrap>
                      {currencyFormatter(
                        product.cashPrice,
                        order?.financing?.currencyCode,
                      )}
                    </Typography>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      alignItems={{ xs: 'flex-end', md: 'baseline' }}
                      justifyContent='flex-end'
                      spacing={{ xs: 0, md: 0.5 }}
                    >
                      <Typography variant='body2' noWrap>
                        <Trans id='payment.upfront.cashprice'>cash price</Trans>
                      </Typography>

                      <Typography variant='caption' noWrap>
                        <Trans id='payment.include.vat'>{' (incl. VAT)'}</Trans>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>

            {!isEmpty(ctos) && (
              <>
                <Divider flexItem variant='fullWidth' />

                <Stack
                  spacing={2}
                  useFlexGap
                  data-testid='order-details-extra-block'
                >
                  <Typography variant='body1' sx={{ fontWeight: 600 }}>
                    <Trans id='payment.ctos.subtitle'>Manual CTOs</Trans>
                  </Typography>

                  <Stack
                    spacing={2}
                    useFlexGap
                    sx={(theme) => ({
                      paddingLeft: theme.spacing(1),
                    })}
                  >
                    {ctos.map((cto) => (
                      <ExtraLine
                        extra={cto}
                        currencyCode={order?.financing?.currencyCode}
                      />
                    ))}
                  </Stack>
                </Stack>
              </>
            )}

            {!isEmpty(accessories) && (
              <>
                <Divider flexItem variant='fullWidth' />

                <Stack
                  spacing={2}
                  useFlexGap
                  data-testid='order-details-extra-block'
                >
                  <Typography variant='body1' sx={{ fontWeight: 600 }}>
                    <Trans id='payment.extras.subtitle'>Accessories</Trans>
                  </Typography>

                  <Stack
                    spacing={2}
                    useFlexGap
                    sx={(theme) => ({
                      paddingLeft: theme.spacing(1),
                    })}
                  >
                    {accessories.map((accessory) => (
                      <ExtraLine
                        extra={accessory}
                        currencyCode={order?.financing?.currencyCode}
                      />
                    ))}
                  </Stack>
                </Stack>
              </>
            )}

            {!isEmpty(order?.specialOffers) && (
              <>
                <Divider flexItem variant='fullWidth' />

                <Stack
                  spacing={2}
                  useFlexGap
                  data-testid='order-details-specialOffer-block'
                >
                  <Typography variant='body1' sx={{ fontWeight: 600 }}>
                    <Trans id='payment.specialOffers.subtitle'>
                      Special offer(s)
                    </Trans>
                  </Typography>

                  <Stack
                    spacing={2}
                    useFlexGap
                    sx={(theme) => ({
                      paddingLeft: theme.spacing(1),
                    })}
                  >
                    {order?.specialOffers?.map((specialOffer) => (
                      <Stack
                        key={specialOffer.uuid}
                        direction='row'
                        justifyContent='space-between'
                        data-testid='order-details-specialOffer'
                      >
                        <Typography
                          variant='body2'
                          data-testid='specialOffer-name'
                        >
                          {specialOffer.name}
                        </Typography>
                        <Stack
                          textAlign='end'
                          data-testid='specialOffer-monthly-price'
                        >
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 600 }}
                            noWrap
                          >
                            {currencyFormatter(
                              specialOffer.monthlyPrice,
                              order?.financing?.currencyCode,
                            )}
                          </Typography>
                          <Typography variant='body2' noWrap>
                            <Trans id='payment.extra.monthly'>per month</Trans>
                          </Typography>

                          <Typography variant='body2' noWrap>
                            {currencyFormatter(
                              specialOffer.cashPrice,
                              order?.financing?.currencyCode,
                            )}
                          </Typography>
                          <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            alignItems={{ xs: 'flex-end', md: 'baseline' }}
                            justifyContent='flex-end'
                            spacing={{ xs: 0, md: 0.5 }}
                          >
                            <Typography variant='body2' noWrap>
                              <Trans id='payment.upfront.cashprice'>
                                cash price
                              </Trans>
                            </Typography>

                            <Typography variant='caption' noWrap>
                              <Trans id='payment.include.vat'>
                                {' (incl. VAT)'}
                              </Trans>
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </>
            )}

            {!isEmpty(order?.insurances) && (
              <>
                <Divider flexItem variant='fullWidth' />

                <Stack
                  spacing={2}
                  useFlexGap
                  data-testid='order-details-insurance-block'
                >
                  <Typography variant='body1' sx={{ fontWeight: 600 }}>
                    <Trans id='payment.insurances.subtitle'>Insurance(s)</Trans>
                  </Typography>

                  <Stack
                    spacing={2}
                    useFlexGap
                    sx={(theme) => ({
                      paddingLeft: theme.spacing(1),
                    })}
                  >
                    {order?.insurances?.map((insurance) => (
                      <Stack
                        key={insurance.code}
                        direction='row'
                        justifyContent='space-between'
                        data-testid='order-details-insurance'
                      >
                        <Typography
                          variant='body2'
                          data-testid='insurance-name'
                        >
                          {insurance.name}
                        </Typography>
                        <Stack
                          textAlign='end'
                          data-testid='insurance-monthly-price'
                        >
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 600 }}
                            noWrap
                          >
                            {currencyFormatter(
                              insurance.monthlyPrice,
                              order?.financing?.currencyCode,
                            )}
                          </Typography>
                          <Typography variant='body2' noWrap>
                            <Trans id='payment.extra.monthly'>per month</Trans>
                          </Typography>

                          <Typography variant='body2' noWrap>
                            {currencyFormatter(
                              insurance.cashPrice,
                              order?.financing?.currencyCode,
                            )}
                          </Typography>
                          <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            alignItems={{ xs: 'flex-end', md: 'baseline' }}
                            justifyContent='flex-end'
                            spacing={{ xs: 0, md: 0.5 }}
                          >
                            <Typography variant='body2' noWrap>
                              <Trans id='payment.upfront.cashprice'>
                                cash price
                              </Trans>
                            </Typography>

                            <Typography variant='caption' noWrap>
                              <Trans id='payment.include.vat'>
                                {' (incl. VAT)'}
                              </Trans>
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </>
            )}

            {!isEmpty(order?.discounts) && (
              <>
                <Divider flexItem variant='fullWidth' />

                <Stack
                  spacing={2}
                  useFlexGap
                  data-testid='order-details-discount-block'
                >
                  <Typography variant='body1' sx={{ fontWeight: 600 }}>
                    <Trans id='payment.discounts.subtitle'>Discount(s)</Trans>
                  </Typography>

                  <Stack
                    spacing={2}
                    useFlexGap
                    sx={(theme) => ({
                      paddingLeft: theme.spacing(1),
                    })}
                  >
                    {order?.discounts?.map((discount) => (
                      <Stack
                        key={discount.uuid}
                        direction='row'
                        justifyContent='space-between'
                        data-testid='order-details-discount'
                      >
                        <Typography variant='body2' data-testid='discount-name'>
                          {discount.name}
                        </Typography>
                        <Stack
                          textAlign='end'
                          data-testid='discount-monthly-price'
                        >
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 600 }}
                            noWrap
                          >
                            {currencyFormatter(
                              discount.monthlyPrice,
                              order?.financing?.currencyCode,
                            )}
                          </Typography>
                          <Typography variant='body2' noWrap>
                            <Trans id='payment.extra.monthly'>per month</Trans>
                          </Typography>

                          <Typography variant='body2' noWrap>
                            {currencyFormatter(
                              discount.cashPrice,
                              order?.financing?.currencyCode,
                            )}
                          </Typography>
                          <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            alignItems={{ xs: 'flex-end', md: 'baseline' }}
                            justifyContent='flex-end'
                            spacing={{ xs: 0, md: 0.5 }}
                          >
                            <Typography variant='body2' noWrap>
                              <Trans id='payment.upfront.cashprice'>
                                cash price
                              </Trans>
                            </Typography>

                            <Typography variant='caption' noWrap>
                              <Trans id='payment.include.vat'>
                                {' (incl. VAT)'}
                              </Trans>
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}

                    {order?.discounts?.length > 1 && (
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        data-testid='order-details-total-discount'
                      >
                        <Typography
                          variant='body2'
                          sx={{ fontWeight: 600 }}
                          data-testid='discount-name'
                        >
                          <Trans id='payment.discounts.total.subtitle'>
                            Total discounts
                          </Trans>
                        </Typography>
                        <Stack
                          textAlign='end'
                          data-testid='discount-total-monthly-price'
                        >
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 600 }}
                            noWrap
                          >
                            {currencyFormatter(
                              order?.financing?.monthlyTotalDiscounts,
                              order?.financing?.currencyCode,
                            )}
                          </Typography>
                          <Typography variant='body2' noWrap>
                            <Trans id='payment.extra.monthly'>per month</Trans>
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </>
            )}

            <Divider flexItem variant='fullWidth' />
          </>
        )}

        <Stack
          useFlexGap
          direction='row'
          justifyContent='space-between'
          data-testid='order-totals'
        >
          <Typography variant='h3'>
            <Trans id='payment.order.total'>Order total</Trans>
          </Typography>

          <Grid
            item
            container
            direction='column'
            width='auto'
            alignItems='end'
            spacing={1}
            xs={12}
            sm={7}
            data-testid={dataTestID}
          >
            <Grid
              item
              container
              width='auto'
              direction='column'
              alignItems='end'
              data-testid='total-monthly-price'
            >
              <Grid item>
                <Typography variant='h3' component='span'>
                  <Skeleton
                    isLoading={
                      isLoading && !order?.financing?.fixedPeriodMonthlyPayment
                    }
                    width={72}
                  >
                    {currencyFormatter(
                      order?.financing?.fixedPeriodMonthlyPayment,
                      order?.financing?.currencyCode,
                    )}
                  </Skeleton>
                </Typography>
              </Grid>
              <Grid item container alignItems='baseline'>
                <Typography variant='body2'>
                  <Skeleton
                    isLoading={isLoading && !order?.financing?.paymentPlanName}
                    width={72}
                  >
                    <Trans id='payment.total.monthly'>
                      {`per month on ${order?.financing?.paymentPlanName}`}
                    </Trans>
                  </Skeleton>
                </Typography>
              </Grid>
            </Grid>

            {order?.financing?.openingFeeAmount > 0 && (
              <Grid
                item
                container
                width='auto'
                direction='column'
                alignItems='end'
                data-testid='total-opening-fee'
              >
                <Grid item>
                  <Typography
                    variant='h5'
                    component='span'
                    sx={{ fontWeight: 600 }}
                  >
                    {currencyFormatter(
                      order?.financing?.openingFeeAmount,
                      order?.financing?.currencyCode,
                    )}
                  </Typography>
                </Grid>
                <Grid item container alignItems='baseline'>
                  <Typography variant='body2'>
                    <Trans id='payment.upfront.percentage'>
                      {`${order?.financing?.openingFeePercentage}% upfront payment`}
                    </Trans>
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid
              item
              container
              width='auto'
              direction='column'
              alignItems='end'
              data-testid='total-cash-price'
            >
              <Grid item>
                <Typography
                  variant='h5'
                  component='span'
                  sx={{ fontWeight: 600 }}
                >
                  <Skeleton
                    isLoading={
                      isLoading && !order?.financing?.startingPrincipal
                    }
                    width={112}
                  >
                    {currencyFormatter(
                      order?.financing?.deviceContractFinance?.totalSaleAmount,
                      order?.financing?.currencyCode,
                    )}
                  </Skeleton>
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction='row' alignItems='baseline' spacing={0.5}>
                  <Typography variant='body2' noWrap>
                    <Trans id='payment.upfront.cashprice'>cash price</Trans>
                  </Typography>

                  <Typography variant='caption' noWrap>
                    <Trans id='payment.include.vat'>{' (incl. VAT)'}</Trans>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Stack>

        {(showDisclaimers || showEvaluation) && (
          <Divider flexItem variant='fullWidth' />
        )}

        {showDisclaimers && (
          <PaymentDisclaimers
            hasMultipleOrders={hasMultipleOrders}
            isLoading={isLoading}
            order={order}
          />
        )}

        {showEvaluation && (
          <Paper
            sx={(theme) => ({
              backgroundColor: 'common.white',
              borderRadius: '12px',
              padding: theme.spacing(2),
            })}
            elevation={0}
            variant={variant}
          >
            <AccordionPreview
              summary={
                <Typography variant='subtitle2' sx={{ fontWeight: 600 }}>
                  <Trans id='payment.evaluate.summary.title'>
                    {recycleDevice?.name} evaluation summary
                  </Trans>
                </Typography>
              }
            >
              <Stack spacing={2} useFlexGap>
                <EvaluationSummary
                  grade={recycleEvaluation?.grade}
                  swapCost={recycleEvaluation?.swapCost}
                  value={recycleEvaluation?.deviceValue}
                  currencyCode={recycleEvaluation?.currencyCode}
                />
                <OnlineSwapLegal
                  funder={order?.financing?.funder}
                  paymentPlanName={order?.financing?.paymentPlanName}
                  deviceName={recycleDevice?.name}
                  evaluation={recycleEvaluation}
                />
              </Stack>
            </AccordionPreview>
          </Paper>
        )}
      </Stack>
    </Paper>
  );
};

export default PaymentInformation;
