import { createSlice } from '@reduxjs/toolkit';

const initialCookiesState = {
  hideCookieConsent: false,
};

export const cookiesSlice = createSlice({
  name: 'cookies',
  initialState: initialCookiesState,
  reducers: {
    setHideCookiesConsent: (state) => {
      state.hideCookieConsent = true;
    },
  },
});

export const { setHideCookiesConsent } = cookiesSlice.actions;

export default cookiesSlice.reducer;
