import React from 'react';

import { Trans } from '@lingui/macro';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { currencyFormatter } from 'utils/formatters';

export const OnlineSwapLegal = ({
  deviceName,
  funder,
  paymentPlanName,
  evaluation,
  flow = 'upgrade',
}) => {
  const deviceIsOk = ['A', 'B', 'W', 'IN_AGREED_CONDITION'].includes(
    evaluation?.grade,
  );

  const swapCost = currencyFormatter(
    evaluation.swapCost,
    evaluation.currencyCode,
  );

  const deviceValue = currencyFormatter(
    evaluation.deviceValue,
    evaluation.currencyCode,
  );

  const texts = {
    inCondition: {
      upgrade: (
        <>
          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.ok.1'>
              Your {deviceName} is in acceptable condition. After receipt and
              confirmation of the condition by our recycle partner,{' '}
              {deviceValue} will be credited to your {funder} Loan Account
              within 7 working days. This will fully pay off the estimated
              remaining amount of your current loan.
            </Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.ok.2'>
              * If the device evaluation by our recycle partner does not match
              yours, you will be given the option to accept a revised value or
              receive the device back. The final agreed value will be credited
              to your {funder} Loan Account within 3 working days after you
              accept the device grading.
            </Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.ok.3'>
              * The remaining loan amount is estimated based on you settling
              your monthly statement each month. Late fees or accrued interest
              have not been taken into consideration. Please check the {funder}{' '}
              Account to confirm your current loan balance.
            </Trans>
          </Typography>
        </>
      ),
      return: (
        <>
          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.ok.1'>
              Your {deviceName} is in acceptable condition. After receipt and
              confirmation of the condition by our recycle partner,{' '}
              {deviceValue} will be credited to your {funder} Loan Account
              within 7 working days. This will fully pay off the estimated
              remaining amount of your current loan.
            </Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.ok.2'>
              * If the device evaluation by our recycle partner does not match
              yours, you will be given the option to accept a revised value or
              receive the device back. The final agreed value will be credited
              to your {funder} Loan Account within 3 working days after you
              accept the device grading.
            </Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.ok.3'>
              * The remaining loan amount is estimated based on you settling
              your monthly statement each month. Late fees or accrued interest
              have not been taken into consideration. Please check the {funder}{' '}
              Account to confirm your current loan balance.
            </Trans>
          </Typography>
        </>
      ),
    },
    damaged: {
      upgrade: (
        <>
          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.not.ok.1'>
              Your {deviceName} is not in acceptable condition. As a result, it
              has a reduced value of {deviceValue} which we estimate will not
              fully pay off the remaining amount of your current loan. If you
              proceed with the Upgrade process, you will have an estimated
              remaining {paymentPlanName} loan balance of {swapCost}* You will
              have to continue making payments to {funder} until this loan
              amount is fully paid off, in addition to the payments for your new
              device.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.not.ok.2'>
              {deviceValue} will be credited to your {funder} Loan Account
              within 7 working days after your {deviceName} is received for
              evaluation.If the device evaluation by our recycle partner does
              not match yours, you will be given the option to accept a revised
              value or receive the device back. The final agreed value will be
              credited to your
              {funder} Loan Account within 3 working days after you accept the
              device grading.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.not.ok.3'>
              The Upgrade process will be finalized after you have been credit
              Approved for your new device.If you do not receive credit Approval
              for the new contract, the upgrade will be cancelled and you will
              be given the option to keep your existing device and continue your
              monthly payments or return the device.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.not.ok.4'>
              * The remaining loan amount is estimated based on you settling
              your monthly statement each month. Late fees or accrued interest
              have not been taken into consideration. Please check the {funder}{' '}
              Account to confirm your current loan balance.
              <br />
              ** If you choose to return your device, {deviceValue} will be
              credited to your {funder} Loan Account. This may or may not settle
              the outstanding balance on your {funder} account.
            </Trans>
          </Typography>
        </>
      ),
      return: (
        <>
          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.not.ok.1'>
              Your {deviceName} is not in acceptable condition. As a result, it
              has a reduced value of {deviceValue} which we estimate will not
              fully pay off the remaining amount of your current loan. If you
              proceed with the Return process, you will have an estimated
              remaining {paymentPlanName} loan balance of {swapCost}* You will
              have to continue making payments to {funder} until this loan
              amount is fully paid off.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.not.ok.2'>
              {deviceValue} will be credited to your {funder} Loan Account
              within 7 working days after your {deviceName} is received for
              evaluation. If the device evaluation by our recycle partner does
              not match yours, you will be given the option to accept a revised
              value or receive the device back. The final agreed value will be
              credited to your {funder} Loan Account within 3 working days after
              you accept the device grading.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.not.ok.3'>
              * The remaining loan amount is estimated based on you settling
              your monthly statement each month. Late fees or accrued interest
              have not been taken into consideration. Please check the {funder}{' '}
              Account to confirm your current loan balance.
              <br />
              ** By choosing to return your device, {deviceValue} will be
              credited to your {funder} Loan Account. This may or may not settle
              the outstanding balance on your {funder} account.
            </Trans>
          </Typography>
        </>
      ),
    },
    worthless: {
      upgrade: (
        <>
          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.not.ok.zero.1'>
              Your {deviceName} is not in acceptable condition and cannot be
              returned. As a result, you will have an estimated remaining{' '}
              {paymentPlanName} loan balance of {swapCost}* and you will have to
              continue making payments to {funder} until this loan amount is
              fully paid off.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.not.ok.zero.2'>
              By continuing without returning the device you will be able to
              purchase a new device with our program, where you will sign a new
              agreement with {funder}, not related to the existing one.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.evaluation.legal.device.not.ok.zero.3'>
              * The remaining loan amount is estimated based on you settling
              your monthly statement each month. Late fees or accrued interest
              have not been taken into consideration. Please check the {funder}{' '}
              Account to confirm your current loan balance.”
            </Trans>
          </Typography>
        </>
      ),
      return: (
        <>
          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.not.ok.zero.1'>
              Your {deviceName} is not in acceptable condition and cannot be
              returned. As a result, you will have an estimated remaining{' '}
              {paymentPlanName} loan balance of {swapCost}* and you will have to
              continue making payments to {funder} until this loan amount is
              fully paid off.
            </Trans>
          </Typography>

          <Typography variant='body2'>
            <Trans id='online.return.evaluation.legal.device.not.ok.zero.2'>
              * The remaining loan amount is estimated based on you settling
              your monthly statement each month. Late fees or accrued interest
              have not been taken into consideration. Please check the {funder}{' '}
              Account to confirm your current loan balance.”
            </Trans>
          </Typography>
        </>
      ),
    },
  };

  return (
    <Stack spacing={2}>
      {deviceIsOk
        ? texts.inCondition[flow]
        : evaluation.deviceValue > 0
        ? texts.damaged[flow]
        : texts.worthless[flow]}
    </Stack>
  );
};

export default OnlineSwapLegal;
