import { useSnackbar } from 'notistack';

import { useCallback, useEffect, useRef, useState } from 'react';

import { t } from '@lingui/macro';

import { errorCodes, formatters, onlineService } from '@ft-frontend/core';

import useStartingInfo from 'contexts/starting_info/useStartingInfo';

function useOnlineAPI(
  endpoint,
  {
    payload: initialPayload = {},
    needsWhitelabelUUID = true,
    initialRequest = true,
  },
) {
  const { enqueueSnackbar } = useSnackbar();

  const { id: wlUUID } = useStartingInfo();

  const [payload, setPayload] = useState(initialPayload);
  const [performRequest, setPerformRequest] = useState(false);

  const [data, setData] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(initialRequest);

  const exceptionHandler = useCallback(
    (exception) => enqueueSnackbar(exception.message, { variant: 'error' }),
    [enqueueSnackbar],
  );

  const getErrorMessage = (error) => {
    const errorTranslation = errorCodes[error.code];

    if (!errorTranslation) {
      return error.message;
    } else {
      return formatters.stringFormatter(
        t({ id: errorTranslation.id, message: errorTranslation.message }),
        error.values,
      );
    }
  };

  const apiMessageHandler = useCallback(
    (variant) =>
      (error = {}) => {
        enqueueSnackbar(
          {
            variant,
            title: `${formatters.capitalize(variant)}${
              error.errorNumber ? ` #${error.errorNumber}` : ''
            }`,
            message: getErrorMessage(error),
          },
          { variant },
        );
        window.gtag('event', 'request_error', {
          errorNumber: error?.errorNumber,
          error: error.code,
          values: error.values,
        });
      },
    [enqueueSnackbar],
  );

  const fetchData = useCallback(async () => {
    if (needsWhitelabelUUID && !wlUUID) {
      return;
    }

    setIsLoading(true);
    setIsError(false);

    const response = await onlineService[endpoint]?.(
      {
        clientID: wlUUID,
        ...payload,
      },
      {
        exceptionHandler,
        errorHandler: apiMessageHandler('error'),
        warningHandler: apiMessageHandler('warning'),
      },
    );

    if (
      response?.data?.header?.apiStatus &&
      response.data.header.apiStatus !== 'ERROR'
    ) {
      setData(response.data);
      setIsLoading(false);
    } else {
      setIsError(true);
    }
  }, [
    needsWhitelabelUUID,
    wlUUID,
    endpoint,
    payload,
    exceptionHandler,
    apiMessageHandler,
  ]);

  // Initial Request
  const hasLoaded = useRef(false);
  useEffect(() => {
    if (hasLoaded.current === false && initialRequest) {
      hasLoaded.current = true;

      fetchData();
    }
  }, [fetchData, hasLoaded, initialRequest]);

  // Triggered Requests
  useEffect(() => {
    if (performRequest) {
      setPerformRequest(false);
      fetchData();
    }
  }, [fetchData, performRequest, setPerformRequest]);

  const triggerRequest = useCallback(
    (newPayload) => {
      if (newPayload) setPayload(newPayload);

      setPerformRequest(true);
    },
    [setPayload],
  );

  return {
    data,
    isError,
    isLoading,
    triggerRequest,
    exceptionHandler,
    apiMessageHandler,
  };
}

export { useOnlineAPI };
