import React from 'react';

import { Trans } from '@lingui/macro';

import Circle from '@mui/icons-material/Circle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function AvailabilityIndicator({
  stockStatus = 'NO_STOCK_INFORMATION',
  ...props
}) {
  const stockLevel = {
    OUT_OF_STOCK: 'unavailable',
    LOW_STOCK: 'available',
    IN_STOCK: 'available',
    FOR_ORDER: 'available',
    //NO_STOCK_INFORMATION: 'available',
    ITEM_NOT_FOUND: 'unavailable',
    //NO_STOCK_CHECK_CONFIGURED: 'unavailable',
  };
  return (
    stockLevel[stockStatus] && (
      <Stack {...props} direction='row' justify-content='end' spacing={0.5}>
        <Circle sx={{ fontSize: 16 }} color={stockLevel[stockStatus]} />
        <Typography variant='caption'>
          {stockLevel[stockStatus] === 'available' ? (
            <Trans id='available.indicator'> Available</Trans>
          ) : (
            <Trans id='unavailable.indicator'> Unavailable</Trans>
          )}
        </Typography>
      </Stack>
    )
  );
}
