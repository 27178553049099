import { createSlice } from '@reduxjs/toolkit';

export const creditSlice = createSlice({
  name: 'credit',
  initialState: {
    creditOrder: undefined,
  },
  reducers: {
    resetCredit: (state) => {
      state.creditOrder = undefined;
    },
    setCreditOrder: (state, action) => {
      state.creditOrder = action.payload;
    },
  },
});

export const { resetCredit, setCreditOrder } = creditSlice.actions;

export default creditSlice.reducer;
