import React from 'react';

import { Trans } from '@lingui/macro';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { evaluationGrade } from 'constants/evaluationGrades';
import { simStatusLabels } from 'constants/recycle';

import { currencyFormatter, dateFormatter } from 'utils/formatters';

export default function EvaluationSummary({
  currencyCode,
  deviceName,
  deviceReference,
  expiredDate,
  grade,
  isTrade = true,
  networkStatus,
  orderUUID,
  swapCost,
  tradecartSID,
  value,
}) {
  const valueBoxStyle = {
    display: 'flex',
    alignItems: 'flex-end',
  };

  return (
    <Stack spacing={2}>
      <Box
        sx={(theme) => ({
          display: 'grid',
          columnGap: theme.spacing(1),
          gridTemplateColumns: 'minmax(25%, auto) minmax(50%, 75%)',
        })}
      >
        {orderUUID && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.order.guid'>Order:</Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-device-name'
              >
                {orderUUID}
              </Typography>
            </Box>
          </>
        )}
        {tradecartSID && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.quoteid'>Quote ID:</Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-device-name'
              >
                {tradecartSID}
              </Typography>
            </Box>
          </>
        )}
        {deviceName && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.device'>Device:</Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-device-name'
              >
                {deviceName}
              </Typography>
            </Box>
          </>
        )}

        {deviceReference && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.deviceReference'>
                  IMEI / Serial number:
                </Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-device-reference'
              >
                {deviceReference}
              </Typography>
            </Box>
          </>
        )}

        {networkStatus && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.network'>
                  Network status:
                </Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-network-status'
              >
                {simStatusLabels()[networkStatus]}
              </Typography>
            </Box>
          </>
        )}

        {grade && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.condition'>Condition:</Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-condition'
              >
                <Trans id={evaluationGrade[grade]?.message.id} />
              </Typography>
            </Box>
          </>
        )}

        {(swapCost || swapCost === 0) && currencyCode && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.remaining'>
                  Estimated remaining loan balance:
                </Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-remaining-loan'
              >
                {currencyFormatter(swapCost, currencyCode)}
              </Typography>
            </Box>
          </>
        )}

        {swapCost > 0 && (value || value === 0) && currencyCode && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.estimated.return.value'>
                  Estimated device return value:
                </Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-remaining-loan'
              >
                {currencyFormatter(value, currencyCode)}
              </Typography>
            </Box>
          </>
        )}

        {expiredDate && (
          <>
            <Box>
              <Typography variant='subtitle2'>
                <Trans id='online.evaluate.summary.expiration.date'>
                  Expiration date:
                </Trans>
              </Typography>
            </Box>
            <Box sx={valueBoxStyle}>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: 600 }}
                data-testid='evaluate-summary-remaining-loan'
              >
                {dateFormatter(expiredDate, true)}
              </Typography>
            </Box>
          </>
        )}
      </Box>

      {isTrade && (value || value === 0) && currencyCode && (
        <Box>
          <Typography
            variant='h4'
            data-testid='evaluate-summary-estimated-value'
          >
            <Trans id='online.evaluate.summary.estimated.value'>
              Estimated value for your device is{' '}
              {currencyFormatter(value, currencyCode)}
            </Trans>
          </Typography>
        </Box>
      )}
    </Stack>
  );
}
