import React, { Fragment, useState } from 'react';

const Image = ({
  aspectRatio,
  baseURL = 'https://d2qd4lcg64wzid.cloudfront.net/',
  filePath = 'ft_generic/no_photo.svg',
  height,
  width,
  responsive,
  pictureStyle,
  style,
  // thumbor props
  // https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/thumbor-filters.html
  // https://thumbor.readthedocs.io/en/latest/filters.html#available-filters
  autoJPG,
  backgroundColor,
  blur,
  colorFill,
  convolution,
  crop,
  equalize,
  grayscale,
  imageFormat,
  noUpscale,
  proportion,
  quality,
  rgb,
  rotate,
  sharpen,
  stretch,
  stripEXIF,
  stripICC,
  upscale,
  watermark,
  // <img> props
  ...otherProps
}) => {
  const [isLoading, setIsLoading] = useState(true);

  if (!filePath || filePath === 'undefined') {
    return;
  }

  if (!baseURL?.endsWith('/')) {
    baseURL += '/';
  }

  filePath = filePath?.replace(/^http(s)?:\/\/.+?\//, '');

  if (filePath?.startsWith('/')) {
    filePath = filePath?.slice(1);
  }

  let thumbor = '';
  let dimensions = '';
  let sources;

  if (autoJPG) {
    thumbor += `filters:autojpg()/`;
  }

  if (backgroundColor) {
    thumbor += `filters:background_color(${backgroundColor})/`;
  }

  if (blur) {
    thumbor += `filters:blur(${blur})/`;
  }

  if (colorFill) {
    thumbor += `filters:fill(${colorFill})/`;
  }

  if (convolution) {
    thumbor += `filters:convolution(${convolution})/`;
  }

  if (crop) {
    thumbor += `${crop}`;
  }

  if (equalize) {
    thumbor += `filters:equalize()/`;
  }

  if (grayscale) {
    thumbor += `filters:grayscale()/`;
  }

  if (imageFormat) {
    thumbor += `filters:format(${imageFormat})/`;
  }

  if (noUpscale) {
    thumbor += `filters:no_upscale()/`;
  }

  if (proportion) {
    thumbor += `filters:proportion(${proportion})/`;
  }

  if (quality) {
    thumbor += `filters:quality(${quality})/`;
  }

  if (height || width) {
    dimensions = `fit-in/${width || 0}x${height || 0}/`;
  }

  if (rgb) {
    thumbor += `filters:rgb(${rgb})/`;
  }

  if (rotate) {
    thumbor += `filters:rotate(${rotate})/`;
  }

  if (sharpen) {
    thumbor += `filters:sharpen(${sharpen})/`;
  }

  if (stretch) {
    thumbor += `filters:stretch()/`;
  }

  if (stripEXIF) {
    thumbor += `filters:strip_exif()/`;
  }

  if (stripICC) {
    thumbor += `filters:strip_icc()/`;
  }

  if (upscale) {
    thumbor += `filters:upscale()/`;
  }

  if (watermark) {
    thumbor += `filters:watermark(${watermark})/`;
  }

  if (responsive) {
    // don't replace <Fragment></Fragment> with <></>
    // or it will cause a "Fragment is not defined" error
    sources = (
      <Fragment>
        <source
          media='(min-width:1920px)'
          srcSet={`${baseURL}fit-in/1920x0/${thumbor}${filePath} 1920w`}
        />
        <source
          media='(min-width:1080px)'
          srcSet={`${baseURL}fit-in/1080x0/${thumbor}${filePath} 1080w,
                  ${baseURL}fit-in/1920x0/${thumbor}${filePath} 2x`}
        />
        <source
          media='(min-width:768px)'
          srcSet={`${baseURL}fit-in/768x0/${thumbor}${filePath} 768w,
                  ${baseURL}fit-in/1080x0/${thumbor}${filePath} 1.5x`}
        />
        <source
          srcSet={`${baseURL}fit-in/375x0/${thumbor}${filePath} 375w,
                  ${baseURL}fit-in/768x0/${thumbor}${filePath} 2x`}
        />
      </Fragment>
    );
  } else {
    sources = (
      <source srcSet={`${baseURL}${dimensions}${thumbor}${filePath}`} />
    );
  }

  return (
    <picture style={{ lineHeight: 0, ...pictureStyle }}>
      {sources}

      <img
        src={`${baseURL}${dimensions}${thumbor}${filePath}`}
        style={{
          maxInlineSize: '100%',
          blockSize: 'auto',
          aspectRatio: `${aspectRatio}`,
          objectFit: 'contain',
          width: '100%',
          height: 'auto',
          backgroundColor: isLoading ? 'rgba(0, 0, 0, 0.11)' : 'transparent',
          ...style,
        }}
        {...otherProps}
        onLoad={() => setIsLoading(false)}
      />
    </picture>
  );
};

export default Image;
