import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, defineMessage } from '@lingui/macro';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const steps = (swapUUID) => [
  defineMessage({
    id: 'step.device',
    message: `Choose your device`,
  }),
  defineMessage({
    id: 'step.extras',
    message: `Add acessories`,
  }),
  ...(swapUUID
    ? [
        defineMessage({
          id: 'step.evaluate.device',
          message: `Evaluate device`,
        }),
      ]
    : []),
  defineMessage({
    id: 'step.delivery.details',
    message: `Contact and delivery`,
  }),
  defineMessage({
    id: 'step.review',
    message: `Review and payment`,
  }),
  defineMessage({
    id: 'step.order',
    message: `Order summary`,
  }),
];

const isInViewport = (element) => {
  const rect = element?.getBoundingClientRect();
  return (
    rect?.top >= 0 &&
    rect?.left >= 0 &&
    rect?.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

const shouldShowEllipsis = () => {
  const pageStepContainer = document.getElementById('pageStepContainer');
  const pageStepEnd = document.getElementById('pageStepEnd');

  if (!pageStepContainer || isInViewport(pageStepEnd)) {
    return false;
  }

  return !(
    pageStepContainer.offsetWidth === pageStepContainer.scrollWidth ||
    pageStepContainer.scrollLeftMax === 0 ||
    pageStepEnd.offsetWidth > 0 ||
    pageStepEnd.scrollWidth > 0
  );
};

export default function PageStepper({ activeStep = 0 }) {
  const [screenWidth, setScreenWidth] = useState();
  const swapUUID = useSelector((state) => state.flow.swapUUID);

  const [ellipsisVisible, setEllipsisVisibility] = useState(
    shouldShowEllipsis(activeStep),
  );

  useEffect(() => {
    if (activeStep === undefined) return;

    const stepDiv = document.getElementById(`pageStep-${activeStep}`);

    if (stepDiv) {
      stepDiv.scrollIntoView({ inline: 'start', block: 'nearest' });
    }

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    setEllipsisVisibility(shouldShowEllipsis(activeStep));

    return () => window.removeEventListener('resize', handleResize);
  }, [activeStep, screenWidth]);

  return (
    <Grid
      sx={{
        zIndex: '-1',
      }}
      item
      xs={12}
    >
      <Toolbar
        id='pageStepper'
        data-testid='pageStepper'
        sx={{
          padding: (theme) => `${theme.spacing(1)} 0 !important`,
        }}
      >
        <Stepper
          id='pageStepContainer'
          sx={{ width: '100%', overflow: 'hidden' }}
          connector={null}
          activeStep={activeStep}
        >
          {steps(swapUUID).map((step, index) => (
            <Step
              sx={{
                paddingLeft: index === 0 && '0 !important',
                paddingRight: (theme) =>
                  index !== steps(swapUUID).length && theme.spacing(2),
              }}
              id={`pageStep-${index}`}
              data-testid={step.id}
              data-test-status={
                index > activeStep
                  ? 'FUTURE'
                  : index < activeStep
                  ? 'PREVIOUS'
                  : 'CURRENT'
              }
              key={`pageStep-${index}`}
            >
              <StepLabel sx={{ whiteSpace: 'nowrap' }}>
                <Trans id={step.id} />
              </StepLabel>
            </Step>
          ))}
          <Box id='pageStepEnd' sx={{ width: 1, height: 1 }} last='true' />
        </Stepper>
        <Typography
          sx={{
            color: 'rgba(0, 0, 0, 0.6)',
            display: `${ellipsisVisible ? 'block' : 'none'}`,
          }}
        >
          ...
        </Typography>
      </Toolbar>
    </Grid>
  );
}
