import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    order: {},
  },
  reducers: {
    resetOrder: (state) => {
      state.order = {};
    },
    setOrder: (state, action) => {
      state.order = {
        ...action.payload,
        products:
          !action.payload?.products || action.payload?.products?.length === 0
            ? [action.payload?.product]
            : action.payload?.products,
      };
    },
  },
});

export const { resetOrder, setOrder } = orderSlice.actions;

export default orderSlice.reducer;
