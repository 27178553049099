import { createSlice } from '@reduxjs/toolkit';

const initialRecycleState = {
  customer: {},
  deliveryOption: 'DELIVERY',
  hasPackaging: false,
  orderGUID: undefined,
  outcome: undefined,
  priceID: undefined,
  trackingID: undefined,
};

export const recycleSlice = createSlice({
  name: 'recycle',
  initialState: initialRecycleState,
  reducers: {
    clearCollectionTimes: (state) => {
      state.collectionTimes = initialRecycleState.collectionTimes;
    },
    resetRecycle: (state) => {
      return {
        ...initialRecycleState,
        deliveryOption: state.deliveryOption,
      };
    },
    setCustomerField: (state, action) => {
      state.customer[action.payload.field] = action.payload.value;
    },
    setHasPackaging: (state, action) => {
      state.hasPackaging = action.payload;
    },
    setOrderGUID: (state, action) => {
      state.orderGUID = action.payload;
    },
    setOutcome: (state, action) => {
      state.outcome = action.payload;
    },
    setPriceID: (state, action) => {
      state.priceID = action.payload;
    },
    setTrackingID: (state, action) => {
      state.trackingID = action.payload;
    },
  },
});

export const {
  clearCollectionTimes,
  resetRecycle,
  setCustomerField,
  setHasPackaging,
  setOrderGUID,
  setOutcome,
  setPriceID,
  setTrackingID,
} = recycleSlice.actions;

export default recycleSlice.reducer;
