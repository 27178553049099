import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

import { Trans } from '@lingui/macro';

import {
  adminAccessoriesUrl,
  adminAttachmentsURL,
  adminCampaignsUrl,
  adminCollectionsUrl,
  adminContractDateAdjustUrl,
  adminDevicesUrl,
  adminPricingToolUrl,
  adminResidualValuesSearchUrl,
  adminRulesUrl,
  adminStoresUrl,
  adminTemplateBlocksURL,
  adminTestContractsUrl,
  adminUserUrl,
  adminVouchersUrl,
  contractPendingUrl,
  recycleQuoteSearchUrl,
  recycleQuotesUrl,
  recycleReportsUrl,
  reportSettlementUrl,
  salesUrl,
  servicesUrl,
} from 'constants/routes';
import {
  ADMIN,
  STORE_MANAGER,
  STORE_PERSON,
  VENDOR_MANAGER,
} from 'constants/user';

export const mainNav = (menuConfigs, documentation = {}) => {
  const newCart = {
    id: 'new-cart',
    label: <Trans id='navigation.home.title'>New Cart</Trans>,
    minRole: STORE_PERSON,
    path: servicesUrl,
    options: [],
  };

  const contracts = {
    id: 'contracts',
    label: <Trans id='navigation.contracts.title'>Contracts</Trans>,
    minRole: STORE_PERSON,
    options: {
      pendings: {
        id: 'pendings',
        label: <Trans id='navigation.contracts.pendings.title'>Pendings</Trans>,
        path: contractPendingUrl,
        minRole: STORE_PERSON,
      },
      sales: {
        id: 'sales',
        label: <Trans id='navigation.contracts.sales.tilte'>Sales</Trans>,
        path: salesUrl,
        minRole: STORE_PERSON,
      },
      recycled: {
        id: 'recycled',
        label: <Trans id='navigation.contracts.recycle.title'>Recycled</Trans>,
        path: recycleQuotesUrl,
        minRole: STORE_PERSON,
      },
    },
  };

  const support = {
    id: 'support',
    isActive: true,
    label: <Trans id='navigation.support.title'>Support</Trans>,
    minRole: STORE_PERSON,
    options: Object.entries(documentation).reduce((acc, [label, url]) => {
      return {
        ...acc,
        [label]: {
          id: label,
          isActive: true,
          label: label,
          onClick: () => window.open(url, '_blank'),
          minRole: STORE_PERSON,
        },
      };
    }, {}),
  };

  const reports = {
    id: 'reports',
    label: <Trans id='navigation.contracts.reports.title'>Reports</Trans>,
    minRole: STORE_MANAGER,
    options: {
      recycle: {
        id: 'recycle',
        label: <Trans id='navigation.reports.recycle.title'>Recycle</Trans>,
        path: recycleReportsUrl,
        minRole: STORE_MANAGER,
      },
      settlements: {
        id: 'settlements',
        label: (
          <Trans id='navigation.reports.settlements.title'>Settlements</Trans>
        ),
        path: reportSettlementUrl,
        minRole: VENDOR_MANAGER,
      },
    },
  };

  const tradein = {
    id: 'tradein',
    label: <Trans id='navigation.tradein.title'>Trade-in</Trans>,
    minRole: STORE_PERSON,
    options: {
      onlineQuotesSearch: {
        id: 'online-quote-search',
        label: (
          <Trans id='navigation.tradein.online.quotes.title'>
            Online quote search
          </Trans>
        ),
        path: recycleQuoteSearchUrl,
        minRole: STORE_PERSON,
      },
    },
  };

  const admin = {
    id: 'admin',
    label: <Trans id='navigation.admin.title'>Admin</Trans>,
    minRole: STORE_PERSON,
    options: {
      stores: {
        id: 'stores',
        label: <Trans id='navigation.stores.title'>Stores</Trans>,
        path: adminStoresUrl,
        minRole: VENDOR_MANAGER,
      },
      users: {
        id: 'users',
        label: <Trans id='navigation.users.title'>Users</Trans>,
        path: adminUserUrl,
        minRole: STORE_MANAGER,
      },
      devices: {
        id: 'devices',
        label: <Trans id='navigation.devices.title'>Devices</Trans>,
        path: adminDevicesUrl,
        minRole: VENDOR_MANAGER,
      },
      accessories: {
        id: 'accessories',
        label: <Trans id='navigation.accessories.title'>Accessories</Trans>,
        path: adminAccessoriesUrl,
        minRole: VENDOR_MANAGER,
      },
      collections: {
        id: 'collections',
        label: <Trans id='navigation.collections.title'>Collections</Trans>,
        path: adminCollectionsUrl,
        minRole: STORE_PERSON,
      },
      contractDateAdjust: {
        id: 'contract-date-adjust',
        label: (
          <Trans id='navigation.delorean.title'>Contract Date Adjust</Trans>
        ),
        path: adminContractDateAdjustUrl,
        minRole: ADMIN,
      },
      pricingTool: {
        id: 'pricing-tool',
        label: <Trans id='navigation.pricing.tool'>Pricing Tool</Trans>,
        path: adminPricingToolUrl,
        minRole: VENDOR_MANAGER,
      },
      residualValues: {
        id: 'residual-values',
        label: <Trans id='navigation.residual.values'>Residual Values</Trans>,
        path: adminResidualValuesSearchUrl,
        minRole: VENDOR_MANAGER,
      },
      rules: {
        id: 'residual-values',
        label: <Trans id='navigation.rules'>Rules</Trans>,
        path: adminRulesUrl,
        minRole: VENDOR_MANAGER,
        isMigrated: true,
      },
      vouchers: {
        id: 'vouchers',
        label: <Trans id='navigation.vouchers.title'>Vouchers</Trans>,
        path: adminVouchersUrl,
        minRole: VENDOR_MANAGER,
      },
    },
  };

  const crm = {
    id: 'crm',
    label: <Trans id='navigation.crm.title'>CRM</Trans>,
    minRole: VENDOR_MANAGER,
    options: {
      campaigns: {
        id: 'campaigns',
        label: <Trans id='navigation.campaigns.title'>Campaigns</Trans>,
        path: adminCampaignsUrl,
        minRole: VENDOR_MANAGER,
      },
      templateBlocks: {
        id: 'template-blocks',
        label: (
          <Trans id='navigation.template.blocks.title'>Template Blocks</Trans>
        ),
        path: adminTemplateBlocksURL,
        minRole: VENDOR_MANAGER,
      },
      testContracts: {
        id: 'test-contracts',
        label: <Trans id='navigation.test.contract'>Test Contracts</Trans>,
        path: adminTestContractsUrl,
        minRole: VENDOR_MANAGER,
      },
      attachments: {
        id: 'attachments',
        label: <Trans id='navigation.crm.attachments'>Attachments</Trans>,
        path: adminAttachmentsURL,
        minRole: ADMIN,
      },
    },
  };

  return merge(
    {
      newCart,
      contracts,
      reports,
      tradein,
      admin,
      crm,
      support: isEmpty(documentation) ? {} : support,
    },
    menuConfigs,
  );
};
