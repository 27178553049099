export const changePasswordUrl = '/posale/password/change';
export const loginPageUrl = '/posale/login';

export const servicesUrl = '/posale/services';

export const contractPendingUrl =
  '/posale/sales/contracts/pending?activeForm=SalesForm-Filter';
export const salesUrl = '/posale/sales?activeForm=SalesForm-Filter';
export const recycleQuotesUrl = '/posale/recycle/quotes';

export const recycleReportsUrl = '/posale/reports/recycle';
export const reportSettlementUrl = '/posale/reports/settlements';

export const recycleQuoteSearchUrl = '/posale/recycle/quotes/search';

export const adminStoresUrl = '/posale/admin/stores';
export const adminUserUrl = '/posale/admin/users';
export const adminDevicesUrl = '/posale/admin/devices';
export const adminAccessoriesUrl = '/posale/admin/accessories';
export const adminCollectionsUrl = '/posale/admin/collections';
export const adminContractDateAdjustUrl = '/posale/admin/contracts/adjust';
export const adminPricingToolUrl = '/posale/admin/pricing_tool';
export const adminResidualValuesSearchUrl = '/posale/admin/residualvalues';
export const adminRulesUrl = '/posale/admin/rules';
export const adminVouchersUrl = '/posale/admin/vouchers';

export const adminCampaignsUrl = '/posale/admin/campaigns?form=close';
export const adminTemplateBlocksURL = '/posale/admin/emails/blocks';
export const adminTestContractsUrl = '/posale/admin/testcontracts';
export const adminAttachmentsURL = '/posale/admin/attachments';
