import React from 'react';

import { Trans } from '@lingui/macro';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Unauthorized = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid
        container
        justifyContent='center'
        xs={12}
        alignItems='center'
        height='100%'
        spacing={4}
        sx={{
          height: '100vh',
        }}
      >
        <Grid item xs={6} textAlign='center'>
          <img
            src='https://cdn.financetech365.com/svg/error-face.svg'
            srcSet='https://cdn.financetech365.com/svg/error-face.svg'
            alt='error 404'
            loading='lazy'
            style={{
              maxHeight: '450px',
            }}
            width='100%'
          />
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <div>
              <Typography variant='h2' align='left'>
                <Trans id='unauthorized.title'>
                  It looks like you don't have enough permissions to visit this
                  page.
                </Trans>
              </Typography>
            </div>
            <div>
              <Link
                component='button'
                variant='h5'
                onClick={() => window.history.back()}
                data-testid='auth-go-back-btn'
              >
                <Trans id='unauthorized.back.navigation'>
                  Back to previous page
                </Trans>
              </Link>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Unauthorized;
