import { apiServiceClient } from '../frameworkClient';

const wlDeviceClient = apiServiceClient('framework/WLDeviceService');

export const createWLDevice = async (wlDevice) => {
  return wlDeviceClient('createWLDevice', { wlDevice });
};

export const getWLDevice = async (wlID, wlDeviceID) => {
  return wlDeviceClient('getWLDevice', { wlID, wlDeviceID });
};

export const listWLDevices = async (wlID) => {
  return wlDeviceClient('listWLDevices', { wlID });
};

export const updateWLDevice = async (wlDevice) => {
  return wlDeviceClient('updateWLDevice', { wlDevice });
};

export default {
  createWLDevice,
  getWLDevice,
  listWLDevices,
  updateWLDevice,
};
